import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {SendGiftConfig} from '../../store/reducers/sendConfig';
import {setGiftField} from '../../store/actions/sendConfig';
import {connect, DispatchProp} from 'react-redux';
import {AppState} from '../../store';
import GiftPreview from './GiftPreview';
import giftCardsConfig from '../../cards/config';
import {GiftCard} from '../../cards';
import {computeTotalCost} from '../../utils/feeUtils';

const renderSuggestion = (idx: number, message: string, onClick: React.MouseEventHandler<any>) => {
    return (
        <div className="greeting_message" key={idx} onClick={onClick} data-message={message}>
            <input id={`suggestion-${idx}`} type="radio" className="radio_message"/>
            <label htmlFor={`suggestion-${idx}`} className="message_text_check">{message}</label>
        </div>
    );
};

class SendConfigureStep extends React.Component<{ sendGiftConfig: SendGiftConfig } & DispatchProp,
    { isSuggestionBoxVisible: boolean }> {

    private card: GiftCard;

    constructor(props) {
        super(props);
        this.card = giftCardsConfig[props.sendGiftConfig.cardId];
        this.state = {isSuggestionBoxVisible: false};
    }

    public componentWillReceiveProps(nextProps) {
        this.card = giftCardsConfig[nextProps.sendGiftConfig.cardId];
    }

    public render() {
        return (
            <div className="content is-medium">
                <div className="hero-body has-text-centered">
                    <h2><strong>Let's customize the GiftBox </strong></h2>
                    <p>
                        Add the amount of the Crypto GiftBox. Optionally, you can add the receiver's name, write
                        a greeting message and sign the GiftBox.
                    </p>
                </div>

                <div className="box is-paddingless">
                    <GiftPreview/>
                </div>
                <br/>

                <div className="field is-spaced">
                    <label className="label is-size-5">
                        Gift Value
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                            Insert the amount of ETHER you want to give. This is what the receiver will get. The total cost includes our commmision.
                        </span>
                        </div>
                    </label>

                    <div className="field is-horizontal">
                        <div className="field-label is-verify">
                            <label className="label is-size-5">ETH</label>
                        </div>

                        <div className="field-body">
                            <div className="field is-amount">
                                <p className="control">
                                    <input className="input" type="number" name="value" placeholder="Amount"
                                           value={this.props.sendGiftConfig.value}
                                           onChange={this.handleValueFieldChange}/>
                                </p>

                            </div>
                            <div className="field-label is-total">
                                <label className="label is-size-5 has-text-primary">TOTAL COST:</label>
                            </div>
                            <span className="has-text-primary">{computeTotalCost(this.props.sendGiftConfig.value)} (fee included)</span>
                        </div>



                    </div>
                </div>

                <div className="field">
                    <label className="label is-size-5">
                        To
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                            Who's the lucky receiver? Insert his or her name.
                        </span>
                        </div>
                    </label>
                    <div className="control">
                        <input className="input" name="receiverName" type="text"
                               placeholder="Receiver's name (Optional)"
                               value={this.props.sendGiftConfig.receiverName}
                               onChange={this.handleFieldChange}/>
                    </div>
                </div>

                <div className="field is-spaced">
                    <label className="label is-size-5">
                        From
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                            Do you want to sign the card?
                        </span>
                        </div>
                    </label>
                    <div className="control">
                        <input className="input" type="text" name="senderName" placeholder="Sender's name (Optional)"
                               value={this.props.sendGiftConfig.senderName}
                               onChange={this.handleFieldChange}/>
                    </div>
                </div>

                <div className="field is-grouped-multiline is-spaced">
                    <label className="label is-size-5">
                        Write a message on the GiftBox
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                            Would you like to write a greeting message? You can write a personal message or you
                            can choose a ready made one.
                        </span>
                        </div>
                    </label>
                    <div className="control">
                    <textarea className="textarea" placeholder="Dear John, I wish you ..."
                              name="message" value={this.props.sendGiftConfig.message}
                              onChange={this.handleFieldChange}/>
                        <span className="has-text-danger is-size-6 is-pulled-right"
                              hidden={this.props.sendGiftConfig.message.length < 170}>
                            {this.props.sendGiftConfig.message.length} / 190
                        </span>
                    </div>

                    <div className="control suggestion-section">
                        <input id="mess" type="checkbox" className="message_check"
                               checked={this.state.isSuggestionBoxVisible}
                               onClick={this.handleMessageSuggestionButtonClick}/>
                        <label htmlFor="mess" className="button is-small">
                            <span>Looking for inspiration?</span>
                            <FontAwesomeIcon icon={this.state.isSuggestionBoxVisible ? 'chevron-up' : 'chevron-down'}
                                             fixedWidth={true}/>
                        </label>

                        <label className="field-label is-medium">
                            <div className="tooltip">
                                <FontAwesomeIcon icon="question-circle"/>
                                <span className="tooltiptext">
                                Find inspiration with our ready made messages. You can choose any message you like
                                and modify it as you wish.
                            </span>
                            </div>
                        </label>

                        <div className="reveal-if-active_check is-small">
                            {this.card.messageSuggestions.map((msg, idx) => renderSuggestion(idx, msg, this.handleSuggestionClick))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private handleMessageSuggestionButtonClick = () => {
        this.setState({isSuggestionBoxVisible: !this.state.isSuggestionBoxVisible});
    };

    private handleValueFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        logger.debug(`${event.target.name} = ${event.target.value}`);
        this.props.dispatch(setGiftField(event.target.name, Number(event.target.value)));
    };

    private handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value.substring(0, 190);
        this.props.dispatch(setGiftField(event.target.name, value));
    };

    private handleSuggestionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const suggestion: string = event.currentTarget.dataset.message;
        logger.debug(`Clicked suggestion: '${suggestion}'`);
        this.props.dispatch(setGiftField('message', suggestion));
        this.setState({isSuggestionBoxVisible: false});
        event.preventDefault();
    };
}

const mapStateToProps = (state: AppState) => {
    return {
        sendGiftConfig: state.sendGiftConfig,
    };
};

export default connect(mapStateToProps)(SendConfigureStep);
