/* tslint:disable:max-line-length */
import {GiftCard} from './index';

const holidayMessageSuggestions: string[] = [
    'May this holiday season sparkle and shine, may all of your wishes and dreams come true, and may you feel this happiness all year round!',
    'May this year be filled with even more happy memories, cherished moments and shining dreams.',
    'Congratulations on the first step of many in your path to success',
];

const birthdayMessageSuggestions: string[] = [
    'Happy birthday! Enjoy this year and more coming years and cherish them!',
    'Here\'s crypto for a beer. Happy birthday!',
];

const giftCardsConfig: { [key: string]: GiftCard } = {
    'card-1': {
        text: [
            {
                id: 'title',
                value: 'Gift certificate',
                size: 200,
                font: 'Serif',
                maxWidth: 1300,
                lineHeight: 88,
                color: '#158cba',
                placement: {x: 620, y: 350},
            }, {
                id: 'message',
                size: 90,
                font: 'Serif',
                textstyle: 'italic',
                maxWidth: 1700,
                lineHeight: 88,
                color: '#158cba',
                placement: {x: 480, y: 780},
            }, {
                id: 'value',
                size: 130,
                font: 'Serif',
                color: '#000',
                maxWidth: 910,
                lineHeight: 88,
                placement: {x: 1150, y: 500},
            }, {
                id: 'senderName',
                size: 90,
                value: 'John',
                font: 'Serif',
                textstyle: 'italic',
                maxWidth: 200,
                color: '#158cba',
                lineHeight: 10,
                placement: {x: 1700, y: 1150},
            }, {
                id: 'ClaimKey_title',
                value: 'Claim Key',
                size: 50,
                font: 'Serif',
                textstyle: 'bold',
                maxWidth: 400,
                color: '#000',
                lineHeight: 68,
                placement: {x: 1660, y: 1320},
            }, {
                id: 'claimKey',
                value: '<your claim key will be available here>',
                size: 42,
                font: 'Serif',
                maxWidth: 1000,
                color: 'black',
                lineHeight: 52,
                placement: {x: 1330, y: 1390},
            }, {
                id: 'Details',
                value: 'Details',
                size: 50,
                font: 'Serif',
                textstyle: 'bold',
                maxWidth: 200,
                color: '#000',
                lineHeight: 68,
                placement: {x: 520, y: 1320},
            }, {
                id: 'Details_row1',
                value: 'Congratulations! You have just received an ether Crypto GiftBox.',
                size: 42,
                font: 'Serif',
                maxWidth: 1000,
                color: 'black',
                lineHeight: 52,
                placement: {x: 150, y: 1400},
            }, {
                id: 'Details_row2',
                value: 'Access www.cryptogiftbox.com/#/claim and follow the instructions therein to claim the gift.',
                size: 42,
                font: 'Serif',
                maxWidth: 1000,
                color: 'black',
                lineHeight: 52,
                placement: {x: 150, y: 1520},
            }, {
                id: 'Details_row3',
                value: 'DO NOT REVEAL THE CLAIM KEY until you claim the balance to your wallet.',
                size: 42,
                font: 'Serif',
                maxWidth: 1000,
                color: 'black',
                lineHeight: 52,
                placement: {x: 150, y: 1640},
            }],
        image: {
            original: require('../assets/cards/Slide01.jpg'),
            thumbnail: require('../assets/cards/Slide01.jpg'),
        },
        defaults: {
            message: 'Cheers to another birthday! May this year be filled with even more happy memories, cherished moments and shining dreams.',
        },
        messageSuggestions: holidayMessageSuggestions,
        name: 'Test1',
    },

    'card-2': {
        text: [{
            id: 'title',
            value: 'Gift certificate',
            size: 200,
            font: 'Serif',
            maxWidth: 1300,
            lineHeight: 88,
            color: '#f26d7d',
            placement: {x: 620, y: 350},
        }, {
            id: 'message',
            size: 90,
            font: 'Serif',
            textstyle: 'italic',
            maxWidth: 1700,
            lineHeight: 88,
            color: '#f26d7d',
            placement: {x: 480, y: 780},
        }, {
            id: 'value',
            size: 130,
            font: 'Serif',
            color: '#000',
            maxWidth: 910,
            lineHeight: 88,
            placement: {x: 1150, y: 500},
        }, {
            id: 'senderName',
            size: 90,
            value: 'John',
            font: 'Serif',
            textstyle: 'italic',
            maxWidth: 200,
            color: '#f26d7d',
            lineHeight: 90,
            placement: {x: 1700, y: 1150},
        }, {
            id: 'ClaimKey_title',
            value: 'Claim Key',
            size: 50,
            font: 'Serif',
            textstyle: 'bold',
            maxWidth: 400,
            color: '#000',
            lineHeight: 68,
            placement: {x: 1660, y: 1320},
        }, {
            id: 'claimKey',
            value: '<your claim key will be available here>',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 1330, y: 1390},
        }, {
            id: 'Details',
            value: 'Details',
            size: 50,
            font: 'Serif',
            textstyle: 'bold',
            maxWidth: 200,
            color: '#000',
            lineHeight: 68,
            placement: {x: 520, y: 1320},
        }, {
            id: 'Details_row1',
            value: 'Congratulations! You have just received an ether Crypto GiftBox.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1400},
        }, {
            id: 'Details_row2',
            value: 'Access www.cryptogiftbox.com/#/claim and follow the instructions therein to claim the gift.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1520},
        }, {
            id: 'Details_row3',
            value: 'DO NOT REVEAL THE CLAIM KEY until you claim the balance to your wallet.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1640},
        }],
        image: {
            original: require('../assets/cards/Slide02.jpg'),
            thumbnail: require('../assets/cards/Slide02.jpg'),
        },
        defaults: {
            message: 'Cheers to another birthday! May this year be filled with even more happy memories, cherished moments and shining dreams.',
        },
        messageSuggestions: holidayMessageSuggestions,
        name: 'Test2',
    },

    'card-3': {
        text: [{
            id: 'title',
            value: 'Gift certificate',
            size: 200,
            font: 'Serif',
            maxWidth: 1300,
            lineHeight: 88,
            color: '#f26d7d',
            placement: {x: 620, y: 550},
        }, {
            id: 'message',
            size: 90,
            font: 'Serif',
            textstyle: 'italic',
            maxWidth: 2000,
            lineHeight: 88,
            color: '#f26d7d',
            placement: {x: 240, y: 880},
        }, {
            id: 'value',
            size: 130,
            font: 'Serif',
            color: '#000',
            maxWidth: 910,
            lineHeight: 88,
            placement: {x: 1150, y: 700},
        }, {
            id: 'senderName',
            size: 90,
            value: 'John',
            font: 'Serif',
            textstyle: 'italic',
            maxWidth: 200,
            color: '#f26d7d',
            lineHeight: 10,
            placement: {x: 1900, y: 1150},
        }, {
            id: 'ClaimKey_title',
            value: 'Claim Key',
            size: 50,
            font: 'Serif',
            textstyle: 'bold',
            maxWidth: 400,
            color: '#000',
            lineHeight: 68,
            placement: {x: 1660, y: 1320},
        }, {
            id: 'claimKey',
            value: '<your claim key will be available here>',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 1330, y: 1390},
        }, {
            id: 'Details',
            value: 'Details',
            size: 50,
            font: 'Serif',
            textstyle: 'bold',
            maxWidth: 200,
            color: '#000',
            lineHeight: 68,
            placement: {x: 520, y: 1320},
        }, {
            id: 'Details_row1',
            value: 'Congratulations! You have just received an ether Crypto GiftBox.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1400},
        }, {
            id: 'Details_row2',
            value: 'Access www.cryptogiftbox.com/#/claim and follow the instructions therein to claim the gift.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1520},
        }, {
            id: 'Details_row3',
            value: 'DO NOT REVEAL THE CLAIM KEY until you claim the balance to your wallet.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1640},
        }],
        image: {
            original: require('../assets/cards/Slide03.jpg'),
            thumbnail: require('../assets/cards/Slide03.jpg'),
        },
        defaults: {
            message: 'May this holiday season sparkle and shine, may all of your wishes and dreams come true, and may you feel this happiness all year round!',
        },
        messageSuggestions: holidayMessageSuggestions,
        name: 'Test3',
    },

    'card-4': {
        text: [{
            id: 'title',
            value: 'Gift certificate',
            size: 200,
            font: 'Serif',
            maxWidth: 1300,
            lineHeight: 88,
            color: '#f26d7d',
            placement: {x: 1100, y: 350},
        }, {
            id: 'message',
            size: 90,
            font: 'Serif',
            textstyle: 'italic',
            maxWidth: 1300,
            lineHeight: 88,
            color: '#f26d7d',
            placement: {x: 1100, y: 780},
        }, {
            id: 'value',
            size: 130,
            font: 'Serif',
            color: '#000',
            maxWidth: 910,
            lineHeight: 88,
            placement: {x: 1650, y: 500},
        }, {
            id: 'senderName',
            size: 90,
            value: 'John',
            font: 'Serif',
            textstyle: 'italic',
            maxWidth: 200,
            color: '#f26d7d',
            lineHeight: 90,
            placement: {x: 2000, y: 1150},
        }, {
            id: 'ClaimKey_title',
            value: 'Claim Key',
            size: 50,
            font: 'Serif',
            textstyle: 'bold',
            maxWidth: 400,
            color: '#000',
            lineHeight: 68,
            placement: {x: 1660, y: 1320},
        }, {
            id: 'claimKey',
            value: '<your claim key will be available here>',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 1330, y: 1390},
        }, {
            id: 'Details',
            value: 'Details',
            size: 50,
            font: 'Serif',
            textstyle: 'bold',
            maxWidth: 200,
            color: '#000',
            lineHeight: 68,
            placement: {x: 520, y: 1320},
        }, {
            id: 'Details_row1',
            value: 'Congratulations! You have just received an ether Crypto GiftBox.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1400},
        }, {
            id: 'Details_row2',
            value: 'Access www.cryptogiftbox.com/#/claim and follow the instructions therein to claim the gift.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1520},
        }, {
            id: 'Details_row3',
            value: 'DO NOT REVEAL THE CLAIM KEY until you claim the balance to your wallet.',
            size: 42,
            font: 'Serif',
            maxWidth: 1000,
            color: 'black',
            lineHeight: 52,
            placement: {x: 150, y: 1640},
        }],
        image: {
            original: require('../assets/cards/Slide04.jpg'),
            thumbnail: require('../assets/cards/Slide04.jpg'),
        },
        defaults: {
            message: 'Happy birthday! Enjoy this year and more coming years and cherish them!',
        },
        messageSuggestions: birthdayMessageSuggestions,
        name: 'Test4',
    },
};
export default giftCardsConfig;
