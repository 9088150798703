import * as logger from 'loglevel';
import {SendGiftConfig} from '../store/reducers/sendConfig';
import {txParams} from './loaders';
import {Store} from 'redux';
import {ContractGift, Gift, TransactionInfo} from './models';
import {AppState} from '../store';
import {W3} from 'soltsice';
import {decodeClaimKey, encodeClaimKey} from '../utils/encoders';
import address = W3.address;
import Signature = W3.Signature;
import Web3 = require('web3');
import webService from './webService';
import {getFirestore} from 'redux-firestore';
import {setSentGifts} from '../store/actions/crypto';
import {setClaimField} from '../store/actions/claimConfig';
import firebase from 'firebase/app';
import {computeTotalCost} from '../utils/feeUtils';

let store: Store<AppState>;

export interface GiftCredentials {
    signKey: string;
    accessToken: string;
}

export function initialize(storeInstance: Store<AppState>) {
    store = storeInstance;
}

export async function sendGift(pendingGift: SendGiftConfig): Promise<{ gift: Gift, claimKey: string }> {
    const contract = store.getState().crypto.contract;
    const web3 = store.getState().crypto.web3 as any; // We don't have the right types anyway
    const sender = store.getState().crypto.account;

    // Prepare the credentials, generating a new sign key
    const giftCredentials = generateGiftCredentials(web3);
    const giftValue: number = web3.utils.toWei(pendingGift.value.toString(), 'ether');
    const giftCost: number = web3.utils.toWei(computeTotalCost(pendingGift.value).toString(), 'ether');

    // Submit the gift to the contract
    logger.debug(`Sending gift of ${giftValue} (${giftCost} total cost) from ${sender} using:`, giftCredentials);
    const contractResponse = await contract.createGift(giftCredentials.accessToken, false, txParams(sender, giftCost));
    logger.debug('Received contract response: ', contractResponse);

    // Extract received data
    const giftId = contractResponse.logs[0].args.id.toNumber();
    const txInfo = {
        txHash: contractResponse.tx,
        blockNumber: contractResponse.receipt.blockNumber,
        blockHash: contractResponse.receipt.blockHash,
        gasUsed: contractResponse.receipt.gasUsed,
    };
    logger.info('Gift registered in contract:', encodeClaimKey(giftId, giftCredentials.signKey), txInfo);

    // Persist gift in the datastore
    const gift: Gift = {
        txInfo,
        id: giftId,
        senderName: pendingGift.senderName || null,
        senderAddress: sender,
        receiverName: pendingGift.receiverName || null,
        cardId: pendingGift.cardId,
        value: pendingGift.value,
        message: pendingGift.message,
        requiresApproval: false,
        createdAt: firebase.firestore.Timestamp.now(),
    };

    await getFirestore(null, null).set({collection: 'gifts', doc: giftId.toString()}, gift);
    logger.info('Gift registered in database:', gift);

    // Trigger a sync of contract gifts shortly
    setTimeout(syncSentGifts, 1000);

    return {gift, claimKey: encodeClaimKey(giftId, giftCredentials.signKey)};
}

export async function claimGift(encodedClaimKey: string, receiverAddress: address): Promise<any> {
    const contract = store.getState().crypto.contract;
    const web3: Web3 = store.getState().crypto.web3;
    const {giftId, signKey} = decodeClaimKey(encodedClaimKey);
    const sig: Signature = web3.eth.accounts.sign(receiverAddress.slice(2), signKey) as Signature;

    logger.debug(`Validating claim request for ${giftId}:`, sig);
    const isValid = await (contract.validateMessage(giftId, sig.message, sig.r, sig.s, parseInt(sig.v, 16)));
    if (!isValid) {
        throw Error('Message could not be validated');
    }

    logger.debug('Claim request valid. Submitting claim request...');
    try {
        const response = await webService.claimGift(web3, giftId, receiverAddress, signKey);
        logger.debug('Claim request completed:', response);
        store.dispatch(setClaimField('transactionHash', response.txHash));
    } catch (error) {
        logger.error('Failed to claim gift:', error);
        store.dispatch(setClaimField('error', error));
        throw error;
    }
}

export function validateClaimKey(encodedClaimKey: string) {
    const {giftId, signKey} = decodeClaimKey(encodedClaimKey);
    logger.debug('Decoded claim key:', giftId, signKey);
    if (isNaN(giftId)) {
        throw Error(`Invalid gift id: ${giftId}. Key is too short.`);
    }
    if (isNaN(giftId) || giftId <= 0 || giftId > 2 * 10E9) {
        throw Error(`Invalid gift id: ${giftId}. Key is too long.`);
    }
    if (signKey.length !== 66) {
        throw Error(`Invalid sign key: ${signKey}`);
    }
}

export function loadGift(giftId: number): Promise<ContractGift> {
    const contract = store.getState().crypto.contract;
    const web3 = store.getState().crypto.web3 as any; // We don't have the right types anyway

    if (!contract) {
        return Promise.reject(Error('Unable to connect to contract'));
    }

    return contract.gifts(giftId)
        .then((response: any[]) => {
            logger.info(`Received gift info for #${giftId}:`, response, web3.utils.fromWei(response[4].toString(), 'ether'));
            if (response[0].equals(0)) {
                throw new Error(`Unable to find gift with id #${giftId}`);
            }
            return {
                id: response[0].toNumber(),
                sender: response[1],
                receiver: response[3],
                value: web3.utils.fromWei(response[4].toString(), 'ether') as number,
                requiresApproval: response[8],
                isCompleted: response[9],
            };
        });
}

export function verifyGiftClaimKey(giftId: number, signKey: string): Promise<boolean> {
    const contract = store.getState().crypto.contract;
    const web3: Web3 = store.getState().crypto.web3;

    if (!contract) {
        return Promise.reject(Error('Unable to connect to contract'));
    }

    const message = 'KeyValidationMessageKeyValidationMessage';
    const sig: Signature = web3.eth.accounts.sign(message, signKey) as Signature;

    return contract.validateMessage(giftId, message, sig.r, sig.s, Number(sig.v));
}

export function isAddress(value?: string): boolean {
    const web3: Web3 = store.getState().crypto.web3;
    return value !== undefined && web3.utils.isAddress(value);
}

export function generateGiftCredentials(web3: Web3): GiftCredentials {
    // We're piggy-backing on Ethereum's crypto framework, in order tu make proper checks in Solidity in the contract
    const giftKeyPair = (web3.eth.accounts as any).create();
    return {
        signKey: giftKeyPair.privateKey,
        accessToken: giftKeyPair.address,
    };
}

export async function syncSentGifts(): Promise<number[]> {
    const contract = store.getState().crypto.contract;
    const sender = store.getState().crypto.account;

    if (!contract || !sender) {
        store.dispatch(setSentGifts([]));
        return;
    }

    const sentGifts = (await contract.getCreatedGifts(txParams(sender))).map(it => it.toNumber());
    logger.debug(`Loaded sent gifts for ${sender}:`, sentGifts);
    store.dispatch(setSentGifts(sentGifts));

    return sentGifts;
}
