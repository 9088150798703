import * as logger from 'loglevel';

import {applyMiddleware, combineReducers, compose, createStore, Reducer, Store} from 'redux';
import {vanillaPromise} from './middleware';
import thunk from 'redux-thunk';
import {cryptoReducer, CryptoState} from './reducers/crypto';
import {sendGiftConfigReducer, SendGiftConfig} from './reducers/sendConfig';
import {claimGiftConfigReducer, ClaimGiftConfig} from './reducers/claimConfig';
import {AccountInfo, accountInfoReducer} from './reducers/accountInfo';
import {reactReduxFirebase, firebaseStateReducer} from 'react-redux-firebase';
import {reduxFirestore, firestoreReducer} from 'redux-firestore';
import firebase from 'firebase/app';
import {firebaseApp} from '../fbase';
import {Gift} from '../../functions/src/utils/models';

logger.debug('Initializing data store...');

// Enable support for the Redux DevTools Extension on development builds only
// noinspection TsLint
// const composeWithDevTools = (process.env.NODE_ENV !== 'production'
//     ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : null)
//     || compose;

/** The definition of the state of the App, as stored in Redux. */
export interface AppState {
    crypto: CryptoState;
    sendGiftConfig: SendGiftConfig;
    claimGiftConfig: ClaimGiftConfig;
    accountInfo: AccountInfo;
    firebase: { auth: { uid: string, email: string, displayName: string }, profile: { name: string, email: string } };
    firestore: { data: { gifts: Gift[] } };
}

// Make sure Firestore is initialized
firebaseApp.firestore();

/* The instance of the Redux store of the App. */
export const store: Store<AppState> = createStore(
    // Combine the existing reducers, creating the complete reducer for the entire app
    combineReducers({
        crypto: cryptoReducer,
        sendGiftConfig: sendGiftConfigReducer,
        claimGiftConfig: claimGiftConfigReducer,
        accountInfo: accountInfoReducer,
        firebase: firebaseStateReducer,
        firestore: firestoreReducer as Reducer<any>,
    }),
    // Attach the dev tools and the required middleware
    compose(
        reactReduxFirebase(firebase, {
            userProfile: 'users',
            useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
        }),
        reduxFirestore(firebase, {}),
        applyMiddleware(
            thunk,
            vanillaPromise,
        ),
    ),
);
