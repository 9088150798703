import {AppState} from '../index';
import {isLoaded} from 'react-redux-firebase';

export const getAccount = (state: AppState) => state.crypto.account;
export const getUserId = (state: AppState) => state.firebase.auth.uid;
export const getUserName = (state: AppState) => state.firebase.auth.displayName;
export const getUserEmail = (state: AppState) => state.firebase.auth.email;

export const isAuthLoaded = (state: AppState) => isLoaded(state.firebase.auth);
export const isAccountLoaded = (state: AppState) => state.crypto.account !== undefined;
export const isAuthenticated = (state: AppState) => {
    return isAuthLoaded(state) && !!getAccount(state) && getAccount(state) === getUserId(state);
};
export const isUserConfigured = (state: AppState) => {
    return isAuthenticated(state) && !!getUserName(state) && !!getUserEmail(state);
};
