import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './StepIndicator.scss';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export interface Step {
    path: string;
    component: React.ComponentClass<any> | React.SFC;
    label?: string;
    state?: 'complete' | 'incomplete' | 'active' | 'inactive';
    icon?: IconProp;
}

export interface StepIndicatorProps {
    currentStepIndex: number;
    steps: Step[];
}

const StepIndicator: React.SFC<StepIndicatorProps> = (props: StepIndicatorProps) => (
    <ol className="step-indicator has-text-centered ">
        {props.steps.map(renderStep.bind(this, props.currentStepIndex))}
    </ol>
);

function renderStep(currentStepIdx: number, step: Step, idx: number) {
    if (step.label === undefined) {
        return;
    }
    const state = step.state || (idx === currentStepIdx ? 'active' : idx < currentStepIdx ? 'complete' : 'inactive');
    const icon: IconProp = step.icon || (idx < currentStepIdx ? 'check' : null);
    return <li className={state}>
        <div className="step">{icon != null ? <FontAwesomeIcon icon={icon}/> : (idx + 1)}</div>
        <div className="label is-hidden-touch">{step.label}</div>
    </li>;
}

export default StepIndicator;
