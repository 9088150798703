import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MetaMaskSubmit: React.SFC = () => (
    <section id="MetaMaskSubmit" className="container">

        <div className="content is-medium">

            <div className="hero-body has-text-centered">
                <h2><strong>You're almost done </strong></h2>
                <p>
                    Confirm the transaction in the MetaMask window to create the Crypto GiftBox.
                </p>
            </div>


            <div className="box">

                <div className="columns">
                    <div className="column is-narrow">
                         <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                             transform="grow-10 rotate-30"/>
                            <span className="fa-layers-text has-text-primary"><b>1</b></span>
                        </span>
                    </div>
                    <div className="column">
                        <p><b>Set the Gas Fee</b></p>
                        <p>
                            Every transactions on the Ethereum network requires a fee in the form of gas, independently
                            of our
                            Platform.
                        </p>
                        <p>You can leave the Gas Limit and the Gas Price set by MetaMask or you can change them as you
                            wish.
                        </p>
                        <div className="notification is-warning">
                            <p className="is-size-6"><b>
                                <FontAwesomeIcon icon={['far', 'exclamation-circle']} transform="down-0.5"
                                                 size="1x"/> Notice</b>
                            </p>
                            <p className="is-size-6">
                                The the Gas Limit and the Gas Price need to be set up so that your transaction to be
                                processed on the Ethereum
                                network. Too low Gas Limit might result in loosing your the Gas Fee without having the
                                transaction processed and too low gas Price might cause the transaction to never be
                                processed.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-narrow">
                        <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>2</b></span>
                    </span>
                    </div>
                    <div className="column">
                        <p>

                            <b>Confirm the transaction</b>
                        </p>
                        <p>
                            Click <b><i>Submit</i></b> in the MetaMask window to initiate the transfer of the ether to
                            the
                            smart contract.
                        </p>
                    </div>
                </div>

                <figure className="image">
                    <img src="src/assets/img/metamask/Slide10.jpeg"/>
                </figure>

                <p className="has-text-centered">Check out the questions bellow or see our <Link
                    to="/help">Help</Link> for more
                    information.
                </p>
            </div>

            <div className="hero-body has-text-centered">
                <h2>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'map-marker']} transform="down-1"
                                             size="1x"/>
                        <FontAwesomeIcon className="has-text-white" icon={['fas', 'info']}
                                             transform="shrink-8 right-0.3" size="1x"/>
                    </span>
                    <strong>&nbsp;Short Help</strong>
                </h2>
            </div>

            <div className="question">
                <label>
                    <input id="q9" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q9" className="question_text"> What is MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">MetaMask is a digital wallet allowing you to
                            interact with the Ethereum network and safely transfer the ether (ETH) to the receiver
                            of
                            the
                            Crypto GiftBox.
                        </p>
                        <p className="reveal-if-active_faq_text">For more information about MetaMask, please visit
                            their
                            official website at <a href="https://metamask.io/"
                                                   target="_blank"><i> metamask.io</i></a>.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q10" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q10" className="question_text"> How to install MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">MetaMask is a Chrome or Firefox extension and you
                            simply
                            have to add it to your browser. Once added to your Chrome or Firefox browser, you will
                            only
                            need to set
                            up
                            the wallet and you’re done. If you need some additional help, don’t worry - we have
                            prepared
                            this
                            <a href="#/help/metamask" target="_blank"><i><strong> guide</strong></i></a> for you.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q11" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q11" className="question_text"> What is Gas Fee?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text"> The Gas Fee is
                            the internal pricing for running a transaction or contract in Ethereum network and needs
                            to
                            be paid for each transaction that occurs via the Ethereum network independently of our
                            Site.
                        </p>
                        <p><b>Gas limit</b></p>
                        <p>
                            The gas limit is the maximum amount of gas you are willing to pay for a transaction to
                            take
                            place - in this case, for the ether to be transferred into the smart contract.
                        </p>
                        <p>
                            Each transaction requires a standard number of necessary gas units depending on how much
                            code is
                            executed on the blockchain. You must include enough gas to cover the computational
                            resources
                            or
                            otherwise your transaction will fail and the gas will be lost. All unused gas is
                            refunded to
                            you at
                            the end of the transaction.
                        </p>
                        <p><b>Gas price</b></p>
                        <p>
                            The gas price is the cost of each gas unit.
                            You can spend less on a transaction by lowering the amount you pay per unit of
                            gas. The amount of gas you pay for each unit influences the speed at which the
                            transaction
                            is processed.
                        </p>
                        <p>
                            For quick transactions you should set the gas price higher. However, if
                            you are not in a hurry, you only need to set the amount high enough so that the
                            transaction
                            eventually is processed.
                        </p>
                    </div>
                </label>
            </div>


            <div className="question">
                <label>
                    <input id="q12" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q12" className="question_text"> Why does a locked screen appear?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">Occasionally, MetaMask locks your account
                            automatically. If this happens, simply click on the MetaMask icon and enter your
                            password to
                            unlock it.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q13" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q13" className="question_text"> How can I get ether (ETH)?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">If you are a U.S. citizen, you can buy ether (ETH)
                            directly from your MetaMask wallet.</p>
                        <figure className="image">
                            <img src="src/assets/img/metamask/Slide08.jpeg"/>
                        </figure>

                        <p className="reveal-if-active_faq_text is-size-5">Otherwise, you can purchase ether (ETH)
                            from
                            several exchanges platforms, such as Bitfinex, Bittrex, Binance, Coinbase and then
                            transfer
                            it
                            to your MetaMask wallet.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q14" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q14" className="question_text"> How do I send ether (ETH) to MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">If you purchased ether (ETH) from an exchange
                            platform,
                            or if you already have an Ethereum wallet, you need to send the ether (ETH) to the
                            address
                            of
                            your MetaMask wallet.</p>
                        <p className="reveal-if-active_faq_text">That’s very simple: copy your MetaMask wallet
                            address by clicking on “...” and choose “Copy address to clipboard”. Then, go to your
                            exchange
                            platform account or to you wallet, select “Withdrawn” or “Send” and paste the address
                            from
                            your
                            MetaMask wallet.
                        </p>
                        <figure className="image">
                            <img src="src/assets/img/metamask/Slide9.jpg"/>
                        </figure>

                    </div>
                </label>
            </div>
        </div>

    </section>
);

export default MetaMaskSubmit;
