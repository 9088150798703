import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';
import {AppState} from '../../store';
import {connect} from 'react-redux';
import {SendGiftConfig} from '../../store/reducers/sendConfig';

const ErrorClaimFailed: React.SFC<{ error: Error, transactionHash: string }> = (props) => {
    return (
        <div className="content">
            <div className="hero-body has-text-centered">
                <div className="columns">
                    <div className="column is-one-fifth">
                        <p className="has-text-left">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['far', 'user-md']} transform="down-8"
                                             size="6x"/>
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'medkit']}
                                             transform="down-26 right-30" size="3x"/>
                        </span>
                        </p>
                    </div>
                    <div className="column">
                        <h1 className="title is-spaced"><b>Hmm. Something has gone wrong...</b></h1>

                        <p className="is-size-5">No worries, your Ether is safe. Let's do a quick exam to check what
                            happened.</p>
                    </div>
                </div>
            </div>
            <br/>
            <div className="notification is-warning">
                <p className="is-size-5">
                    Apparently, an error has occurred while trying to claim your Crypto GiftBox:
                </p>
                <code className="is-flex">
                    {props.error.message}
                </code>
            </div>
            <div className="card">
                <div className="card-content">
                    <p className="is-size-5">
                    <span className="icon is-inline-block">
                        <FontAwesomeIcon className="has-text-primary" icon="wrench" size="1x"/>
                    </span> Generally, if an error has occurred, then something has gone wrong with the Ethereum
                        transaction, so your Ether has not been transferred and is safely in the contract.
                        However, in the rare chance that something else happened and the Ether has been transferred,
                        it should be available in your account soon. If that is not the case, please try again, or
                        contact us if the error persists.
                    </p>
                    <div hidden={!props.transactionHash}>
                        <hr/>
                        <p className="has-text-centered"><strong>Recovery info</strong></p>
                        <p>
                            <div className="field is-horizontal">
                                <div className="field-label" hidden={!props.transactionHash}>
                                    <label className="label is-success-heading">Transaction:</label>
                                </div>
                                <div className="field-body" hidden={!props.transactionHash}>
                                    <div className="field">
                                        <p>{props.transactionHash}</p>
                                    </div>
                                </div>
                            </div>

                            <span hidden={!props.transactionHash}><b>Transaction:</b> {props.transactionHash}</span>
                        </p>
                    </div>
                </div>
            </div>
            <br/>
            <Link to="/claim/confirm" className="is-flex is-horizontal-center ">
                <button className="button is-medium is-primary is-outlined">Try again</button>
            </Link>
        </div>
    );
};

export default connect((state: AppState) => ({
    error: state.claimGiftConfig.error,
    transactionHash: state.claimGiftConfig.transactionHash,
}))(ErrorClaimFailed);
