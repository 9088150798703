import * as logger from 'loglevel';
import {log} from 'util';

const regex = /[0-9A-Fa-f]{6}/g;

export const SIGN_KEY_LENGHTH = 64;

export function isHex(inputString: string) {
    return regex.test(inputString);
}

export function hexToBase64(hexString: string): string {
    return new Buffer(hexString, 'hex').toString('base64');
}

export function base64ToHex(base64String: string): string {
    return new Buffer(base64String, 'base64').toString('hex');
}

export function encodeClaimKey(giftId: number, signKey: string) {
    const hexGiftKey = giftId.toString(16);
    // NOTE: we need to have an even number of charactes for the encoding to work right
    const paddedHexGiftKey = (hexGiftKey.length % 2 !== 0 ? '0' : '') + hexGiftKey;
    const decodedClaimKey = signKey.slice(2) + paddedHexGiftKey;
    return hexToBase64(decodedClaimKey);
}

export function decodeClaimKey(claimKey: string): { giftId: number, signKey: string } {
    const decodedClaimKey = base64ToHex(claimKey.replace(' ', ''));
    const signKey = '0x' + decodedClaimKey.slice(0, SIGN_KEY_LENGHTH);
    const giftId = parseInt(decodedClaimKey.slice(SIGN_KEY_LENGHTH), 16);
    return {
        giftId,
        signKey,
    };
}
