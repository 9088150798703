import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

const ErrorWrongNetwork: React.SFC = () => (
    <section className="container">
        <div className="hero error has-text-centered">
            <div className="hero-head ">
                <FontAwesomeIcon className="is-huge has-text-primary" icon={['fal', 'globe']}/>
                <h1 className="title is-2">Unrecognized network</h1>
            </div>

            <div className="hero-body">
                <p className="is-size-5"><strong>Oops...</strong> It seems the Ethereum network is not the right one.
                    Please switch your MetaMask to the <i>Main Ethereum Network</i>.</p>
                <br/>
                <p className="is-size-5">Check out the questions bellow or see our <Link to="/help">Help</Link> for more
                    information.</p>
            </div>
        </div>

        <div className="content is-medium">
            <div className="hero-body has-text-centered">
                <h2>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'map-marker-question']} transform="down-1"
                                         size="1x"/>
                    </span>
                    <strong>&nbsp;What's this?</strong>
                </h2>
            </div>


            <div className="question">
                <label>
                    <input id="q9" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q9" className="question_text"> What is MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">MetaMask is a digital wallet allowing you to
                            interact with the Ethereum network and safely transfer the ether (ETH) to the receiver of
                            the
                            Crypto GiftBox.
                        </p>
                        <p className="reveal-if-active_faq_text">For more information about MetaMask, please visit their
                            official website at <a href="https://metamask.io/" target="_blank"><i> metamask.io</i></a>.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q30" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q30" className="question_text"> What's Ethereum Main Network?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">
                            You can make ether transactions (so to speak, to send the ether to the smart contract) only
                            if you have your MetaMask set on the Ethereum Main Network.
                        </p>
                        <p>If not, don't worry - all you have to do is to
                            change the network from your MetaMask - go on the MetaMask icon (the cute fox on your
                            browser bar), click on the <i>Network</i> icon and select <b>Ethereum Main Network</b>
                        </p>
                        <figure className="image">
                            <img src="src/assets/img/metamask/Slide15.jpeg"/>
                        </figure>
                    </div>
                </label>
            </div>
        </div>


    </section>
);

export default ErrorWrongNetwork;
