import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {RouteComponentProps as RouteProps} from 'react-router';
import {AppState} from '../../store';
import {connect} from 'react-redux';
import * as contractHelper from '../../crypto/contractHelper';
import {W3} from 'soltsice';
import address = W3.address;

class ClaimInProgressStep extends React.Component<{ claimKey: string, receiverAddress: address } & RouteProps<{}>, {}> {
    public async componentDidMount() {
        try {
            await contractHelper.claimGift(this.props.claimKey, this.props.receiverAddress);
            this.props.history.push('/claim/success');
        } catch (error) {
            logger.error('Failed to claim gift:', error);
            this.props.history.replace('/claim/error');
        }
    }

    public render() {
        return (
            <div className="content is-medium has-text-centered">

                <div className="hero error has-text-centered">
                    <div className="hero-head ">
                        <FontAwesomeIcon className="is-huge has-text-primary" icon={['fas', 'hand-holding-box']}/>
                    </div>

                </div>

                <div className="hero-body">
                    <h2><strong>The transaction is being processed...</strong></h2>
                    <p>
                        Please wait. This might take a few minutes. The miners are working hard to process the
                        transaction so that you can enjoy your Crypto GiftBox as soon as possible.
                    </p>
                    <br/>
                    <div className="has-text-primary">
                        <FontAwesomeIcon icon="spinner" size="3x" spin={true}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    claimKey: state.claimGiftConfig.claimKey,
    receiverAddress: state.claimGiftConfig.receiverAddress,
}))(ClaimInProgressStep);
