import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MetaMaskHelp: React.SFC = () => (
    <section id="MetaMaskHelp" className="container">

        <div className="content is-medium">

            <div className="hero-body has-text-centered">
                <h2><strong>How to install and use MetaMask</strong></h2>
                <p className="is-spaced is-size-5">
                    Step by step guide.
                </p>
            </div>

            <div className="notification is-warning">
                <p className="is-size-6"><b>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} transform="down-0.5"
                                     size="1x"/> Notice</b>
                </p>
                <p className="is-size-6">MetaMask is a digital wallet allowing you to
                    interact with the Ethereum network and make Ether (ETH) transfers.</p>
                <p className="is-size-6">
                    Please be very careful with your MetaMask credentials, Seed Words and your Private Key, because anyone
                    having access to them has control on your Ether (ETH).
                </p>
                <p className="is-size-6">For more information about MetaMask, please visit their
                    official website at <a href="https://metamask.io/" target="_blank"><i> metamask.io</i></a>.
                </p>
            </div>

            <br/>
            <h3><strong>Installing MetaMask</strong></h3>

            <p>
                MetaMask is a browser extension for Chrome and Firefox. Although the following steps regards the Chrome extension, the process is similar for the Firefox version, as well.
            </p>
            <br/>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>1</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click this <a
                    href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
                    target="_blank"><i> link</i></a> to go to Chrome Web Store and get Metamask.</p>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>2</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click <b><i>Add to Chrome</i></b> to install MetaMask.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide01.jpeg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>3</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click <b><i>Add extension</i></b> to confirm.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide02.jpeg"/>
                </figure>
            </div>

            <br/>
            <h3><strong>Creating a new account</strong></h3>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>1</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click the MetaMask logo (the cute fox) in the Chrome bar.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide03.jpeg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>2</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Read the Privacy Notice and click on <b><i>Accept</i></b> button.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide04.jpeg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>3</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Read the Terms of Use and click on <b><i>Accept</i></b> button.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide05.jpeg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>4</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Enter a strong password - do not forget it; and click
                    <b><i> Create</i></b>.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide06.jpeg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']} transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>5</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Copy and store the seed (the 12 words displayed by MetaMask). Be very
                    careful with those 12 words, because without them you cannot restore your wallet.</p>
                <figure className="image">
                    <img src="src/assets/img/metamask/Slide07.jpeg"/>
                </figure>
            </div>


        </div>

    </section>
);

export default MetaMaskHelp;
