import * as logger from 'loglevel';

import {Action} from 'redux';
import {SET_ACCOUNT_INFO_ACTION, SetAccountInfoAction} from '../actions/accountInfo';

export interface AccountInfo {
    name?: string;
    email?: string;
}

export function accountInfoReducer(state: AccountInfo = {}, action: Action) {
    switch (action.type) {
        case SET_ACCOUNT_INFO_ACTION:
            return {
                ...state,
                name: (action as SetAccountInfoAction).name,
                email: (action as SetAccountInfoAction).email,
            };
        default:
            return state;
    }
}
