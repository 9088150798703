import * as logger from 'loglevel';
import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import SendGiftFlow from './send/SendGiftFlow';
import ClaimFlow from './claim/ClaimGiftFlow';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Help from './help/Help';
import Navbar from './common/Navbar';
import Home from './home/Home';
import MetaMaskHelp from './help/MetaMaskHelp';
import WalletHelp from './help/WalletHelp';
import TermsOfUse from './terms/TermsOfUse';
import PrivacyPolicy from './terms/PrivacyPolicy';
import CookiePolicy from './terms/Cookie';
import ScrollToTop from './common/ScrollToTop';
import Footer from './common/Footer';
import GiftList from './profile/GiftList';
import MetaMaskSubmit from './help/MetaMaskSubmit';

import './App.scss';
import './theme-variables.scss';
import SignUpScreen from './signup/SignUpScreen';
import ErrorWrongBrowser from './signup/ErrorWrongBrowser';
import Contact from './help/Contact';
import {AnalyticsComponent} from '../utils/Analytics';

class AppComponent extends React.Component<DispatchProp, {}> {
    public render() {
        return (
            <Router>
                <ScrollToTop>
                    <Navbar/>
                    <Route component={AnalyticsComponent}/>
                    <Switch>
                        <Route path="/claim/:step?" component={ClaimFlow}/>
                        <Route path="/send/:step?" component={SendGiftFlow}/>
                        <Route path="/gifts" component={GiftList}/>
                        <Route path="/auth" component={SignUpScreen}/>
                        <Route path="/help/metamask" component={MetaMaskHelp}/>
                        <Route path="/help/wallet" component={WalletHelp}/>
                        <Route path="/help/wrongbrowser" component={ErrorWrongBrowser}/>
                        <Route path="/help/metamasksubmit" component={MetaMaskSubmit}/>
                        <Route path="/help/contact" component={Contact}/>
                        <Route path="/help" component={Help}/>
                        <Route path="/terms/privacy" component={PrivacyPolicy}/>
                        <Route path="/terms/cookie" component={CookiePolicy}/>
                        <Route path="/terms" component={TermsOfUse}/>
                        <Route component={Home}/>
                    </Switch>
                    <Footer/>
                </ScrollToTop>
            </Router>
        );
    }
}

const App = connect()(AppComponent);
export default App;
