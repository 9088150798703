import * as logger from 'loglevel';
import {Store} from 'redux';
import {setAccount, setContract, setWeb3} from '../store/actions/crypto';
import {W3} from 'soltsice';
import address = W3.address;
import {loadContract, loadWeb3} from './loaders';
import {EthGiftBox} from './types';
import {AppState} from '../store';
import * as CryptoManager from './contractHelper';
import Web3 = require('web3');
import {checkAuthMismatch} from '../fbase/auth';

function loadAndStoreAccount(store: Store<AppState>, web3Instance: Web3) {
    return web3Instance.eth.getAccounts()
        .then((accounts: address[]) => {
            const newAccount = accounts.length > 0 ? accounts[0] : null;
            if (newAccount !== store.getState().crypto.account) {
                logger.info('Detected Crypto account:', newAccount);
                store.dispatch(setAccount(newAccount));
                checkAuthMismatch();
                CryptoManager.syncSentGifts();
            }
        })
        .catch((error: any) => {
            logger.warn('Unable to load accounts:', error);
            store.dispatch(setAccount(null));
        });
}

function loadAndStoreContract(store: Store<AppState>, web3Instance: Web3) {
    return loadContract<EthGiftBox>(web3Instance, require('../assets/contracts/EthGiftBox.json'))
        .then((contract: EthGiftBox) => {
            logger.info('Loaded contract:', contract);
            store.dispatch(setContract(contract));
            CryptoManager.syncSentGifts();
        })
        .catch((error: any) => {
            logger.warn('Unable to load contract:', error);
            store.dispatch(setContract(null));
        });
}

function startAccountPolling(store: Store<AppState>, web3Instance: Web3) {
    setInterval(() => {
        loadAndStoreAccount(store, web3Instance);
    }, 750);
}

export function initializeCrypto(store: Store<AppState>) {
    CryptoManager.initialize(store);

    // Load the network provider and web3 instance
    loadWeb3()
        .then((web3Instance: Web3) => {
            logger.debug('Loaded web3:', web3Instance);
            store.dispatch(setWeb3(web3Instance));

            // Start polling for detecting account changes
            startAccountPolling(store, web3Instance);

            // Load the configured account and the contract
            return Promise.all([
                loadAndStoreAccount(store, web3Instance),
                loadAndStoreContract(store, web3Instance),
            ]);
        },
            (reason) => {
                logger.error('Unable to load web3:', reason);
                store.dispatch(setWeb3(null));
            });
}

export {ContractGift} from './models';
