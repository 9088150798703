import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect, DispatchProp} from 'react-redux';
import {AppState} from '../../store';
import {SendGiftConfig} from '../../store/reducers/sendConfig';
import {ReactImageGalleryItem} from 'react-image-gallery';
import {GiftCard} from '../../cards';
import {setGiftField} from '../../store/actions/sendConfig';
import * as pdfGenerator from '../../utils/pdfGenerator';

class GiftPreviewI extends React.Component<{ sendGiftConfig: SendGiftConfig } & DispatchProp, {}> {

    private canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;

    constructor(props) {
        super(props);
    }

    public componentDidMount() {
        this.ctx = this.canvas.getContext('2d');
        this.drawCardOnCanvas(this.props.sendGiftConfig);
    }

    public shouldComponentUpdate(nextProps, nextState) {
        const propertiesMonitored = [
            'cardId', 'value', 'receiverName', 'senderName', 'senderEmail', 'message'];

        for (const property of propertiesMonitored) {
            logger.log('Properties ', property, nextProps.sendGiftConfig[property], this.props.sendGiftConfig[property]);
            if (nextProps.sendGiftConfig[property] !== this.props.sendGiftConfig[property]) {
                //logger.log('Should update based on ', property);
                //logger.log('Next props:' + JSON.stringify(nextProps));
                this.drawCardOnCanvas(nextProps.sendGiftConfig);
                return true;
            }

        }
        return false;
    }

    public drawCardOnCanvas(pendingGift: SendGiftConfig) {

        const dataURL = pdfGenerator.drawCardOnCanvas(pendingGift, this.canvas, this.ctx);
        this.props.dispatch(setGiftField('cardDataURL', dataURL));
    }

    public render() {
        return (
            <div className="gift-preview">
                <canvas ref={canvasRef => this.canvas = canvasRef} className="gift-canvas"/>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        sendGiftConfig: state.sendGiftConfig,
    };
};

const GiftPreview = connect(mapStateToProps)(GiftPreviewI);
export default GiftPreview;
