import {Action} from 'redux';
import {
    SET_SEND_CONFIG_FIELD_ACTION, SetGiftCardAction,
    SET_GIFT_CARD_ACTION, SetSendGiftConfigFieldAction,
    RESET_SEND_CONFIG_ACTION,
} from '../actions/sendConfig';

export interface SendGiftConfig {
    cardId?: string;
    value?: number;
    receiverName?: string;
    senderName?: string;
    senderEmail?: string;
    message?: string;
    isAgreedToTerms: boolean;
    claimKey?: string;
    error?: any;
    transactionHash?: string;
    cardDataURL?: string;
    toEmail?: string;
}

const initialState: SendGiftConfig = {
    value: 0.0053,
    isAgreedToTerms: false,
    toEmail: null,
};

export function sendGiftConfigReducer(state: SendGiftConfig = initialState, action: Action) {
    switch (action.type) {
        case SET_SEND_CONFIG_FIELD_ACTION:
            return {
                ...state,
                [(action as SetSendGiftConfigFieldAction).fieldName]: (action as SetSendGiftConfigFieldAction).value,
            };
        case SET_GIFT_CARD_ACTION:
            return {
                ...state,
                cardId: (action as SetGiftCardAction).cardId,
                message: (action as SetGiftCardAction).card.defaults.message,
            };
        case RESET_SEND_CONFIG_ACTION:
            // If we're not complete yet, we only reset whether the user agreed to the terms or not
            if (!state.claimKey) {
                return {
                    ...state,
                    isAgreedToTerms: false,
                };
            }
            return initialState;
        default:
            return state;
    }
}
