import * as logger from 'loglevel';
import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {connect, DispatchProp} from 'react-redux';
import {W3} from 'soltsice';
import address = W3.address;
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CryptoState} from '../../store/reducers/crypto';
import {AppState} from '../../store';
import {AccountInfo} from '../../store/reducers/accountInfo';

const Footer: React.SFC<{ crypto: CryptoState, accountInfo: AccountInfo }> = ({crypto, accountInfo}) => {
    const accountElement = crypto.account === undefined
        ? <FontAwesomeIcon icon="spinner" size="xs" spin={true}/>
        : crypto.account === null ? 'Locked'
            : `${crypto.account} [${accountInfo.name}]`;
    const contractElement = crypto.contract === undefined
        ? <FontAwesomeIcon icon="spinner" size="xs" spin={true}/>
        : crypto.contract === null ? 'Not deployed' : crypto.contract.address;
    return (
        <footer className="footer">
            <div className="container">
                <div className="content is-marginless has-text-centered">

                    <p>
                        <Link to="">
                            <span className="icon is-medium">
                                    <FontAwesomeIcon icon={['fab', 'twitter']} transform="" size="lg"/>
                            </span>
                        </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <Link to="">
                            <span className="icon is-medium">
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} transform="" size="lg"/>
                            </span>
                        </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="">
                            <span className="icon is-medium">
                                    <FontAwesomeIcon icon={['fab', 'linkedin-in']} transform="" size="lg"/>
                            </span>
                        </Link>
                    </p>

                    <span>Wallet address: {accountElement}</span><br/>
                    <span>Contract: {contractElement}</span>

                    <br/><br/>
                    <span className="has-text-centered">
                        <Link to="/terms">Terms of Use</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/terms/privacy">Privacy Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/terms/cookie">Cookie Policy</Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to="/help/contact">Contact</Link>
                    </span>

                </div>
            </div>

            <div className="copyright">
                <p className="heading is-home-text has-text-centered">
                    <FontAwesomeIcon icon={['far', 'copyright']} size="xs"/>&nbsp;2018<strong> CRYPTO
                    GIFTBOX</strong> <i>by</i> CryptoExplorers. All rights reserved.</p>
            </div>

        </footer>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        accountInfo: state.accountInfo,
        crypto: state.crypto,
    };
};

export default connect(mapStateToProps)(Footer);
