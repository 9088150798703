import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {CryptoState} from '../../store/reducers/crypto';
import InProgress from '../common/LoadingInProgress';
import {AppState} from '../../store';
import {connect, InferableComponentEnhancerWithProps} from 'react-redux';
import ErrorMetamaskNotFound from './ErrorMetamaskNotFound';
import ErrorWrongNetwork from './ErrorWrongNetwork';
import ErrorMetamaskLocked from './ErrorMetamaskLocked';
import SignUpForm from './SignUpForm';
import {compose} from 'redux';
import LoginForm from './LoginForm';
import {RouteComponentProps} from 'react-router';
import * as selectors from '../../store/selectors';

const SignUpScreen: React.SFC<{
    isLoaded: boolean,
    isWeb3Available: boolean,
    isContractAvailable: boolean,
    isAccountAvailable: boolean,
    isAuthenticated: boolean,
    isUserConfigured: boolean,
} & RouteComponentProps<{}>> = (props) => {
    let screenElement: JSX.Element | React.StatelessComponent;
    if (!props.isLoaded) {
        screenElement = <InProgress/>;
    } else if (!props.isWeb3Available) {
        screenElement = <ErrorMetamaskNotFound/>;
    } else if (!props.isContractAvailable) {
        screenElement = <ErrorWrongNetwork/>;
    } else if (!props.isAccountAvailable) {
        screenElement = <ErrorMetamaskLocked/>;
    } else if (!props.isAuthenticated) {
        screenElement = <LoginForm/>;
    } else if (!props.isUserConfigured) {
        screenElement = <SignUpForm/>;
    } else {
        logger.info('User is authenticated and profile is complete...');
        props.history.replace('/');
    }
    return (
        <section className="container">
            {screenElement}
        </section>
    );
};

export default compose(
    connect((state: AppState) => ({
        isLoaded: (state.crypto.web3 !== undefined && state.crypto.contract !== undefined
            && state.crypto.account !== undefined && selectors.isAuthLoaded(state)),
        isWeb3Available: !!state.crypto.web3,
        isContractAvailable: !!state.crypto.contract,
        isAccountAvailable: !!selectors.getAccount(state),
        isAuthenticated: selectors.isAuthenticated(state),
        isUserConfigured: selectors.isUserConfigured(state),
    })),
)(SignUpScreen);
