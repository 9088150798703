import * as logger from 'loglevel';
import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {W3} from 'soltsice';
import {Route, RouteComponentProps, Router, Switch} from 'react-router';
import StepIndicator, {Step} from '../common/StepIndicator';

import './ClaimGiftFlow.scss';
import ClaimSuccessStep from './ClaimSuccessStep';
import ClaimConfigureStep from './ClaimConfigureStep';
import ClaimKeyStep from './ClaimKeyStep';
import ClaimInProgressStep from './ClaimInProgressStep';
import {Link} from 'react-router-dom';
import ClaimConfirmStep from './ClaimConfirmStep';
import {AppState} from '../../store';
import {clearFocusEventHandler} from '../../utils/utils';
import {ClaimGiftConfig} from '../../store/reducers/claimConfig';
import {isAuthenticated} from '../../store/selectors';
import * as contractHelper from '../../crypto/contractHelper';
import ErrorGiftClaimed from './ErrorGiftClaimed';
import ErrorClaimFailed from './ErrorClaimFailed';
import {resetClaimConfig} from '../../store/actions/claimConfig';
import {AnyAction, Dispatch} from 'redux';
// noinspection TsLint
import {History} from 'history';

interface StepConfig extends Step {
    hasNext?: boolean;
    hasPrevious?: boolean;
    isAvailable: (config: ClaimGiftConfig) => boolean;
}

const stepsConfig: StepConfig[] = [
    {
        path: '',
        label: '',
        component: ClaimKeyStep,
        isAvailable: () => true,
    },
    {
        path: 'configure',
        label: '',
        component: ClaimConfigureStep,
        hasPrevious: true,
        hasNext: true,
        isAvailable: config => !!config.contractGift,
    },
    {
        path: 'confirm',
        label: '',
        component: ClaimConfirmStep,
        hasPrevious: true,
        hasNext: true,
        isAvailable: config => !!config.contractGift && contractHelper.isAddress(config.receiverAddress),
    },
    {
        path: 'processing',
        component: ClaimInProgressStep,
        isAvailable: (config) => {
            return !!config.contractGift && contractHelper.isAddress(config.receiverAddress) && config.isAgreedToTerms;
        },
    },
    {
        path: 'success',
        component: ClaimSuccessStep,
        isAvailable: config => !!config.transactionHash,
    },
    {
        path: 'error',
        component: ErrorClaimFailed,
        isAvailable: config => !!config.error,
    },
    {
        path: 'error_claimed',
        component: ErrorGiftClaimed,
        isAvailable: config => !!config.claimKey,
    },
];

class ClaimGiftFlow extends React.Component<{
    stepIdx: number,
    currentStepPath: string,
    nextStep: string,
    previousStep: string,
    isNextStepAvailable: boolean,
    isIndicatorVisible: boolean,
    history: History,
    dispatch: Dispatch<AnyAction>,
}> {

    private static renderStepRoute(step: Step, idx: number) {
        return <Route exact={true} key={idx} path={`/claim/${step.path || ''}`} component={step.component}/>;
    }

    private static checkRightPath(targetStepIdx: number, currentStepPath: string, history: History) {
        // If we're not on the right path for the step, navigate to it
        if (currentStepPath !== stepsConfig[targetStepIdx].path) {
            logger.warn(`Detected wrong step (/${currentStepPath}). Redirecting to: /${stepsConfig[targetStepIdx].path}`);
            history.replace(`/claim/${stepsConfig[targetStepIdx].path}`);
        }
    }

    constructor(props) {
        super(props);
        ClaimGiftFlow.checkRightPath(props.stepIdx, props.currentStepPath, props.history);
    }

    public componentWillUnmount() {
        // When un-mounting this, we clear all previous configuration
        logger.debug('Leaving SendGift flow. Clearing up SendGift configuration...');
        this.props.dispatch(resetClaimConfig());
    }

    public componentWillReceiveProps(nextProps) {
        ClaimGiftFlow.checkRightPath(nextProps.stepIdx, nextProps.currentStepPath, nextProps.history);
    }

    public render(): React.ReactNode {
        // If the step is not available, we will redirect to the right step, but there will still be
        // an intermediate rendering, so we want to avoid showing an invalid step
        if (this.props.currentStepPath !== stepsConfig[this.props.stepIdx].path) {
            return <section className="container"><span>Error. Please start over!</span></section>;
        }
        const isIndicatorVisible = this.props.isIndicatorVisible;
        return <section id="sendGiftFlow" className="container">
            {(isIndicatorVisible) ? <StepIndicator steps={stepsConfig} currentStepIndex={this.props.stepIdx}/> : null}
            <Switch>
                {stepsConfig.map(ClaimGiftFlow.renderStepRoute)}
            </Switch>
            <Link to={`/claim/${this.props.previousStep}`} hidden={this.props.previousStep === null}>
                <button className="button is-pulled-left is-primary is-outlined" onClick={clearFocusEventHandler}>
                    Go back
                </button>
            </Link>
            <Link to={`/claim/${this.props.nextStep}`} hidden={this.props.nextStep === null}>
                <button className="button is-pulled-right is-primary is-outlined" onClick={clearFocusEventHandler}
                        disabled={!this.props.isNextStepAvailable}>
                    Continue
                </button>
            </Link>
        </section>;
    }
}

export default connect(
    (state: AppState) => {
        return {
            isAuthenticated: isAuthenticated(state),
            config: state.claimGiftConfig,
        };
    }, null,
    (stateProps, dispatchProps: DispatchProp, ownProps: RouteComponentProps<{ step: string }>) => {
        let stepIdx: number;
        const currentStepPath = ownProps.match.params.step || '';
        // Try to find a match for the current step. Otherwise, default to the first step
        stepIdx = stepsConfig.findIndex(stp => stp.path === currentStepPath);
        stepIdx = stepIdx < 0 ? 0 : stepIdx;

        // Check whether the step is available and, if not, iterate back to find the last available one
        let step = stepsConfig[stepIdx];
        while (!step.isAvailable(stateProps.config)) {
            stepIdx -= 1;
            step = stepsConfig[stepIdx];
        }

        // logger.debug(stateProps, dispatchProps, ownProps);
        return {
            stepIdx,
            currentStepPath,
            isIndicatorVisible: !!step.label,
            previousStep: step.hasPrevious ? stepsConfig[stepIdx - 1].path : null,
            nextStep: step.hasNext ? stepsConfig[stepIdx + 1].path : null,
            isNextStepAvailable: step.hasNext && stepsConfig[stepIdx + 1].isAvailable(stateProps.config),
            history: ownProps.history,
            dispatch: dispatchProps.dispatch,
        };
    },
)(ClaimGiftFlow);
