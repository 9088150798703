import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import './Help.scss';
import {NavLink} from 'react-router-dom';

const Help: React.SFC<RouteComponentProps<{}>> = () => (
        <section id="help" className="container">

            <div className="content is-medium">

                <div className="hero-body has-text-centered">
                    <h2>
                        <span className="icon has-text-primary">
                                <FontAwesomeIcon icon={['far', 'rocket']} transform="down-3.5" size="1x"/>
                            </span>
                        <strong>&nbsp;&nbsp;Getting started</strong>
                    </h2>
                </div>

                <div className="question">
                    <label>
                        <input id="q1" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q1" className="question_text"> What is a Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">A Crypto GiftBox is a customizable Ether gift card
                                allowing you to
                                easily
                                and safely send Ether (ETH), as a gift, to anyone you'd like - friends, family, colleagues
                                or employees.
                            </p>
                            <p className="reveal-if-active_faq_text">But unlike the traditional gift cards, the Crypto
                                GiftBox
                                is
                                based on the revolutionary blockchain technology, using smart contracts to ensure the safety
                                transfer of the Ether (ETH).
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q29" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q29" className="question_text">How Crypto GiftBox works?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">
                                Customize your Crypto GiftBox just as you like - choose your
                                favorite template, add whatever amount of Ether (ETH) you
                                want, write a special message and sign it.
                            </p>
                            <p>Once you finish crafting your Crypto GiftBox, and while still admiring your work, you can
                                download the Crypto GiftBox to your computer or, if you wish, you can receive it directly on
                                your email. Or better, you can have it both ways.
                            </p>
                            <p>Then, all you have to do is to give the Crypto GiftBox to the lucky receiver and enjoy the
                                moment.
                            </p>
                            <p>When the happy receiver wishes to redeem the Ether (ETH), she or he can easily receive it by
                                using the unique secret claim key indicated on the Crypto GiftBox.
                            </p>
                            <p>And...that's it. You've just created a special moment by sending the gift of the future with
                                Crypto GiftBox.
                            </p>
                        </div>
                    </label>
                </div>


                <div className="question">
                    <label>
                        <input id="q26" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q26" className="question_text"> What do I need to send a Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">All you need is: </p>
                            <ul className="reveal-if-active_faq_list">
                                <li>A computer or laptop having installed the Chrome or Firefox browser;</li>
                                <li>MetaMask, a digital wallet used specifically with Chrome or Firefox browser -
                                    unfortunately, you
                                    cannot create a Crypto GiftBox and send Ether (ETH) without installing MetaMask.
                                </li>
                            </ul>
                            <p className="reveal-if-active_faq_text"> If you wonder what is MetaMask, please check out the
                                below section <i><b>About MetaMask</b></i>.</p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q27" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q27" className="question_text"> How can I send a Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">
                                Once you have the MetaMask installed, you need to create your Crypto GiftBox account -
                                dont't
                                worry, it only takes one minute.
                            </p>
                            <p>
                                Then, all you have to do is to go to <NavLink to="/send" target="_blank"><i>Send
                                gift</i></NavLink> and follow a few simple steps. It's very easy and you'll get plenty of
                                help from us along the way.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q25" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q25" className="question_text"> What do I need to claim a Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">All you need is your claim key (indicated on the Crypto
                                GiftCard), an Ethereum wallet and a few minutes.</p>

                            <p className="reveal-if-active_faq_text"> If you don't have an Ethereum wallet yet, don’t worry
                                - you can
                                create one immediately. Just follow these simple <NavLink
                                    to="/help/wallet" target="_blank"><i><strong>instructions</strong></i></NavLink> and you
                                should have your wallet done in a few minutes.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q24" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q24" className="question_text"> How can I claim a Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">That's very simple. All you have to do is to go
                                to <NavLink to="/claim" target="_blank"><i>Claim
                                    gift</i></NavLink> and follow a few simple steps. <b>Spoiler alert:</b> you'll need your
                                claim key
                                (indicated
                                on the Crypto GiftCard) and your Ethereum wallet address.
                            </p>
                            <p>If you need a little help on how to create an Ethereym wallet and redeem your Crypto GiftBox,
                                you can
                                check out these <NavLink
                                    to="/help/wallet" target="_blank"><i><strong> instructions</strong></i></NavLink>.
                            </p>
                        </div>
                    </label>
                </div>

                <br/>
                <div className="hero-body has-text-centered">
                    <h2>
                        <span className="has-text-primary">
                            <FontAwesomeIcon icon={['far', 'cogs']} transform="down-1" size="1x"/>
                        </span>
                        &nbsp;&nbsp;<strong>About Crypto GiftBox</strong>
                    </h2>
                </div>

                <div className="question">
                    <label>
                        <input id="q2" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q2" className="question_text">How is the Ether (ETH) transferred to the
                            receiver?</label>
                        <div className="reveal-if-active_faq">
                            <p>
                                Each time you create a Crypto GiftBox, a unique secret claim key will be generated and
                                printed
                                on the Crypto GiftBox.
                                Once you finish creating the Crypto GiftBox, the Ether (ETH) you wish to give will be safely
                                deposited into a smart contract, and locked there waiting to be
                                withdrawn by the lucky receiver of
                                the Crypto GiftBox.
                            </p>
                            <p className="reveal-if-active_faq_text">
                                When the receiver wants to claim the Ether (ETH), he or she will need to use the secret
                                claim key
                                printed on the Crypto GiftBox to prove that he or she is indeed the rightful receiver. If
                                the request is indeed signed with the
                                secret claim key, the Ether (ETH) will be automatically transferred from the
                                smart
                                contract to his or her wallet - otherwise, the Ether (ETH) will remain in the smart
                                contract.
                            </p>
                            <p className="reveal-if-active_faq_text">
                                <FontAwesomeIcon icon="exclamation-circle" size="1x"/> Using the secret claim key is the
                                only possible way to
                                claim the Ether (ETH); there is no other way to withdrawn the Ether (ETH) without using the
                                secret claim key.
                            </p>

                            <div className="columns">
                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <span className="heading has-text-centered is-help-title">
                                                Create the Crypto GiftBox
                                            </span>
                                        </header>
                                        <div className="card-content">
                                            <p className="heading has-text-centered is-help-text">
                                                A unique secret claim key is randomly generated and printed on the Crypto
                                                GiftBox.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-narrow is-centered">
                                    <br/>
                                    <span className="icon has-text-primary">
                                        <FontAwesomeIcon icon={['fal', 'long-arrow-right']} size="2x"/>
                                    </span>
                                </div>
                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="heading has-text-centered is-help-title">
                                                Transfer the ether (ETH)
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <p className="heading has-text-centered is-help-text">
                                                The ether is safely deposited into a smart contract until is claimed by the
                                                receiver.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-narrow is-centered">
                                    <br/>
                                    <span className="icon has-text-primary">
                                        <FontAwesomeIcon icon={['fal', 'long-arrow-right']} size="2x"/>
                                    </span>
                                </div>
                                <div className="column">
                                    <div className="card">
                                        <header className="card-header">
                                            <p className="heading has-text-centered is-help-title">
                                                Claim the Crypto GiftBox
                                            </p>
                                        </header>
                                        <div className="card-content">
                                            <p className="heading has-text-centered is-help-text">
                                                The ether can be redeemed only by signing the request with the secret claim
                                                ckey.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q16" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q16" className="question_text"> Wait, what's a smart contract?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">A smart contract is an agreement codified on the
                                Ethereum network - in other words, is a computer program, which
                                automatically executes once a condition is fulfilled.
                            </p>
                            <p className="reveal-if-active_faq_text">A smart contract enables two or more parties to safely trade and do business over the internet without the need of a trusted third-party to verify or uphold the terms of the agreement.

                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q15" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q15" className="question_text"> Why is safe to send a Crypto
                            GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Basically, the smart contract is like an escrow
                                account here.
                            </p>
                            <p className="reveal-if-active_faq_text"> You deposit the Ether (ETH) into the smart contract,
                                and
                                once is deposited, no
                                one, but the receiver, can redeem it.
                            </p>

                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q3" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q3" className="question_text"> What coins can I send?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Currently, you can send only Ether (ETH).
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q4" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q4" className="question_text"> Can I use fiat currency to send a Crypto
                            GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Unfortunately, no.
                            </p>
                            <p className="reveal-if-active_faq_text">
                                You need first to convert the fiat currency (e.g. USD) into Ether (ETH).
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q6" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q6" className="question_text"> Once sent the Crypto GiftBox, can I change my
                            mind?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Yes, you can.
                            </p>
                            <p className="reveal-if-active_faq_text">As long as the Ether (ETH) is not claimed, you have
                                the possibility to revoke the gift. However, you can withdraw the Ether (ETH) only as long
                                as you
                                use the same Ethereum wallet address from which you transferred the Ether (ETH) when you
                                created the
                                Crypto GiftBox. Otherwise, the Ether (ETH) cannot be withdrawn.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q7" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q7" className="question_text"> What happens if the Crypto GiftBox is
                            lost?</label>
                        <div className="reveal-if-active_faq">

                            <p>The secret claim key printed on the Crypto GiftBox is meant to secure the transfer of the
                                Ether (ETH) to the receiver.</p>
                            <p className="reveal-if-active_faq_text">Thus, in such a sad event, unfortunately, the receiver
                                won't
                                be able to redeem the Ether (ETH). Worse, anybody having the claim key can withdrawn the
                                Ether (ETH).
                            </p>
                            <p className="reveal-if-active_faq_text"> To prevent such a misfortune situation, we
                                strongly recommend to protect very carefully the Crypto GiftBox and the secret claim key
                                until the Ether (ETH) is
                                withdrawn.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q8" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q8" className="question_text"> Does it cost to send a
                            Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Yes, each time you create a Crypto GiftBox, we
                                will collect a small commission of 3.5% of the total value of the gift.
                            </p>
                            <p className="reveal-if-active_faq_text">Additionally, independently of us, each transaction on
                                the Ethereum network
                                will require a Gas Fee. For more information, see the below questions about <i>Gas
                                    Fee</i>.
                            </p>
                        </div>
                    </label>
                </div>

                <br/>

                <div className="hero-body has-text-centered">
                    <h2>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'rectangle-landscape']}
                                             transform="down-1" size="1x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['fab', 'ethereum']}
                                             transform="shrink-8 down-1 right-0.5" size="1x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['fas', 'rectangle-wide']}
                                             transform="shrink-13 down-1 right-7"/>
                        </span>
                        <strong>&nbsp;&nbsp;About MetaMask</strong></h2>
                </div>

                <div className="question">
                    <label>
                        <input id="q9" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q9" className="question_text"> What is MetaMask?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">MetaMask is a digital wallet allowing you to
                                interact with the Ethereum network and safely transfer the Ether (ETH) to the receiver of
                                the
                                Crypto GiftBox.
                            </p>
                            <p className="reveal-if-active_faq_text">For more information about MetaMask, please visit their
                                official website at <a href="https://metamask.io/" target="_blank"><i> metamask.io</i></a>.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q10" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q10" className="question_text"> How to install MetaMask?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">MetaMask is a Chrome or Firefox extension, and you
                                simply
                                have to add it to your browser. Once added to your Chrome or Firefox browser, you will only
                                need to set
                                up
                                the wallet and you’re done.
                            </p>
                            <p>Please make sure you keep your MetaMask credentials and recovery information in a safe
                                place.
                            </p>
                            <p>If you need some additional help, don’t worry - we've prepared
                                this
                                <NavLink to="help/metamask" target="_blank"><i><strong> guide</strong></i></NavLink> for
                                you.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q13" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q13" className="question_text"> How can I get Ether (ETH)?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">If you are a U.S. citizen, you can buy Ether (ETH)
                                directly from your MetaMask wallet.</p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide08.jpeg"/>
                            </figure>

                            <p className="reveal-if-active_faq_text is-size-5">Otherwise, you can purchase Ether (ETH) from
                                several exchanges platforms, and then transfer
                                it to your MetaMask wallet.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q14" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q14" className="question_text"> How do I send Ether (ETH) to MetaMask?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">If you purchased Ether (ETH) from an exchange
                                platform, or if you already have Ether (ETH) in your Ethereum wallet, you need to send the
                                Ether (ETH) to the address of your MetaMask wallet.</p>
                            <p className="reveal-if-active_faq_text">That’s very simple: copy your MetaMask wallet
                                address by clicking on <b>“...”</b> and choose <b><i>Copy address to clipboard</i></b>.
                                Then, go to your exchange platform account or to you wallet, select
                                <b><i>Withdrawn</i></b> or <b><i>Send</i></b> and paste the address from your
                                MetaMask wallet.
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide09.jpeg"/>
                            </figure>

                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q30" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q30" className="question_text"> What's Ethereum Main Network?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">
                                You can make Ether (ETH) transactions (e.g., to send the Ether (ETH) to the smart contract)
                                only
                                if you have your MetaMask set on the Ethereum Main Network.
                            </p>
                            <p>If not, don't worry - all you have to do is to
                                change the network from your MetaMask - go on the MetaMask icon (the cute fox on your
                                browser bar), click the <b><i>Network</i></b> icon and select <b><i>Ethereum Main
                                    Network</i></b>
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide15.jpeg"/>
                            </figure>
                        </div>
                    </label>
                </div>

                <br/>
                <div className="hero-body has-text-centered">
                    <h2>
                        <span className="icon has-text-primary">
                            <FontAwesomeIcon icon={['far', 'user']} transform="down-3.5" size="1x"/>
                        </span>
                        <strong>&nbsp;&nbsp;My account</strong></h2>
                </div>

                <div className="question">
                    <label>
                        <input id="q22" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q22" className="question_text"> How is my Crypto GiftBox account created?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">
                                We use cryptographic algorithms and your MetaMask account to create your Crypto GiftBox
                                account.
                                As long as you as you use the same MetaMask account, you can access your Crypto GiftBox
                                account anytime,
                                without a
                                password.
                            </p>
                            <p>
                                Please make sure you keep your MetaMask credentials and recovery information in a safe
                                place.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q23" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q23" className="question_text">Why do not I need a password?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Your Crypto GiftBox account is created by using your
                                MemaMask account. Only by using the same
                                MetaMask account can access your Crypto GiftBox account.
                            </p>
                            <p>
                                So, long as you keep your MetaMask credential safe, it's ok - you can access your
                                Crypto GiftBox account, without a password.
                            </p>

                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q18" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q18" className="question_text">What happens if I lose my MetaMask
                            credentials?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Unfortunately, in such case you won't be able to access
                                your
                                Crypto GiftBox
                                account either.
                            </p>
                            <p>But don't worry, you can create a new one anytime.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q17" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q17" className="question_text">How can I change the MetaMask account?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">That's very simple. Open the MetaMask and go on the
                                <b><i> Account</i></b> icon in the top right corner. Then, select the account you want.
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide12.jpeg"/>
                            </figure>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q19" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q19" className="question_text">Why do I need to sign that MetaMask message when I
                            sign in?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">So that you prove that you are the right owner of the
                                account.
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide14.jpeg"/>
                            </figure>

                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q12" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q12" className="question_text">Why does a locked screen appear?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Occasionally, MetaMask locks your account
                                automatically. If this happens, simply click on the MetaMask icon and enter your password to
                                unlock it.
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide11.jpeg"/>
                            </figure>

                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q20" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q20" className="question_text">How can I log out from my Crypto GiftBox
                            account?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">By logging out from your MetaMask account. Open the
                                MetaMask and go on the <b><i>Settings</i></b> icon from the top right corner. Then click <b><i>Log
                                    out</i></b>.
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide13.jpeg"/>
                            </figure>

                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q21" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q21" className="question_text"> Why am I logged out when I change the MetaMask
                            account?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">Your Crypto GiftBox account is linked to your MetaMask
                                account. If you do not use the right MetaMask account, you cannot access your
                                Crypto GiftBox account and you will be automatically logged out.
                            </p>
                            <p>
                                Don't worry, you can sign in again anytime, by selecting the right account in MetaMask.
                            </p>
                            <figure className="image">
                                <img src="src/assets/img/metamask/Slide12.jpeg"/>
                            </figure>

                        </div>
                    </label>
                </div>

                <br/>
                <div className="hero-body has-text-centered">
                    <h2>
                        <span className="icon has-text-primary">
                            <FontAwesomeIcon icon={['far', 'map']} transform="down-3.5" size="1x"/>
                        </span>
                        <strong>&nbsp;&nbsp;Other</strong></h2>
                </div>

                <div className="question">
                    <label>
                        <input id="q11" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q11" className="question_text"> What is Gas Fee?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text"> The Gas Fee is
                                the internal pricing for running a transaction or contract in Ethereum network and needs
                                to
                                be paid for each transaction that occurs via the Ethereum network independently of our
                                platform.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q28" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q28" className="question_text"> What is the Gas Limit?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">
                                The Gas Limit is the maximum amount of gas you are willing to pay for a transaction to
                                take
                                place - in this case, for the Ether (ETH) to be transferred into the smart contract.
                            </p>
                            <p>
                                Each transaction requires a standard number of necessary gas units depending on how much
                                code is
                                executed on the blockchain. You must include enough gas to cover the computational
                                resources,
                                otherwise your transaction will fail and the gas will be lost. All unused gas is
                                refunded to
                                you at
                                the end of the transaction.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q40" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q40" className="question_text"> What is Gas Price?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">
                                The Gas Price is the cost of each gas unit.
                                You can spend less on a transaction by lowering the amount you pay per unit of
                                gas. The amount of gas you pay for each unit influences the speed at which the
                                transaction
                                is processed.
                            </p>
                            <p>
                                For quick transactions you should set the Gas Price higher. If
                                you are not in a hurry, you only need to set the amount high enough so that the
                                transaction eventually is processed - however, the amount should not be too low so that
                                the miners won't process it.
                            </p>
                        </div>
                    </label>
                </div>

                <br/>
                <div className="hero-body has-text-centered">
                    <h2>
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['far', 'user']} transform="down-2"
                                             size="1x"/>
                            <FontAwesomeIcon className="has-text-primary" icon={['far', 'comment']}
                                             transform="shrink-4 up-10 right-12" size="xs"/>
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'question']}
                                             transform="shrink-10 up-10 right-12"/>
                        </span>
                        <strong>&nbsp;&nbsp;&nbsp;&nbsp;Still unclear?</strong></h2>
                </div>
                <p className="has-text-centered">
                    <NavLink to="help/contact" className=""> Write us</NavLink> for any questions and we will gladly help
                    you.
                </p>

            </div>

        </section>
    )
;

export default Help;
