import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WalletHelp: React.SFC = () => (
    <section id="WalletHelp" className="container">

        <div className="content is-medium">

            <div className="hero-body has-text-centered">
                <h2><strong>How to install a wallet and claim your Ether</strong></h2>
                <p className="is-spaced is-size-5">
                    Step by step guide.
                </p>
            </div>

            <div className="notification is-warning">
                <p className="is-size-6"><b>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} transform="down-0.5"
                                     size="1x"/> Notice</b>
                </p>
                <p className="is-size-6">These are only two examples of Ethereum wallets, which we do not own or
                    control. There are a dozen of other wallets that can help you store your Ether (ETH) and you can
                    choose any of them.</p>
                <p className="is-size-6">
                    However, whatever wallet you choose to use, please be very careful with your credentials and your
                    Private Key, because anyone having them has control over your Ether (ETH).
                </p>
            </div>
            <br/>
            <h3><strong>MyEtherWallet</strong></h3>

            <div className="box">
                <p><strong><span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>1</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Go to <a href="www.myetherwallet.com"
                                                    target="_blank"><i>www.myetherwallet.com</i></a>, and click <b><i>New
                    Wallet</i></b>.</p>
                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide4.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>2</b></span>
                    </span></strong>&nbsp;&nbsp;&nbsp;Enter a strong password. Do not forget it.</p></div>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>3</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click <b><i>Create New Wallet</i></b>.</p></div>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>4</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Download the Keystore / UTC file and save it somewhere safe
                    (it can be further used to access your Ethereum wallet).</p>
                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide5.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>5</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click <b><i>I understand. Continue</i></b>.</p></div>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>6</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Print your Paper Wallet backup and / or handwrite the
                    Private Key on a piece of paper.</p>
                <p>Be very careful with your Private Key. Do not misplace it, because you can control your Ether (ETH)
                    only by
                    using this Private Key. Do no lose it, because anyone having this Private Key can access your
                    Ether (ETH).</p>
                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide6.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                   <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>7</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Click <b><i>Save Your Address</i></b>.</p></div>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>8</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;From <b><i>How would you like to access your
                    wallet?</i></b> select <b><i>Private Key</i></b> and enter the Private Key you have saved or
                    handwritten when
                    you created your wallet.</p>
                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide7.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                   <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>9</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Unlock your wallet.</p></div>
            <div className="box">
                <p><strong>
                   <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>10</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Copy your address.</p>
                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide8.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>11</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Paste your wallet address into the text box from our platform.
                </p></div>
            <p>For more information on how to create a MyEtherWallet, you can access the following <a
                href="https://myetherwallet.github.io/knowledge-base/getting-started/creating-a-new-wallet-on-myetherwallet.html"
                target="_blank"><i>link</i>.</a></p>

            <h4><strong>Did you forget to copy the wallet address?</strong></h4>
            <p>No worries. If you did not copy the address when you created your wallet, you can access it anytime by
                following the next steps:</p>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>1</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Go to <a href="www.myetherwallet.com"
                                                    target="_blank"><i>www.myetherwallet.com </i></a>
                    and access <b><i>View Wallet Info</i></b>.</p></div>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>2</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;From <b><i>How would you like to access your
                    wallet?</i></b>select <b><i>Private Key</i></b> and enter the Private Key you have saved or
                    handwritten when you
                    created your wallet.</p></div>
            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>3</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Unlock your wallet.</p></div>

            <br/>
            <hr/>
            <h3><strong>Jaxx wallet</strong></h3>

            <h4><strong>Install Jaxx</strong></h4>
            <p>In order to create a Jaxx wallet, you first have to install Jaxx on your device. For
                detailed instructions on how to download and install Jaxx, please access the official site <a
                    href="www.jaxx.io" target="_blank"><i> www.jaxx.io</i></a>.</p>
            <p>You can also install Jaxx as a Chrome extension. Although the follosing steps regards the desktop version of Jaxx, the process is similar for the extension version, as well.</p>

            <h4><strong>Create wallet and import your Ether (ETH)</strong></h4>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>1</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;After you install Jaxx on your device, select <b><i>Create New Wallet</i></b>.
                </p>
                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide1.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>2</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Select <b><i>Wallets</i></b> at the top right of the Jaxx screen and check
                    the
                    <b> ETH - Ethereum</b> wallet.</p>

                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide2.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>3</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Copy your wallet address.</p>

                <figure className="image">
                    <img src="src/assets/img/wallet_help/Slide3.jpg"/>
                </figure>
            </div>

            <div className="box">
                <p><strong>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'hexagon']}
                                         transform="grow-10 rotate-30"/>
                        <span className="fa-layers-text has-text-primary"><b>4</b></span>
                    </span>
                </strong>&nbsp;&nbsp;&nbsp;Paste your wallet address into the text box from our platform.
                </p></div>

        </div>

    </section>
);

export default WalletHelp;
