import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {W3} from 'soltsice';
import address = W3.address;
import {authenticate} from '../../fbase/auth';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';

class SignUpForm extends React.Component<{ account: address } & DispatchProp, {
    inProgress: boolean,
    isError: boolean,
    isSignatureDenied: boolean,
}> {

    constructor(props) {
        super(props);
        this.state = {isError: false, inProgress: false, isSignatureDenied: false};
    }

    public render() {
        const errorMessage = this.state.isError ? this.state.isSignatureDenied
            ? 'It seems you\'ve denied the signature request. Your approval is required in order to authenticate.'
            : 'It seems we\'ve encountered an error while authenticating. Please try again.'
            : null;

        return (
            <div className="content">
                <div className="hero-body has-text-centered is-size-5">
                    <h2><strong>Welcome! Glad to have you here!</strong></h2>
                    <p>Sign in and send the gift of the future with Crypto GiftBox.
                    </p>
                </div>
                <div className="notification is-primary">
                    <p className="is-size-5"><strong>New on Crypto GiftBox?</strong></p>
                    <p>Welcome! Click <b>Sign In</b> and create your Crypto GiftBox account within seconds.
                    </p>
                    <p className="is-size-5"><strong>Already signed up?</strong></p>
                    <p>Welcome back! Make sure you have the right account selected in MetaMask and click <b>Sign In</b>.
                    </p>
                    See our <NavLink to="/help"
                                     target="_blank"><b>Help</b></NavLink> for more information.
                </div>
                <div className="box">
                    <div className="field is-paddingless">
                        <label className="label is-size-5">
                            Ethereum Account
                            <div className="tooltip">
                                <FontAwesomeIcon icon="question-circle"/>
                                <span className="tooltiptext">
                                This is your Ethereum account, as selected in MetaMask. If it's not the account you're
                                using, please change it in MetaMask.
                            </span>
                            </div>
                        </label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" disabled={true} value={this.props.account}/>
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={['fab', 'ethereum']} mask={['fas', 'rectangle-landscape']}
                                                 transform="shrink-5" size="lg"/>
                            </span>
                        </div>
                    </div>
                    <br/>
                    <div className="message">
                        <div className="message-body">
                            After clicking <b>Sign In</b>, a MetaMask window will pop up and you will need to sign the
                            message therein
                            so that we know that you are indeed the owner of the account.
                        </div>
                    </div>
                    <div className="message is-danger" hidden={!this.state.isError}>
                        <div className="message-body">
                            {errorMessage}
                        </div>
                    </div>
                    <button className={classNames('button is-medium', {'is-loading': this.state.inProgress})}
                            onClick={this.handleLogin}>Sign In
                    </button>
                </div>
            </div>
        );
    }

    private handleLogin = async () => {
        this.setState({inProgress: true, isError: false});
        try {
            await authenticate(this.props.account);
        } catch (error) {
            logger.error(error);
            return this.setState({
                inProgress: false, isError: true,
                isSignatureDenied: error.message.indexOf('User denied message signature') >= 0,
            });
        }
    };
}

const mapStateToProps = (state: AppState) => {
    return {
        account: state.crypto.account,
    };
};

export default connect(mapStateToProps)(SignUpForm);
