import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AppState} from '../../store';
import {connect} from 'react-redux';
import {W3} from 'soltsice';
import address = W3.address;
import {withFirebase} from 'react-redux-firebase';
import {compose} from 'redux';
import {validate as validateEmail} from 'email-validator';
import classNames from 'classnames';
import {updateUserProfile} from '../../fbase/auth';
import {NavLink} from 'react-router-dom';

class SignUpForm extends React.Component<{ account: address, firebase }, {
    email: string,
    isEmailError: boolean,
    name: string,
    inProgress: boolean,
    areTermsAgreed: boolean,
}> {

    constructor(props) {
        super(props);
        this.state = {email: '', isEmailError: false, name: '', areTermsAgreed: false, inProgress: false};
    }

    public render() {
        const isSubmitEnabled: boolean = !this.state.isEmailError && this.state.areTermsAgreed
            && !!this.state.email && !!this.state.name.trim();
        return (
            <div className="content">
                <div className="hero-body has-text-centered is-size-5">
                    <h2><strong>Let's set up your account</strong></h2>
                    <p>Join us and send the gift of the future with Crypto GiftBox.</p>
                </div>
                <div className="notification is-primary">
                    <p className="is-size-5"><strong>New on Crypto GiftBox?</strong></p>
                    <p>Welcome! We only
                        need your email and your name, because as long as you use the same MetaMask account you can
                        access your Crypto GiftBox account anytime. So, please make
                        sure you keep your MetaMask credentials and recovery information in a safe place.
                    </p>
                    <p className="is-size-5"><strong>Already signed up?</strong></p>
                    <p>Welcome back! It seems you are using another MetaMask account. Please make sure you use the
                        right MetaMask account and everything should be ok.
                    </p>
                    See our <NavLink to="/help"
                                     target="_blank"><b>Help</b></NavLink> for more information.
                </div>

                <div className="box">
                    <div className="field is-paddingless">
                        <label className="label is-size-5">
                            Name
                            <div className="tooltip">
                                <FontAwesomeIcon icon="question-circle"/>
                                <span className="tooltiptext">
                                    This helps us to know who you are in the right places in the platform.
                                </span>
                            </div>
                        </label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" placeholder="Your name or nickname"
                                   value={this.state.name} onChange={this.handleNameFieldChange}/>
                            <span className="icon is-small is-left"><FontAwesomeIcon icon="user"/></span>
                        </div>
                    </div>

                    <div className="field is-paddingless">
                        <label className="label is-size-5">
                            Email address
                            <div className="tooltip">
                                <FontAwesomeIcon icon="question-circle"/>
                                <span className="tooltiptext">
                                    We need your email address to be able to get in touch. Also, we will send your
                                    Crypto GiftBox confirmations on this email, so please make sure to enter a valid
                                    email address.
                                </span>
                            </div>
                        </label>
                        <div className="control has-icons-left has-icons-right">
                            <input className={classNames('input', {'is-danger': this.state.isEmailError})}
                                   value={this.state.email} type="email" placeholder="Your email address"
                                   onChange={this.handleEmailFieldChange} onBlur={this.handleEmailFieldBlur}/>
                            <span className="icon is-small is-left"><FontAwesomeIcon icon="envelope"/></span>
                            <span className="has-text-danger" hidden={!this.state.isEmailError}>
                                This doesn't look like a valid email address.
                            </span>
                        </div>
                    </div>

                    <div className="field is-spaced">
                        <label className="label is-size-5">
                            Ethereum Account
                            <div className="tooltip">
                                <FontAwesomeIcon icon="question-circle"/>
                                <span className="tooltiptext">
                                    This is your Ethereum account, as selected in MetaMask. If it's not the account
                                    you're using, please change it in MetaMask.
                                </span>
                            </div>
                        </label>
                        <div className="control has-icons-left">
                            <input className="input" type="text" disabled={true} value={this.props.account}/>
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={['fab', 'ethereum']} mask={['fas', 'rectangle-landscape']}
                                                 transform="shrink-5" size="lg"/>
                            </span>
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <label className="checkbox">
                                <input type="checkbox" checked={this.state.areTermsAgreed}
                                       onChange={this.handleTermsFieldChange}/>
                                I agree to the <a href="#/terms" target="_blank">Terms of Use</a> and the <a
                                href="#/terms/privacy" target="_blank">Privacy Policy</a>
                            </label>
                        </div>
                    </div>

                    <br/>
                    <button className={classNames('button is-medium', {'is-loading': this.state.inProgress})}
                            onClick={this.handleSubmit} disabled={!isSubmitEnabled}>
                        Sign Up
                    </button>
                </div>
            </div>
        );
    }

    private handleNameFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({name: event.target.value});
    };

    private handleTermsFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({areTermsAgreed: event.target.checked});
    };

    private handleEmailFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({email: event.target.value, isEmailError: false});
    };

    private handleEmailFieldBlur = () => {
        this.setState({isEmailError: !validateEmail(this.state.email)});
    };

    private handleSubmit = async () => {
        this.setState({inProgress: true});
        logger.debug(`Saving user profile: ${this.state.name} - ${this.state.email}`);
        try {
            await updateUserProfile(this.state.name, this.state.email);
            this.setState({inProgress: false});
        } catch (error) {
            logger.error('Failed to update profile:', error);
            this.setState({inProgress: false});
        }
    };
}

export default compose(
    withFirebase,
    connect((state: AppState) => ({
        account: state.crypto.account,
    })),
)(SignUpForm);
