import * as logger from 'loglevel';
import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {Link} from 'react-router-dom';
import {Route, RouteComponentProps, Switch} from 'react-router';
import StepIndicator, {Step} from '../common/StepIndicator';
import SendWelcomeStep from './SendWelcomeStep';
import SendCardStep from './SendCardStep';
import SendPayStep from './SendPayStep';
import SendConfirmStep from './SendConfirmStep';
import SendSuccessStep from './SendSuccessStep';
import SendInProgressStep from './SendInProgressStep';
import SendConfigureStep from './SendConfigureStep';

import './SendGiftFlow.scss';
import {AppState} from '../../store';
import {clearFocusEventHandler} from '../../utils/utils';
import {isAuthenticated} from '../../store/selectors';
import {SendGiftConfig} from '../../store/reducers/sendConfig';
import SendErrorStep from './SendErrorStep';
// noinspection TsLint
import {History} from 'history';
import {AnyAction, Dispatch} from 'redux';
import {resetSendConfig} from '../../store/actions/sendConfig';

interface StepConfig extends Step {
    hasNext?: boolean;
    hasPrevious?: boolean;
    isAvailable: (config: SendGiftConfig) => boolean;
}

const stepsConfig: StepConfig[] = [
    {
        path: '',
        label: 'Welcome',
        component: SendWelcomeStep,
        isAvailable: () => true,
    },
    {
        path: 'card',
        label: 'Choose GiftBox',
        component: SendCardStep,
        hasPrevious: true,
        hasNext: true,
        isAvailable: () => true,
    },
    {
        path: 'configure',
        label: 'Customize GiftBox',
        component: SendConfigureStep,
        hasPrevious: true,
        hasNext: true,
        isAvailable: config => !!config.cardId,
    },
    // {
    //     path: 'generate',
    //     label: 'Generate claim key',
    //     component: SendGeneratingCKStep,
    //     hasPrevious: true,
    //     hasNext: true,
    //     isAvailable: config => !!config.cardId && config.value > 0 && !!config.message,
    // },
    // {
    //    path: 'pay',
    //    label: 'Finalize',
    //    component: SendPayStep,
    //    hasPrevious: true,
    //    hasNext: true,
    //    isAvailable: config => !!config.cardId && config.value > 0 && !!config.message,
    // },
    {
        path: 'confirm',
        label: 'Confirmation',
        component: SendConfirmStep,
        hasPrevious: true,
        hasNext: true,
        isAvailable: config => !!config.cardId && config.value > 0 && !!config.message,
    },
    {
        path: 'processing',
        component: SendInProgressStep,
        isAvailable: config => !!config.cardId && config.value > 0 && !!config.message && config.isAgreedToTerms,
    },
    {
        path: 'success',
        component: SendSuccessStep,
        isAvailable: config => !!config.claimKey && !!config.transactionHash,
    },
    {
        path: 'error',
        component: SendErrorStep,
        isAvailable: config => !!config.error,
    }];

class SendGiftFlow extends React.Component<{
    stepIdx: number,
    currentStepPath: string,
    nextStep: string,
    previousStep: string,
    isNextStepAvailable: boolean,
    isIndicatorVisible: boolean,
    history: History,
    config: SendGiftConfig,
    dispatch: Dispatch<AnyAction>,
}> {

    private static renderStepRoute(step: Step, idx: number) {
        return <Route exact={true} key={idx} path={`/send/${step.path || ''}`} component={step.component}/>;
    }

    private static checkRightPath(targetStepIdx: number, currentStepPath: string, history: History) {
        // If we're not on the right path for the step, navigate to it
        if (currentStepPath !== stepsConfig[targetStepIdx].path) {
            logger.warn(`Detected wrong step (/${currentStepPath}). Redirecting to: /${stepsConfig[targetStepIdx].path}`);
            history.replace(`/send/${stepsConfig[targetStepIdx].path}`);
        }
    }

    constructor(props) {
        super(props);
        SendGiftFlow.checkRightPath(props.stepIdx, props.currentStepPath, props.history);
    }

    public componentWillUnmount() {
        // When un-mounting this, we clear all previous configuration
        logger.debug('Leaving SendGift flow. Clearing up SendGift configuration...');
        this.props.dispatch(resetSendConfig());
    }

    public componentWillReceiveProps(nextProps) {
        SendGiftFlow.checkRightPath(nextProps.stepIdx, nextProps.currentStepPath, nextProps.history);
    }

    public render(): React.ReactNode {
        // If the step is not available, we will redirect to the right step, but there will still be
        // an intermediate rendering, so we want to avoid showing an invalid step
        if (this.props.currentStepPath !== stepsConfig[this.props.stepIdx].path) {
            return <section className="container"><span>Error. Please start over!</span></section>;
        }
        const isIndicatorVisible = this.props.isIndicatorVisible;
        return <section id="sendGiftFlow" className="container">
            {(isIndicatorVisible) ? <StepIndicator steps={stepsConfig} currentStepIndex={this.props.stepIdx}/> : null}
            <Switch>
                {stepsConfig.map(SendGiftFlow.renderStepRoute)}
            </Switch>
            <Link to={`/send/${this.props.previousStep}`} hidden={this.props.previousStep === null}>
                <button className="button is-pulled-left is-primary is-outlined" onClick={clearFocusEventHandler}>
                    Go back
                </button>
            </Link>
            <Link to={`/send/${this.props.nextStep}`} hidden={this.props.nextStep === null}>
                <button className="button is-pulled-right is-primary is-outlined" onClick={clearFocusEventHandler}
                        disabled={!this.props.isNextStepAvailable}>
                    Continue
                </button>
            </Link>
        </section>;
    }
}

export default connect(
    (state: AppState) => ({
        isAuthenticated: isAuthenticated(state),
        config: state.sendGiftConfig,
    }), null,
    (stateProps, dispatchProps: DispatchProp, ownProps: RouteComponentProps<{ step: string }>) => {
        let stepIdx: number;
        const currentStepPath = ownProps.match.params.step || '';
        // If the user is authenticated, try to find a match for the current step. Otherwise, default
        // to the first step
        if (stateProps.isAuthenticated) {
            stepIdx = stepsConfig.findIndex(stp => stp.path === currentStepPath);
            stepIdx = stepIdx < 0 ? 0 : stepIdx;
        } else {
            stepIdx = 0;
        }

        // Check whether the step is available and, if not, iterate back to find the last available one
        let step = stepsConfig[stepIdx];
        while (!step.isAvailable(stateProps.config)) {
            stepIdx -= 1;
            step = stepsConfig[stepIdx];
        }

        // logger.debug(stateProps, dispatchProps, ownProps);
        return {
            stepIdx,
            currentStepPath,
            isIndicatorVisible: !!step.label,
            previousStep: step.hasPrevious ? stepsConfig[stepIdx - 1].path : null,
            nextStep: step.hasNext ? stepsConfig[stepIdx + 1].path : null,
            isNextStepAvailable: step.hasNext && stepsConfig[stepIdx + 1].isAvailable(stateProps.config),
            history: ownProps.history,
            config: stateProps.config,
            dispatch: dispatchProps.dispatch,
        };
    },
)(SendGiftFlow);
