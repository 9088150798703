import * as logger from 'loglevel';
import * as React from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ClaimGiftConfig} from '../../store/reducers/claimConfig';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {setClaimField} from '../../store/actions/claimConfig';
import {ethFormatter} from '../../utils/formatting';
import * as contractHelper from '../../crypto/contractHelper';

class ClaimConfigureStep extends React.Component<{ claimGiftConfig: ClaimGiftConfig } & DispatchProp, {
    receiverAddress: string,
    isError: boolean,
}> {
    constructor(props) {
        super(props);
        this.state = {receiverAddress: props.claimGiftConfig.receiverAddress, isError: false};
    }

    public render() {
        const contractGift = this.props.claimGiftConfig.contractGift;
        return (
            <div className="content is-medium">

                <br/><br/><br/>
                <div className="hero-head has-text-centered">
                    <p className="is-huge">
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'star']} transform="left-60 down-5"
                                         size="3x" spin={true}/>
                        <FontAwesomeIcon className="has-text-danger" icon={['fas', 'star']} transform="left-70 up-15"
                                         size="2x" spin={true}/>
                        <FontAwesomeIcon className="has-text-warning" icon={['far', 'star']} transform="left-25 up-5"
                                         size="3x" spin={true}/>
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'star']} transform="up-10"
                                         size="3x" spin={true}/>
                        <FontAwesomeIcon className="has-text-danger" icon={['far', 'star']} transform="right-30 up-10"
                                         size="2x" spin={true}/>
                        <FontAwesomeIcon className="has-text-warning" icon={['fas', 'star']} transform="right-35 up-10"
                                         size="3x" spin={true}/>
                        <FontAwesomeIcon className="has-text-success" icon={['far', 'star']} transform="right-55 down-5"
                                         size="3x" spin={true}/>
                        <FontAwesomeIcon className="has-text-danger" icon={['fas', 'star']} transform="right-70 down-25"
                                         size="2x" spin={true}/>
                        <FontAwesomeIcon className="has-text-warning" icon={['far', 'star']} transform="left-60 down-25"
                                         size="2x" spin={true}/>
                        <FontAwesomeIcon className="has-text-success" icon={['fas', 'star']} transform="left-90 down-15"
                                         size="1x" spin={true}/>
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'star']} transform="left-70 down-50"
                                         size="1x" spin={true}/>
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'star']} transform="right-95 down-25"
                                         size="1x" spin={true}/>
                        <FontAwesomeIcon className="has-text-success" icon={['fas', 'star']} transform="right-70 down-50"
                                         size="1x" spin={true}/>

                        <FontAwesomeIcon className="has-text-primary" icon={['fal', 'rectangle-landscape']}
                                         transform="grow-4 down-20 left-5" size="3x"/>
                        <FontAwesomeIcon className="has-text-primary" icon={['far', 'gift']}
                                         transform="left-3 down-30" size="2x"/>
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                         transform="grow-10 right-28 down-40 rotate-30"/>
                        <FontAwesomeIcon className="has-text-white" icon={['fab', 'ethereum']}
                                         transform="right-29 down-40"/>
                    </span>
                    </p>
                </div>

                <br/><br/><br/><br/>
                <div className="hero">
                    <div className="hero-body has-text-centered">
                        <h1 className="title is-1 is-spaced"><b>Congratulations!</b></h1>
                        <h3 className="subtitle is-3">
                            You've just received a Crypto GiftBox of <b>{ethFormatter.format(contractGift.value)}</b>
                        </h3>
                    </div>
                </div>

                <div className="notification ">
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label is-success-heading">Value:</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="is-size-6">{ethFormatter.format(contractGift.value)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label is-success-heading">From:</label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <p className="is-size-6">{contractGift.sender}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="claim-configure-container">
                    <div className="home_container">
                        <div className="notification is-primary">
                            <h4 className="has-text-white"><b>How to claim the Ether (ETH)</b></h4>
                            <p> Already have an Ethereum wallet? Then, you only need to copy-paste your
                                wallet address into the text box below and claim the Ether (ETH).
                            </p>
                            <p> If you don't have a wallet yet, don’t worry. You can create one immediately by following
                                these <Link to="/help/wallet" target="_blank"><i><b>instructions</b></i></Link>. Then
                                you only need to copy-paste your newly generated wallet address into the text box below.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="hero-body has-text-centered">
                    <h3><strong>Claim the Ether (ETH) now</strong></h3>
                </div>

                <div className="field is-grouped-multiline is-spaced">
                    <label className="label is-size-5">
                        Insert your wallet address below:
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                                Copy-paste the wallet address to which you want to transfer the Ether.
                            </span>
                        </div>
                    </label>
                    <div className="control has-icons-left">
                        <input className="input" type="text" placeholder="Your wallet address"
                               name="receiverAddress" value={this.state.receiverAddress}
                               onChange={this.handleFieldChange}
                               onBlur={this.handleReceiverAddressBlur}/>
                        <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={['fab', 'ethereum']} mask={['fas', 'rectangle-landscape']}
                                             transform="shrink-5" size="lg"/>
                        </span>
                        <span className="has-text-danger" hidden={!this.state.isError}>
                            This doesn't look like a valid Ethereum address
                        </span>
                    </div>
                </div>

                <div className="field is-grouped-multiline is-spaced" hidden={true}>
                    <label className="label is-size-5">
                        Write a "Thank you" note:
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                                If you'd like, you can write a "Thank you" note to the sender of the GiftBox.
                            </span>
                        </div>
                    </label>
                    <textarea className="textarea" placeholder="Thanks for the wonderful gift"
                              name="thankYouNote" value={this.props.claimGiftConfig.thankYouNote}
                              onChange={this.handleFieldChange}/>
                </div>
            </div>
        );
    }

    private handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        logger.debug(`${event.target.name} = ${event.target.value}`);
        this.props.dispatch(setClaimField(event.target.name, event.target.value));
        if (event.target.name === 'receiverAddress') {
            this.setState({receiverAddress: event.target.value, isError: false});
        }
    };

    private handleReceiverAddressBlur = () => {
        this.setState({isError: !contractHelper.isAddress(this.state.receiverAddress)});
    };
}

const mapStateToProps = (state: AppState) => {
    return {
        claimGiftConfig: state.claimGiftConfig,
    };
};

export default connect(mapStateToProps)(ClaimConfigureStep);
