import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';
import * as contractHelper from '../../crypto/contractHelper';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {SendGiftConfig} from '../../store/reducers/sendConfig';
import {setGiftField} from '../../store/actions/sendConfig';

interface SendInProgressStepProps extends RouteComponentProps<{}>, DispatchProp {
    sendGiftConfig: SendGiftConfig
}

class SendInProgressStep extends React.Component<SendInProgressStepProps, {}> {

    public async componentDidMount() {
        try {
            const result = await contractHelper.sendGift(this.props.sendGiftConfig);
            this.props.dispatch(setGiftField('claimKey', result.claimKey));
            this.props.dispatch(setGiftField('transactionHash', result.gift.txInfo.txHash));
            this.props.history.push('/send/success');
        } catch (error) {
            logger.error('Failed to send gift:', error.message);
            this.props.dispatch(setGiftField('error', error));
            this.props.history.push('/send/error');
        }
    }

    public render() {
        return (
            < div className="content is-medium has-text-centered">
                <div className="hero-body">
                    <h2><strong>Have you submitted the transaction?</strong></h2>
                    <p><strong>Then, it's being processed...</strong></p>
                    <p>
                        Please wait. It might take a few minutes. The miners are working hard to process the transaction
                        so that you can give the Crypto GiveBox as soon as possible.
                    </p>
                    <br/>
                    <div className="has-text-primary">
                        <FontAwesomeIcon icon="spinner" size="3x" spin={true}/>
                    </div>
                </div>
                <br/>
                <br/>
                <p>
                    <Link to="/help/metamasksubmit" target="_blank">What is this MetaMask window about?</Link>
                </p>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        sendGiftConfig: state.sendGiftConfig,
    };
};

export default connect(mapStateToProps)(SendInProgressStep);
