import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

const LoadingInProgress: React.SFC = () => (
    <section className="container">
        <div className="hero has-text-centered">
                <div className="hero-head is-primary">
                    <FontAwesomeIcon className="is-huge has-text-primary" icon={['fal','lock-alt']}/>
                    <h1 className="title is-2">You are not signed in</h1>
                </div>

            <div className="hero-body">
                <p className="is-size-5"><strong>Oups... </strong>It seems you are not authenticated. Please sign in to access this section.</p><br/>
                <Link to="/auth">
                    <button className="button is-medium is-primary is-outlined">Sign In</button>
                </Link>
            </div>
        </div>
    </section>
);

export default LoadingInProgress;
