import {withRouter} from 'react-router';
import * as React from 'react';

/**
 * Implementation for a component that will scroll the window up on every navigation.
 * NOTE: We can't use real prop types due to a problem with the typing of withRouter.
 *
 * @see https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
class ScrollToTop extends React.Component<any, {}> {
    public componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    public render() {
        return this.props.children;
    }
}

/**
 * Component that will scroll the window up on every navigation.
 */
export default withRouter(ScrollToTop);
