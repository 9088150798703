import * as logger from 'loglevel';

// Import the core node modules.
import firebase from 'firebase/app';

// Import the other firebase modules for their SIDE-EFFECTS! These imports will augment
// the App module and provide the type-definition for submodules.
import 'firebase/firestore';
import 'firebase/auth';
import config from '../utils/config';

// Initialize the Firebase application.
logger.info('Initializing Firebase for project:', config.FIREBASE_CONFIG.projectId);
export const firebaseApp: firebase.app.App = firebase.initializeApp(config.FIREBASE_CONFIG);

// Initialize firestore
firebaseApp.firestore().settings({timestampsInSnapshots: true});

firebaseApp.auth().onAuthStateChanged((user: firebase.User) => {
    const authHelpers = require('./auth');
    if (user) {
        logger.info(`Authenticated as Firebase user #${user.uid}`);
        authHelpers.checkAuthMismatch(user.uid);
    } else {
        logger.info('De-authenticated on Firebase');
    }
});
