import * as log from 'loglevel';
import Web3 = require('web3');
import {W3} from 'soltsice';
import TxParams = W3.TX.TxParams;
import address = W3.address;

// TODO: Switch to Infura's production APIs
// const ETH_SERVER_ADDRESS = 'http://35.229.25.44:8545';
const ETH_SERVER_ADDRESS = 'https://ropsten.infura.io/AXQV8AnLc0Z6z2ejcNfi';

export function loadWeb3(): Promise<Web3> {
    return new Promise((resolve, reject) => {
        const Web3Js = require('web3');
        // Wait for loading completion to avoid race conditions with web3 injection timing.
        window.addEventListener('load', () => {
            const definedWeb3: Web3 = (window as any).web3;
            // Checking if Web3 has been injected by the browser (Mist/MetaMask)
            if (typeof definedWeb3 !== 'undefined') {
                log.info('Injected web3 detected:', definedWeb3.currentProvider);
                // Use Mist/MetaMask's provider
                resolve(new Web3Js(definedWeb3.currentProvider));
            } else {
                reject(new Error('No web3 instance injected'));
                // // Fallback to own eth server
                // log.debug(`No web3 instance injected, using web3 on: ${ETH_SERVER_ADDRESS}`);
                // const provider = new Web3Js.providers.HttpProvider(ETH_SERVER_ADDRESS);
                // resolve(new Web3Js(provider));
            }
        });
    });
}

export function loadContract<C>(web3: Web3, contractData: any): Promise<C> {
    const TruffleContract = require('truffle-contract');
    const contractDefinition = TruffleContract(contractData);
    contractDefinition.setProvider(web3.currentProvider);
    // noinspection TsLint
    contractDefinition.currentProvider.sendAsync = function () {
        return contractDefinition.currentProvider.send.apply(contractDefinition.currentProvider, arguments);
    };

    return contractDefinition.deployed();
}

/** 50000 gas @ 2 Gwei */
export function txParams(from: address, value?: number, gas?: number, gasPrice?: number): TxParams {
    if (gasPrice && gasPrice > 40000000000) {
        throw new Error('Given gasPrice ' + gasPrice + ' is above 40Gwei (our default is 2 Gwei, common value for ' +
            'fast transactions is 20Gwei), this could be costly. Construct txParams manually if you know what you ' +
            'are doing.');
    }
    if (gas && gas > 500000) {
        throw new Error(`Given gas ${gas} is too large for a normal transaction (>500k). Construct params manually.`);
    }
    return {
        from,
        gas: gas || 500000,
        gasPrice: gasPrice || 2000000000,
        value: value || 0,
    };
}
