import * as logger from 'loglevel';
import {GiftCard} from '../cards';

import giftCardsConfig from '../cards/config';
import {SendGiftConfig} from '../store/reducers/sendConfig';
import {setGiftField} from '../store/actions/sendConfig';
const jsPDF = require('jspdf');

export function downloadCardPdf(pendingGift, canvas, ctx) {
    const doc = getCardDocPdf(pendingGift, canvas, ctx);
    doc.save('gift card.pdf');
    return doc.output('blob');
}

export function getCardDocPdfAsBlob(pendingGift, canvas, ctx) {
    const doc = getCardDocPdf(pendingGift, canvas, ctx);
    return doc.output('blob');
}

export function getCardDocPdf(pendingGift, canvas, ctx) {
    const card: GiftCard = giftCardsConfig[pendingGift.cardId];
    // logger.debug('Loaded gift card:', card);
    // logger.debug('Pending settings ' + JSON.stringify(pendingGift));
    if (card === undefined) {
        return;
    }
    const dataURL = drawCardOnCanvas(pendingGift, canvas, ctx) ;
    // true last param means compress pdf

    const doc = new jsPDF('l', 'pt', 'letter', true);
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    doc.addImage(dataURL, 'JPEG', 0, 0, width, height, undefined, 'FAST');

    // return pdf as string
    return doc;
}

export function drawCardOnCanvas(pendingGift: SendGiftConfig, canvas, ctx) {
    let cardConfigs: { [key: string]: GiftCard };
    cardConfigs = giftCardsConfig;

    const card: GiftCard = cardConfigs[pendingGift.cardId];
    //logger.debug('Loaded gift card:', card);
    if (card === undefined) {
        return;
    }

    const img = new Image();
    img.src = card.image.original;
    img.onload = () => {
        logger.info('Image finished:', img);
        // TODO: Only render (or re-render via component's state) at this point
    };

    // draw card background image
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    // draw multiple text
    if (card != null && card.text != null) {
        for (const text of card.text) {
            ctx.fillStyle = text.color;

            if (text.textstyle == null) {
                text.textstyle = 'normal';
            }

            ctx.font = `${text.textstyle} ${text.size}px ${text.font}`;

            let textValue = '';
            textValue = pendingGift[text.id] != null ? pendingGift[text.id] : (text.value || '');

            if (text.id === 'value') {
                textValue = ` ${textValue} Ether`;
            }
            wrapText(ctx, textValue, text.placement.x, text.placement.y, text.maxWidth, text.lineHeight);
        }
    }

    return canvas.toDataURL();
}

function wrapText(context, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';

    let height = y;
    for (let n = 0; n < words.length; n = n + 1) {
        const testLine = `${line + words[n]} `;
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, height);
            line = `${words[n]} `;
            height += lineHeight;
        } else {
            line = testLine;
        }
    }
    context.fillText(line, x, height);
}
