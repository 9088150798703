// Setup Logging
import * as log from 'loglevel';

log.setDefaultLevel('debug');
log.getLogger('App').info(`Initializing app in ${process.env.NODE_ENV} mode!`);

import * as React from 'react';
import {render} from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import {initializeFontAwesome} from './utils/FontAwesomeLoader';
import {Provider} from 'react-redux';
import App from './scenes/App';
import {store} from './store';
import {initializeCrypto} from './crypto';
import {Analytics} from './utils/Analytics';

// Setup FontAwesome
initializeFontAwesome();

// Setup the Crypto framework
initializeCrypto(store);

// Setup Google Analytics
Analytics.initialize('UA-126280281-1', {debug: true});
Analytics.trackEvent('app', 'initialized');

// Render app
const rootEl = document.getElementById('root');
render(
    <Provider store={store}>
        <AppContainer>
            <App/>
        </AppContainer>
    </Provider>,
    rootEl,
);

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
    module.hot.accept('./scenes/App', () => {
        const NewApp = require('./scenes/App').default;

        render(
            <Provider store={store}>
                <AppContainer>
                    <NewApp/>
                </AppContainer>
            </Provider>,
            rootEl,
        );
    });
}
