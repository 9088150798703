const FEE_PERCENTAGE = 0.015;
const MINIMUM_FEE = 0.001;

export function computeFee(giftValue) {
    const fee = giftValue * FEE_PERCENTAGE;
    return (fee < MINIMUM_FEE) ? MINIMUM_FEE : fee;
}

export function computeTotalCost(giftValue) {
    return giftValue + computeFee(giftValue);
}
