import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TermsOfUse: React.SFC = () => (
    <section id="TermsOfUse" className="container">

        <div className="content is-medium">

            <div className="hero-body">
                <h2 className="has-text-centered"><strong>Terms of Use</strong></h2>
            </div>
            <p><strong>Last Updated: 20 December 2017</strong></p>

            <p>These Terms of Use (the “Terms”) set forth the terms and conditions that govern access to, and use
                of, the website www.cryptogiftbox.com (the “Site”). The Site enables a user to generate a gift card
                allowing him/her to give a certain amount of Ether, as a gift, to another person (“crypto gift
                card”). The Site uses specially-developed smart contracts (each, a “Smart Contract”, or
                collectively, “Smart Contracts”) that run on the Ethereum network to enable users to transfer the
                Ether to the Ethereum wallet addresses of the persons whom shall receive the crypto gift cards.
            </p>
            <p>These Terms are a legal agreement between you, as a sender or a receiver of the crypto gift card,
                (“you”, or “your”) and CryptoGiftBox (“CryptoGiftBox”, “we”, “us”). Before you use the Site and the
                Smart Contracts you will need to agree to these Terms. The use of the Site or of the Smart Contracts
                represent your confirmation that you understand and agree to all of these Terms.
            </p>
            <p>CryptoGiftBox reserves the right to change the Terms at any time and in our sole discretion. If
            CryptoGiftBox makes changes to these Terms, we will make the updated Terms available on the Site and
            update the “Last Updated” date found at the top of these Terms. Please verify these Terms periodically
            for changes. Any changes to the Terms will apply on the date that they are made, and your continued
            access to or use of the Site after the Terms have been updated will constitute your binding acceptance
            of the updates.
            </p>
            <p><strong>1. Privacy Policy</strong></p>
            <p>Please refer to our Privacy Policy for information on how we collect, use, store and disclose your
            personal information. The Privacy Policy is incorporated by this reference into these Terms and by
            accepting these Terms, you agree to the collection, use, storage, and disclosure of your data in
            accordance with our Privacy Policy.
            </p>
            <p><strong>2. The Site</strong></p>
            <p>The Site uses specially-developed Smart Contracts that run on the Ethereum network to enable the
            transaction of the Ether from the wallet of the user who creates and sends the crypto gift card (the
            “Sender”) to the wallet of the recipient of the crypto gift card (the “Receiver”).
            </p>
            <p>When the Sender created and sends a crypto gift card, the amount of Ether indicated by the Sender
            (except the transaction fees, pursuant to Section 4 below) will be deposited in a Smart Contract (the
            “deposit”).
            </p>
            <p>In addition to the Smart Contracts, the Site uses complex cryptographic algorithms to secure the
            transaction between the Sender and the Receiver. Each time a Sender generates a crypto gift card, the
            Site will randomly generate a unique secret key (the “claim key”) which will be indicated on the crypto
            gift card and which can be used by the Receiver to sign the withdrawal of the deposit from the Smart
            Contract.
            </p>
            <p>Based on the claim key, the Site, using cryptographic algorithms, will automatically generate a derived
            key (the “derived key”), which shall be assigned to the deposit in the Smart Contracts.
            When the Receiver receives the crypto gift card and intends to claim the gift, the Receiver will be able
            to access the deposit using the claim key and request the transfer of the deposit to a specific address
            indicated by the Receiver.
            </p>
            <p>Distinct of the possibility of the Receiver to claim the deposit by using the claim key, the Sender,
            solely, has the possibility to revoke the gift, withdrawing the deposit from the Smart Contract. The
            Sender can withdraw the deposit, only as long as he/she uses the same Ethereum wallet address from which
            he/she transferred the Ether when he/she purchased the crypto gift card. In case the Sender uses other
            Ethereum wallet address, the deposit cannot be withdrawn by the Sender. We are not responsible in case
            the Sender changes his/her Ethereum wallet address and he/she cannot further withdraw the deposit.
            Furthermore, in case the Sender chooses to revoke the gift and withdraw the deposit, we are not
            responsible for any prejudice the Receiver might incur.
            </p>
            <p>Once the deposit and the derived key are recorded in the Smart Contract, the Ether cannot be withdrawn
            other that signing the claim with the claim key, or by revoking the gift by the Sender.
            The claim key will be indicated only on the crypto gift card and no one else have access to it. We do
            not save the claim key and we cannot access them. Therefore, you understand and agree that we cannot
            replicate the claim key and we are not responsible in case the claim key is lost. You understand and
            agree that the deposit can be withdrawn by anybody using the claim key and that you are responsible for
            the security of the claim key.
            </p>
            <p><strong>3. Use of Site</strong></p>
            <p>The Site can most easily be accessed and used through Google Chrome web browser. Therefore, first you
            should have installed Chrome. Once you use Chrome, you will further need to install a Chrome browser
            extension, MetaMask, which enables you to engage in transactions on the Ethereum network. There is no
            other possibility to transfer Ether to the Smart Contract or to claim the Ether from the Smart Contract
            than using MetaMask. You will only be able to interact with the Site and Smart Contracts, if your
            Ethereum wallet is connected and unlocked through MetaMask account.
            </p>
            <p>Transactions taking place on the Site are managed and confirmed through the Ethereum blockchain. You
            understand and agree that your Ethereum public address is publicly visible whenever you engage in a
            transaction on the Site.
            </p>
            <p>You understand and agree that we do not own or control Google Chrome, MetaMask, the Ethereum network,
            the email provider, internet provider, server provider, or any other third party site, product, or
            service that you might access, visit, or use for the purpose of enabling you to use the features of the
            Site and the Smart Contracts. With that in mind, you acknowledge and agree that we will not be liable
            for the acts or omissions of any such third parties, nor will we be liable for any damage that you may
            suffer as a result of interacting with any such third parties.
            </p>
            <p>You acknowledge and agree that you are responsible for the security of your MetaMask wallet (and other
            Ethereum wallets).
            </p>
            <p><strong>4. Pricing</strong></p>
            <p>If you choose to purchase a crypto gift card on the Site, the transaction you engage in will be
            conducted through the Ethereum network through MetaMask. We have no control over these payments or
            transactions and we do not have the ability to reverse any transactions. You understand and agree that
            we have no liability to you or to any third party for any claims or damages that may arise as a result
            of any transactions that you engage in via the Site, or independently using the Smart Contracts.
            </p>
            <p>Ethereum network requires the payment of a transaction fee ( “Gas Fee”) for every transaction that
            occurs on the Ethereum network. The Gas Fee is the internal pricing for running a transaction or
            contract in Ethereum network and needs to be paid for each transaction that occurs via the Ethereum
            network independently of our Site.
            </p>
            <p>Additionally, each time you, as a Sender, purchase a crypto gift, you authorize us to collect a
            commission of 3.5% of the total value of the gift (a “Commission”). You understand and agree that the
            Commission will be transferred directly to us by using smart contracts through the Ethereum network.
            With that in mind, you understand and agree that the Gas Fee and the Commission will be charged from the
            value of Ether indicated on the crypto gift card.
            </p>
            <p><strong>5. Intellectual Property Rights</strong></p>
            <p>Unless otherwise indicated by us, all elements of the Site, all content and other materials therein are
            owned by us (or, as applicable, our licensors) and are protected by intellectual property rights. For
            the avoidance of doubt, the visual interfaces, design, text, graphics, pictures, systems, information,
            data, methods, software, computer code, organization, services, all other elements and any other
            documentation or other ancillary material provided to you (the “Content”) are owned by us or by our
            licensors and are protected by copyright, patents, trademarks, design, trade secrets, any other
            intellectual property rights and applicable law.
            </p>
            <p>We hereby grant you a limited, nonexclusive, non-sublicensable license to access and use the Site, the
            Smart Contracts, and the Content solely for the purpose of purchasing a crypto gift card. However, such
            license is subject to these Terms and you are not permitted to:
            </p>
            <ul>
                <li>use the Site, Smart Contracts, or the Content other than for their intended purposes.</li>
                <li>use any data mining, robots or similar data gathering or extraction methods;</li>
            <li>sell, rent, lease, lend, redistribute, sublicense or make commercial use of the Site, Smart Contracts,
                or the Content;</li>
            <li>copy, reverse engineer, decompile, disassemble or attempt to discover the source code of our Site,
                Smart Contracts, or Content;</li>
            <li>modify, alter or otherwise make any derivative uses of the Site, Smart Contracts, or the Content, or
                any portion thereof, except as expressly permitted under these Terms;</li>
            <li>remove, alter or obscure any copyright, trademark or other proprietary rights notice included in the
                Site, Smart Contracts, or Content;</li>
            <li>download (other than the page caching) any part of the Site, Smart Contracts, or the Content, except
                as expressly permitted under these Terms;</li>
            </ul>
            <p>Any use of the Site, Smart Contracts, or the Content other than as specifically authorized herein,
            without the prior written permission of CryptoGiftBox, is strictly prohibited and will terminate the
            license granted in this herein. Such unauthorized use may also violate applicable laws, including
            without limitation, copyright and trademark laws and applicable regulations and statutes. Unless
            explicitly stated by CryptoGiftBox, nothing in these Terms shall be construed as conferring any license
            to intellectual property rights, whether by estoppel, implication or otherwise.
            </p>
            <p><strong>6. Feedback</strong></p>
            <p>You can submit questions, comments, bug reports, suggestions, ideas, plans, notes or other feedback
            about the Site (“Feedback”). By submitting any Feedback, you grant us a perpetual, irrevocable,
            nonexclusive, worldwide license under all rights necessary for us to incorporate and use your Feedback
            for any purpose, commercial or otherwise, without acknowledgment or compensation to you. For the
            avoidance of doubt, we are free to use such Feedback at our discretion and without additional
            compensation to you.
            </p>
            <p><strong>7. User Content</strong></p>
            <p>You are solely responsible for all content you create, transmit, distribute through the Site (“User
            Content”). With that in mind, you agree not to create, transmit, distribute through the Site User
            Content that:
            </p>
            <ul>
            <li>is illegal or unlawful, that would constitute, encourage or provide instructions for a criminal
            offense, violate the rights of any party, or otherwise create liability or violate any local, state,
                national or international law;</li>
            <li>is defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd, vulgar, suggestive,
            violent, harassing, hateful, threatening, offensive, discriminatory, bigoted, abusive, inflammatory,
                invasive of privacy or publicity rights, fraudulent, deceptive or otherwise objectionable;</li>
                <li>impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;</li>
            <li>may infringe or violate any patent, trademark, trade secret, copyright, or other intellectual or other
                right of any party;</li>
            <li>contains or depicts any statements, remarks or claims that do not reflect your honest views and
                experiences;</li>
                <li>is designed to deceive or trick the users of the Site;</li>
            </ul>
            <p><strong>8. User conduct</strong></p>
            <p>You are solely responsible for your own conduct while accessing or using the Site and Smart Contracts.
            You agree to use the Site and Smart Contracts only for purposes that are legal, proper and in accordance
            with these Terms and any applicable laws or regulations. You agree that you will not and will not permit
                any third party to do, including but not limited to, any of the following:
            </p>
            <ul>
            <li>use the Site and Smart Contracts for any illegal or unauthorized purpose or engage in, encourage, or
            promote any illegal activity, or any activity that violates these Terms or any other rules or polices
                established from time to time by us;</li>
            <li>use the Site and Smart Contracts to violate the legal rights (such as rights of privacy and publicity)
                of others;</li>
                <li>modify, adapt, hack, emulate translate, or reverse engineer the Site and Smart Contracts;</li>
            <li>use any robot, spider, crawler, scraper or other automated means or interface not provided by us to
                access the Site and Smart Contracts or to extract data;</li>
            <li>attempt to indicate in any manner that you have a relationship with us or that we have endorsed you or
                any products or services for any purpose;</li>
            <li>send, upload, distribute or disseminate any content that could be in any way interpreted as
                defamatory, unlawful, abusive, fraudulent, obscene, harassing or otherwise objectionable;</li>
            <li>distribute any other items of a destructive or deceptive nature such as, including but not limited to,
                worms, viruses, Trojan horses, corrupted files, defects, hoaxes;</li>
                <li>impersonate another person by any mean (e.g., by use of an email address or otherwise);</li>
            <li>transmit or otherwise make available through the Site any content that infringes the intellectual
                proprietary rights of any party;</li>
            <li>remove any copyright, trademark or other proprietary rights notices contained in or on the Site, Smart
                Contracts, or any part of it;</li>
                <li>exploit the Site and Smart Contracts for any unauthorized commercial purpose;</li>
            <li>access or use the Site and Smart Contracts for the purpose of creating a product or service
                competitive with any of our products or services.</li>
            </ul>

            <p><strong>9. Disclaimer</strong></p>
            <p>YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SITE, SMART CONTRACTS AND CONTENT IS AT YOUR
            SOLE RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, SAFETY, ACCURACY AND EFFORT
            IS WITH YOU. THE SITE, THE SMART CONTRACTS AND THE CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
            BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM ANY AND ALL WARRANTIES AND
            REPRESENTATIONS (EXPRESS OR IMPLIED, WRITTEN OR ORAL) IN RELATION TO, WITHOUT LIMITATION, THE SITE,
            SMART CONTRACTS, CONTENT OR EXTERNAL WEBSITES OR APPLICATIONS, INCLUDING BUT NOT LIMITED TO ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY, IMPLIED WARRANTIES OF FITNESS OR SUITABILITY FOR ANY PURPOSE AND
            WARRANTIES OF NON-INFRINGEMENT, CONDITION OF TITLE, ACCURACY, RELIABILITY. WE DO NOT WARRANT AND
            REPRESENT THAT THE SITE, SMART CONTRACTS AND CONTENT WILL MEET ACCURACY AND YOUR REQUIREMENTS, THAT THE
            USE OF THE SITE, SMART CONTRACTS AND CONTENT WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE, OR THAT THE
            SITE, SMART CONTRACTS OR ANY CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>
            <p><strong>10. Indemnification</strong></p>
            <p>You agree, at your sole expense, to defend, indemnify and hold us, our officers, agents, employees,
            advertisers, licensors, suppliers or partners harmless from and against any claims, damages, payments,
            deficiencies, fines, judgments, settlements, liabilities, losses, costs and expenses (actual and
            consequential) of any kind or nature, suit, judgment, litigation cost, and attorneys’ fees arising out
            of or in any way related to (i) your use of the Site, Smart Contracts or Content; (ii) your violation of
            these Terms or the rights of any third-party; or (iii) your breach of applicable laws in connection with
            your access to or use of the Site, Smart Contracts and Content.
            </p>
            <p><strong>11. Limitation of Liability</strong></p>
            <p>You acknowledge and agree that to the maximum extent permitted under applicable law, in no event will
            CryptoGifyBox be liable to you or to any other third party for any incidental, indirect, special,
            consequential, exemplary or punitive damages whatsoever including, but not limited to, damages for loss
            of profits, (whether incurred directly or indirectly), loss of goodwill or business reputation, loss of
            data, cost of procurement of substitute goods or services, or any other intangible loss, arising out of
            or related to the Site, Smart Contracts and Content, regardless of the theory of liability (contract,
            warranty, tort, strict liability, product liability or other theory) and even if we have been advised of
            the possibility of such damages.
            </p>
            <p>You understand and agree that we will not be liable for any failure or delayed performance of our
            obligations that result from any condition beyond our reasonable control, including but not limited to,
            acts or omissions of third parties, earthquake, fire, flood, governmental action, acts of terrorism,
            acts of God, labour conditions, power failures, Internet disturbances, server failures.
            You acknowledge and agree that we have made the Site, the Smart Contracts and the Content available to
            you and entered into these Terms upon the warranty disclaimers and limitations of liability set forth
            herein.
            </p>
            <p><strong>12. External Sites</strong></p>
            <p>The Site may contain hyperlinks to third party website or resources. These links to third party pages
            are provided for convenience only. In any event, especially because of the volatile nature of
            information on the Internet, CryptoGiftBox cannot control the nature or content of these external
            sources and therefore is not responsible and or liable for the use, the unavailability of third party
            sites nor their content and advertising or other materials available on such third party websites that
            you might access via our Site.
            </p>
            <p><strong>13. Termination or changes of the Site</strong></p>
            <p>We reserve the right to change, suspend, remove, discontinue or disable access to the Site, Smart
            Contracts or Content at any time and without notice. In no event will CryptoGiftBox be liable for the
            removal of or disabling of access to any portion or feature of the Site, Smart Contracts or Content.
            </p>
            <p><strong>14. Miscellaneous</strong></p>
            <p>CryptoGiftBox may assign these Terms and its rights or delegate its obligations under without your
            consent. All provisions contained in these Terms shall extend to and be binding upon you and
            CryptoGiftBox’s successors and assigns. You may not assign these Terms to another person or entity.
            </p>
            <p>If any provision of these Terms shall be deemed unlawful, void or for any reason unenforceable, then
            that provision shall be deemed severable from these Terms and shall not affect the validity and
            enforceability of any remaining provisions.
            </p>
            <p>Enforcement of the Terms is solely in our discretion and our failure to enforce a provision in some
            instances does not constitute a waiver of our right to enforce such provision in other instances.
            </p>
            <p>The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against
            any party.
            </p>
            <p>These Terms will be governed by and construed in accordance with the laws of Romania. Any legal action
            or proceeding arising under these Terms will be brought exclusively in the courts located in Romania,
            and the parties irrevocably consent to the personal jurisdiction and venue there.
            </p>
            <p>You agree that we may provide you with notices by email, or displaying on the Site. By providing us your
                email address, you agree to our using the email address to send you any notices required by law.</p>


        </div>

    </section>
);

export default TermsOfUse;
