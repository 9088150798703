import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

const ErrorMetamaskNotFound: React.SFC = () => (
    <section className="container">
    <div className="hero error has-text-centered">
        <div className="hero-head">
            <FontAwesomeIcon className="is-huge has-text-primary" icon={['fal', 'search']}/>
            <h1 className="title is-2">MetaMask not found</h1>
        </div>

        <div className="hero-body">
            <p className="is-size-5">
                <strong>Oops...</strong> It seems we cannot find the MetaMask extension. You need
                this extension in order to handle the Ethereum payments, so please make sure it's installed and enabled.
            </p>
            <br/>
            <p className="is-size-5">Check out the questions bellow or see our <Link to="/help">Help</Link> for more information.</p>
        </div>
    </div>

        <div className="content is-medium">
            <div className="hero-body has-text-centered">
                <h2>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'map-marker-question']} transform="down-1"
                                         size="1x"/>
                    </span>
                    <strong>&nbsp;What's this?</strong></h2>
            </div>

            <div className="question">
                <label>
                    <input id="q26" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q26" className="question_text"> What do I need to send a Crypto GiftBox?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">All you need is: </p>
                        <ul className="reveal-if-active_faq_list">
                            <li>A computer or laptop having installed the Chrome or Firefox browser;</li>
                            <li>MetaMask, a digital wallet used specifically with Chrome or Firefox browser -
                                unfortunately, you
                                cannot create a Crypto GiftBox and send ether (ETH) without installing MetaMask.
                            </li>
                        </ul>
                    </div>
                </label>
            </div>


            <div className="question">
                <label>
                    <input id="q9" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q9" className="question_text"> What is MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">MetaMask is a digital wallet allowing you to
                            interact with the Ethereum network and safely transfer the ether (ETH) to the receiver of
                            the
                            Crypto GiftBox.
                        </p>
                        <p className="reveal-if-active_faq_text">For more information about MetaMask, please visit their
                            official website at <a href="https://metamask.io/" target="_blank"><i> metamask.io</i></a>.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q10" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q10" className="question_text"> How to install MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">MetaMask is a Chrome or Firefox extension and you
                            simply
                            have to add it to your browser. Once added to your Chrome or Firefox browser, you will only
                            need to set
                            up
                            the wallet and you’re done. If you need some additional help, don’t worry - we've prepared
                            this
                            <Link to="/help/metamask" target="_blank"><i><strong> guide</strong></i></Link> for you.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q13" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q13" className="question_text"> How can I get ether (ETH)?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">If you are a U.S. citizen, you can buy ether (ETH)
                            directly from your MetaMask wallet.</p>
                        <figure className="image">
                            <img src="src/assets/img/metamask/Slide08.jpeg"/>
                        </figure>

                        <p className="reveal-if-active_faq_text is-size-5">Otherwise, you can purchase ether (ETH) from
                            several exchanges platforms and then transfer
                            it
                            to your MetaMask wallet.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q14" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q14" className="question_text"> How do I send ether (ETH) to MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">If you purchased ether (ETH) from an exchange
                            platform,
                            or if you already have an Ethereum wallet, you need to send the ether (ETH) to the
                            address
                            of
                            your MetaMask wallet.</p>
                        <p className="reveal-if-active_faq_text">That’s very simple: copy your MetaMask wallet
                            address by clicking on “...” and choose “Copy address to clipboard”. Then, go to your
                            exchange
                            platform account or to you wallet, select “Withdrawn” or “Send” and paste the address from
                            your
                            MetaMask wallet.
                        </p>
                        <figure className="image">
                            <img src="src/assets/img/metamask/Slide09.jpeg"/>
                        </figure>

                    </div>
                </label>
            </div>
        </div>

    </section>
);

export default ErrorMetamaskNotFound;
