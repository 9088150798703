import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home: React.SFC<RouteComponentProps<{}>> = () => (

    <div className="">

        <div className="hero is-medium has-text-centered">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title is-spaced is-1"><strong>Easy. Safe. Fast.</strong></h1>
                    <h3 className="subtitle is-2">Send the gift of the future with Crypto GiftBox</h3>
                    <NavLink to="/send">
                        <button className="button is-primary is-large">Get Started</button>
                    </NavLink>
                </div>
            </div>
        </div>

        <div className="hero-body">
            <div className="home-card-container">
                <div className="columns is-multiline">
                    <div className="column">
                        <div className="card is-home">
                            <div className="card-content has-text-centered">

                                <p className="icon is-large">
                                    <span className="fa-layers fa-fw">
                                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                             transform="left-6 rotate-30" size="5x"/>
                                        <FontAwesomeIcon className="has-text-white" icon={['far', 'paint-brush']}
                                             transform="left-3" size="3x"/>
                                    </span>
                                </p>

                                <p className="heading has-text-centered is-home-title">
                                    <br/><br/>Create a Crypto GiftBox
                                </p>
                                <p className="heading has-text-centered is-home-text">
                                    Create and customize a Crypto GiftBox in just a few minutes. Start <NavLink
                                    to="/send">here</NavLink>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card is-home">
                            <div className="card-content has-text-centered">

                                <p className="icon is-large">
                                    <span className="fa-layers fa-fw">
                                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                                         transform="left-6 rotate-30" size="5x"/>
                                        <FontAwesomeIcon className="has-text-white" icon={['far', 'gift']}
                                                         transform="left-3" size="3x"/>
                                    </span>
                                </p>

                                <p className="heading has-text-centered is-home-title">
                                    <br/><br/>Give it as a gift
                                </p>
                                <p className="heading has-text-centered is-home-text">
                                    Download and print the Crypto GiftBox or send it directly via email.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <div className="card is-home">
                            <div className="card-content has-text-centered">

                                <p className="icon is-large">
                                    <span className="fa-layers fa-fw">
                                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                                         transform="left-6 rotate-30" size="5x"/>
                                        <FontAwesomeIcon className="has-text-white" icon={['far', 'envelope-open']}
                                                         transform="up-1 left-3" size="3x"/>
                                    </span>
                                </p>
                                <p className="heading has-text-centered is-home-title">
                                    <br/><br/>Claim the Crypto GiftBox
                                </p>
                                <p className="heading has-text-centered is-home-text">
                                    Use the secret claim key on the Crypto GiftBox to claim the Ether.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="hero-body is-home">
            <div className="container">
                <div className="home_container">
                    <div className="columns">
                        <div className="column">
                            <h2 className="title is-spaced">
                                <b>What is a Crypto GiftBox?</b>
                            </h2>
                            <br/>
                            <p className="is-size-5">Crypto GiftBox is a customizable ether gift card allowing you to
                                easily
                                and safely send Ether (ETH), as a gift, to anyone you'd like - friends, family,
                                colleagues
                                or employees.
                            </p>
                        </div>
                        <div className="column is-one-third">
                         <span className="fa-layers fa-fw">
                             <FontAwesomeIcon className="has-text-light-blue" icon={['fas', 'rectangle-landscape']}
                                              transform="down-9.4 right-4.4" size="10x"/>
                            <FontAwesomeIcon className="has-text-orange" icon={['fas', 'rectangle-landscape']}
                                             transform="down-9 right-4" size="10x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['far', 'gift']}
                                             transform="down-18 right-16" size="5x"/>
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="hero-body is-home">
            <div className="container">
                <div className="home_container">
                    <div className="columns">
                        <div className="column is-one-third">
                         <span className="fa-layers fa-fw">
                             <FontAwesomeIcon className="has-text-light-blue" icon={['fas', 'hexagon']}
                             transform="down-9.75 right-2.4 rotate-30" size="10x"/>
                             <FontAwesomeIcon className="has-text-orange" icon={['fas', 'hexagon']}
                                              transform="down-9.5 right-2 rotate-30" size="10x"/>
                             <FontAwesomeIcon className="has-text-white" icon={['fab', 'ethereum']}
                                              transform="down-16 right-13.2" size="6x"/>

                        </span>
                        </div>
                        <div className="column">
                            <h2 className="title is-spaced">
                                <b>What's so special about Crypto GiftBox?</b>
                            </h2>
                            <br/>
                            <p className="is-size-5">Unlike the traditional gift cards, Crypto GiftBox
                                is
                                based on the Ethereum blockchain technology, using smart contracts to ensure the safety
                                transfer of ether.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="hero-body is-home-spaced-warning is-warning">
            <div className="container">
                <div className="home_container">
                    <h2 className="title is-spaced has-text-centered has-text-white">
                        <b>How does it work?</b>
                    </h2>
                    <br/>
                    <p className="is-size-5 has-text-centered has-text-white">
                        Each time you create a Crypto GiftBox, the Ether (ETH) is deposited into a smart contract and
                        safely
                        locked there using a unique secret claim key, randomly generated for your Crypto GiftBox.
                        Once deposited, only the receivers of the Crypto Giftbox can redeem the Ether (ETH) by using
                        the secret claim key indicated on their Crypto GiftBox.
                    </p>

                </div>
            </div>
        </div>

        <div className="hero-body is-home-spaced is-primary">
            <div className="container is-fullwidth">
                <div className="home_container">
                    <p className="has-text-centered">
                        <FontAwesomeIcon className="has-text-white" icon={['far', 'stopwatch']}
                                         transform="up-7" size="4x"/>
                    </p>
                    <h2 className="title is-spaced has-text-centered has-text-white">
                        <b>Create your Crypto GiftBox in minutes</b>
                    </h2>
                    <p className="is-size-5 has-text-centered"> All you need to do is to follow a few simple steps.
                        It's very easy and you'll get plenty of help from us along the way.
                    </p>

                    <br/><br/>
                    <p className="has-text-centered">
                        <NavLink to="/send">
                            <button className="button is-large is-primary is-inverted">Get Started</button>
                        </NavLink>
                    </p>
                </div>
            </div>
        </div>

    </div>

);

export default Home;
