import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {setClaimGiftKey} from '../../store/actions/claimConfig';
import classNames from 'classnames';
import {RouteComponentProps} from 'react-router';
import {ContractGift} from '../../crypto';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';

class ClaimWelcomeStep extends React.Component<{ dispatch: ThunkDispatch<{}, {}, AnyAction> } & RouteComponentProps<any>, {
    claimKey: string,
    error?: Error,
    inProgress: boolean,
}> {
    constructor(props) {
        super(props);
        this.state = {claimKey: '', inProgress: false};
    }

    public render() {
        const btnClass = classNames('button is-primary is-large is-outlined', {'is-loading': this.state.inProgress});
        return (
            <div className="content has-text-centered is-medium is-verify-spaced is-marginless">
                <div className="hero-body">
                    <h1><strong>Verify your Crypto GiftBox</strong></h1>
                    <p>
                        Start the claiming process by verifying your secret claim key.
                    </p>
                </div>
                <br/>



                <div className="field is-horizontal is-spaced">
                    <div className="field-body">
                        <div className="field">
                            <p className="control is-expanded has-icons-left">
                                <input
                                    className={classNames('input', 'is-medium', {'is-danger': !!this.state.error})}
                                    type="text" placeholder="Your claim key"
                                    name="claimKey" value={this.state.claimKey}
                                    onChange={this.handleFieldChange}/>
                                <span className="icon is-medium is-left">
                                        <FontAwesomeIcon icon="key" size="1x"/>
                                    </span>
                                <span className="has-text-danger"
                                      hidden={this.state.error === null || this.state.error === undefined}>
                                        It seems your claim key is invalid. Have you inserted all the characters?
                                    </span>
                            </p>
                        </div>
                    </div>

                    <label className="field-label is-medium is-verify">
                        <div className="tooltip">
                            <FontAwesomeIcon icon="question-circle"/>
                            <span className="tooltiptext">
                                Copy-paste the secret claim key indicated on your Crypto GiftBox. Please, be very
                                careful to insert all the characters.
                            </span>
                        </div>
                    </label>
                </div>
                <br/>
                <button className={btnClass} onClick={this.handleVerifyClick}>
                    Verify
                </button>
            </div>
        );
    }

    private handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        logger.debug(`${event.target.name} = ${event.target.value}`);
        this.setState({claimKey: event.target.value, error: undefined});
    };

    private handleVerifyClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        // Clear focus & show the button as loading
        (event.target as any).blur();
        this.setState({inProgress: true});

        // Dispatch the async set claim action and listen to its result in order to navigate to the right next step
        try {
            const gift: ContractGift = await this.props.dispatch(setClaimGiftKey(this.state.claimKey)) as ContractGift;
            this.props.history.push(gift.isCompleted ? '/claim/error_claimed' : '/claim/configure');
        } catch (error) {
            logger.warn('Unable to validate claim key:', error.message);
            this.setState({error, inProgress: false});
        }
    };
}

export default connect(null)(ClaimWelcomeStep);
