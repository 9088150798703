import * as logger from 'loglevel';
import {W3} from 'soltsice';
import address = W3.address;
import {Action} from 'redux';
import {EthGiftBox} from '../../crypto/types';
import Web3 = require('web3');

export const SET_ACCOUNT_ACTION = 'SET_ACCOUNT';

export interface SetAccountAction extends Action {
    account: address | null;
}

export function setAccount(account: address | null): SetAccountAction {
    return {
        account,
        type: SET_ACCOUNT_ACTION,
    };
}

export const SET_CONTRACT_ACTION = 'SET_CONTRACT';

export interface SetContractAction extends Action {
    contract: EthGiftBox | null;
}

export function setContract(contract: EthGiftBox | null): SetContractAction {
    return {
        contract,
        type: SET_CONTRACT_ACTION,
    };
}

export const SET_WEB3_ACTION = 'SET_WEB3';

export interface SetWeb3Action extends Action {
    web3: Web3 | null;
}

export function setWeb3(web3: Web3 | null): SetWeb3Action {
    return {
        web3,
        type: SET_WEB3_ACTION,
    };
}

export const SET_SENT_GIFTS_ACTION = 'SET_SENT_GIFTS';

export interface SetSentGiftsAction extends Action {
    sentGiftIds: number[];
}

export function setSentGifts(sentGiftIds: number[]): SetSentGiftsAction {
    return {
        sentGiftIds,
        type: SET_SENT_GIFTS_ACTION,
    };
}

// region Async versions
export function setAccountAsync(accountPromise: Promise<address>) {
    return (dispatch) => {
        accountPromise
            .then((account) => {
                dispatch(setAccount(account));
            })
            .catch((e) => {
                logger.error('Unable to load account:', e);
                dispatch(setAccount(null));
            });
    };
}

export function setContractAsync(contractPromise: Promise<EthGiftBox>) {
    return (dispatch) => {
        contractPromise
            .then((contract) => {
                dispatch(setContract(contract));
            })
            .catch((e) => {
                logger.error('Unable to load contract:', e);
                dispatch(setContract(null));
            });
    };
}

// endregion Async versions
