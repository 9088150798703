import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

const ErrorMetamaskLocked: React.SFC = () => (
    <section className="container">
        <div className="hero error has-text-centered">
            <div className="hero-head ">
                <FontAwesomeIcon className="is-huge has-text-primary" icon={['fal', 'lock-alt']}/>
                <h1 className="title is-2">MetaMask is locked</h1>
            </div>

            <div className="hero-body">
                <p className="is-size-5">
                    <strong>Oops...</strong> It seems that MetaMask is currently locked. Please open the MetaMask
                    extension by
                    clicking on its icon (the cute fox on your browser bar) and insert your account password.
                </p>
                <br/>
                <p className="is-size-5">Check out the questions bellow or see our <Link to="/help">Help</Link> for more
                    information.
                </p>
            </div>
        </div>


        <div className="content is-medium">
            <div className="hero-body has-text-centered">
                <h2>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'map-marker-question']} transform="down-1"
                                         size="1x"/>
                    </span>
                    <strong>&nbsp;What's this?</strong>
                </h2>
            </div>


            <div className="question">
                <label>
                    <input id="q9" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q9" className="question_text"> What is MetaMask?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">MetaMask is a digital wallet allowing you to
                            interact with the Ethereum network and safely transfer the ether (ETH) to the receiver of
                            the
                            Crypto GiftBox.
                        </p>
                        <p className="reveal-if-active_faq_text">For more information about MetaMask, please visit their
                            official website at <a href="https://metamask.io/" target="_blank"><i> metamask.io</i></a>.
                        </p>
                    </div>
                </label>
            </div>

            <div className="question">
                <label>
                    <input id="q12" type="checkbox" className="faq_checkbox"/>
                    <label htmlFor="q12" className="question_text"> Why does a locked screen appear?</label>
                    <div className="reveal-if-active_faq">
                        <p className="reveal-if-active_faq_text">Occasionally, MetaMask locks your account
                            automatically. If this happens, simply click on the MetaMask icon and enter your password to
                            unlock it.
                        </p>
                        <figure className="image">
                            <img src="src/assets/img/metamask/Slide11.jpeg"/>
                        </figure>
                    </div>
                </label>
            </div>

        </div>

    </section>
);

export default ErrorMetamaskLocked;
