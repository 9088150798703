import * as logger from 'loglevel';
import {Action, AnyAction} from 'redux';
import * as contractHelper from '../../crypto/contractHelper';
import {ContractGift} from '../../crypto';
import {decodeClaimKey} from '../../utils/encoders';
import {ThunkAction} from 'redux-thunk';

export const SET_CLAIM_GIFT_CONFIG_FIELD_ACTION = 'SET_CLAIM_GIFT_CONFIG_FIELD';

export interface SetClaimGiftConfigFieldAction extends Action {
    fieldName: string;
    value: string | number | boolean;
}

export function setClaimField(fieldName: string, value: string | number | boolean): SetClaimGiftConfigFieldAction {
    return {
        fieldName,
        value,
        type: SET_CLAIM_GIFT_CONFIG_FIELD_ACTION,
    };
}

export const SET_CLAIM_GIFT_ACTION = 'SET_CLAIM_GIFT';

export interface SetClaimGiftAction extends Action {
    claimKey: string;
    contractGift: ContractGift;
}

function setClaimGift(claimKey: string, contractGift: ContractGift): SetClaimGiftAction {
    return {
        claimKey,
        contractGift,
        type: SET_CLAIM_GIFT_ACTION,
    };
}

export function setClaimGiftKey(claimKey: string): ThunkAction<Promise<ContractGift>, {}, null, AnyAction> {
    // Return a function that accepts `dispatch` so we can dispatch later.
    // Thunk middleware knows how to turn thunk async actions into actions.
    return async (dispatch) => {
        contractHelper.validateClaimKey(claimKey);
        const {giftId, signKey} = decodeClaimKey(claimKey);
        const isVerified = await contractHelper.verifyGiftClaimKey(giftId, signKey);
        logger.info('Validated contract gift:', isVerified);
        if (!isVerified) {
            throw Error('Unable to verify claim key');
        }
        const gift: ContractGift = await contractHelper.loadGift(giftId);
        logger.info('Loaded contract gift:', gift);
        dispatch(setClaimGift(claimKey, gift));
        return gift;
    };
}

export const RESET_CLAIM_CONFIG_ACTION = 'RESET_CLAIM_CONFIG_ACTION ';

export function resetClaimConfig(): Action {
    return {type: RESET_CLAIM_CONFIG_ACTION};
}
