import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

const ErrorGiftClaimed: React.SFC = () => (
    <section className="container">
        <div className="hero error has-text-centered">
            <div className="hero-head ">
                <FontAwesomeIcon className="is-huge has-text-primary" icon={['fal', 'lock-alt']}/>
                <h1 className="title is-2">Gift already claimed</h1>
            </div>

            <div className="hero-body">
                <p className="is-size-5">It seems this gift has already been claimed.</p>
                <Link to="/claim/" className="is-flex is-horizontal-center ">
                    <button className="button is-medium is-primary is-outlined">Try again</button>
                </Link>
            </div>

        </div>

            <div className="content is-medium">
                <div className="hero-body has-text-centered">
                    <h2>
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon className="has-text-primary" icon={['fas', 'map-marker-question']} transform="down-1"
                                         size="1x"/>
                    </span>
                        <strong>&nbsp;What's this?</strong>
                    </h2>
                </div>


                <div className="question">
                    <label>
                        <input id="q9" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q9" className="question_text">Already claimed the Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">If you have recently made an attempt to claim this gift, it looks like it has
                                succeeded and the amount should be available in your account shortly, after full confirmation by
                                the Ethereum network.
                            </p>
                        </div>
                    </label>
                </div>

                <div className="question">
                    <label>
                        <input id="q12" type="checkbox" className="faq_checkbox"/>
                        <label htmlFor="q12" className="question_text">Never claimed the Crypto GiftBox?</label>
                        <div className="reveal-if-active_faq">
                            <p className="reveal-if-active_faq_text">If you have not made any attempts to claim this gift and believe this is a mistake, please get in
                                touch with us and we'll provide you with all the necessary support.
                            </p>
                            <p className="has-text-centered"><Link to="/help/contact" className=""> Write us</Link></p>
                        </div>
                    </label>
                </div>

            </div>

    </section>
);

export default ErrorGiftClaimed;
