export const valueFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
});
export const ethFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'ETH',
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
});

export const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
});

export const ellipsize = (value: string, keepStart: number, keepEnd: number) => {
    if (!value || value.length <= keepStart + keepEnd) {
        return value;
    }
    return value.substring(0, keepStart) + '...' + value.substring(value.length - keepEnd, value.length);
};
