import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {ethFormatter} from '../../utils/formatting';
import {ClaimGiftConfig} from '../../store/reducers/claimConfig';
import {decodeClaimKey} from '../../utils/encoders';
import {setClaimField} from '../../store/actions/claimConfig';

const ClaimConfirmStep: React.SFC<{ claimGiftConfig: ClaimGiftConfig } & DispatchProp> = ({claimGiftConfig, dispatch}) => {
    const {giftId} = decodeClaimKey(claimGiftConfig.claimKey);

    const handleTermsFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setClaimField('isAgreedToTerms', event.target.checked));
    };

    return (
        <div className="content is-medium">

            <div className="hero-body has-text-centered">
                <h2><strong>Your Crypto GiftBox summary</strong></h2>
                <p>
                    You're almost done. Please review the claiming details.
                </p>
            </div>

            <div className="box">
                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label is-summary-heading">Gift Value:</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p><b>{ethFormatter.format(claimGiftConfig.contractGift.value)}</b></p>
                        </div>
                    </div>
                </div>

                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label is-summary-heading">Receiving Address:</label>
                    </div>
                    <div className="field-body">
                        <div className="field">
                            <p><b>{claimGiftConfig.receiverAddress}</b></p>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <p><b>Everything looks alright?</b></p>
            <p> Great! Then click <b>Continue</b>. The ether will be transferred to your wallet shortly, and then you
                can enjoy your gift.
            </p>

            <div className="notification is-warning">
                <p className="is-size-6"><b>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} transform="down-0.5" size="1x"/> Notice</b>
                </p>
                <p className="is-size-6">
                    Please do not close the browser page until you receive the confirmation message.
                </p>

            </div>

            <div className="field is-spaced">
                <div className="control">
                    <label className="checkbox">
                        <input type="checkbox" checked={claimGiftConfig.isAgreedToTerms}
                               onChange={handleTermsFieldChange}/>
                        I agree to the <a href="#/terms" target="_blank">Terms of Use</a> and the <a
                        href="#/terms/privacy" target="_blank">Privacy
                        Policy</a>
                    </label>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        claimGiftConfig: state.claimGiftConfig,
    };
};

export default connect(mapStateToProps)(ClaimConfirmStep);
