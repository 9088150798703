import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CookiePolicy: React.SFC = () => (
    <section id="CookiePolicy" className="container">

        <div className="content is-medium">

            <div className="hero-body">
                <h2 className="has-text-centered"><strong>Cookie Policy</strong></h2>
            </div>

            <p>Our website (the „Site”) uses cookies in order to offer the users a better experience when browsing the Site, as well as to provide services adapted to the needs and the interests of the users. This Cookie Policy provides you with clear and comprehensive information about the cookies we use and the purposes for using them.
            </p>
            <p><strong>1. What is a cookie?</strong></p>
            <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies as soon as you visit our site.
            </p>
            <p>There are two main categories of cookies:
            </p>
            <p><i>Session cookies:</i> these cookies are used only during a browser session. Session cookies are created temporarily. Once you close the browser, all session cookies are deleted.
            </p>
            <p><i>Persistent cookies:</i> these cookies remain on a user’s device for the period of time specified in the cookie. They are activated each time that the user visits the Web site that created that particular cookie.
            </p>
            <p><strong>2. What is the lifetime of a cookie?</strong></p>
            <p>The lifetime of a cookie may significantly vary, depending on the purpose for which it is placed. As mentioned above, certain cookies are used exclusively for a single session (session cookies) and are deleted once the user leaves the website, while other cookies are kept and reused each time the user comes back to the respective website (persistent cookies). However, the cookies may be deleted at any time by addapting of browser setting
            </p>
            <p><strong>3. How to delete and block our cookies</strong></p>
            <p>You can block cookies by activating the settings on your browser that allows you to refuse all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies), you may not be able to access all or parts of our Site. Unless you have adjusted your browser settings so that it will refuse cookies, our system will issue cookies as soon as you visit our site.
            </p>
            <p><strong>4. Your consent</strong></p>
            <p>By continuing to use our Web site, you are agreeing to our placing cookies on your computer in order to analyse the way you use our Web site. If you wish to withdraw your consent at any time, you can delete your cookies using your internet browser settings.
            </p>
            <p><strong>5. What cookies do we use?</strong></p>
            <p>We use the following cookies:
            </p>
            <p><strong>Google Analytics cookies</strong> – these cookies provide anonymous / aggregated information about where you go and what you do on the website. These monitor how visitors navigate through the site and how they reached it. This is used so that we can see total (not individual) figures on which types of content users enjoy most or what might be a problem for them in navigating the site.
            </p>
            <p><strong>Google AdWords cookies</strong> – these cookies help us better administrate our website by analyzing the behavior of our online visitors. For example, these cookies may track the conversions from certain links on the website.
            </p>
            <p><strong>6. Third party cookies</strong></p>
            <p>Third-party cookies are cookies that are set by a domain other than that of our Site. Note that this Cookie Policy does not cover the use of cookies by such third parties.
            </p>
            <p><strong>7. Contact</strong></p>
            <p>If you have any questions or concerns about this Cookie Policy or its implementation, you may contact us at privacy@cryptogiftbox.com.
            </p>


        </div>

    </section>
);

export default CookiePolicy;
