import * as logger from 'loglevel';
import {Action} from 'redux';
import {GiftCard} from '../../cards';

export const SET_SEND_CONFIG_FIELD_ACTION = 'SET_SEND_GIFT_CONFIG_FIELD';

export interface SetSendGiftConfigFieldAction extends Action {
    fieldName: string;
    value: string | number | boolean;
}

export function setGiftField(fieldName: string, value: string | number | boolean): SetSendGiftConfigFieldAction {
    return {
        fieldName,
        value,
        type: SET_SEND_CONFIG_FIELD_ACTION,
    };
}

export const SET_GIFT_CARD_ACTION = 'SET_GIFT_CARD_ACTION ';

export interface SetGiftCardAction extends Action {
    cardId: string;
    card: GiftCard;
}

export function setGiftCard(cardId: string, card: GiftCard): SetGiftCardAction {
    return {
        cardId,
        card,
        type: SET_GIFT_CARD_ACTION,
    };
}

export const RESET_SEND_CONFIG_ACTION = 'RESET_SEND_CONFIG_ACTION ';

export function resetSendConfig(): Action {
    return {type: RESET_SEND_CONFIG_ACTION};
}
