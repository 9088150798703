import * as logger from 'loglevel';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../store';
import * as selectors from '../../store/selectors';

const Navbar: React.SFC<{ isAuthReady: boolean, isShownAsAuthenticated: boolean, userName: string }> = (props) => {
    const userInfo = !props.isAuthReady
        ? <span className="navbar-item"><FontAwesomeIcon icon="spinner" size="xs" spin={true}/></span>
        : !props.isShownAsAuthenticated
            ? <Link className="navbar-item is-underlined" to="/auth">Sign In</Link>
            : <span className="navbar-item">Welcome, {props.userName}</span>;
    const authenticatedLinks = props.isShownAsAuthenticated ?
        // TODO: Switch to React.Fragment when the type issue is fixed
        <Link className="navbar-item is-underlined" to="/gifts">Gifts</Link>
        : null;

    return (
        <div className="hero-head">
            <nav className="navbar has-shadow" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <Link className="navbar-item" to="/">
                            <span className="fa-layers fa-fw">
                                <FontAwesomeIcon className="has-text-primary" icon={['fal', 'rectangle-landscape']}
                                                 transform="grow-4" size="2x"/>
                                <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                                 transform="grow-3 right-25 up-15 rotate-30" size="1x"/>
                                <FontAwesomeIcon className="has-text-white" icon={['fab', 'ethereum']}
                                                 transform="shrink-3 right-25 up-15" size="1x"/>
                                <FontAwesomeIcon className="has-text-primary" icon={['far', 'gift']}
                                                 transform="grow-3 right-6" size="1x"/>
                            </span>
                            <span className="heading is-logo has-text-grey-dark">Crypto GiftBox
                            </span>
                        </Link>
                        <span className="navbar-burger burger" data-target="navbarMenu"/>
                    </div>
                    <div id="navbarMenu" className="navbar-menu is-active">
                        <div className="navbar-end">
                            {userInfo}
                            <Link className="navbar-item is-underlined" to="/send">Send Gift</Link>
                            <Link className="navbar-item is-underlined" to="/claim">Claim Gift</Link>
                            {authenticatedLinks}
                            <Link className="navbar-item is-underlined" to="/help">Help</Link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        isAuthReady: selectors.isAccountLoaded(state) && selectors.isAuthLoaded(state),
        isShownAsAuthenticated: !!selectors.getUserName(state) && !!selectors.getAccount(state),
        userName: selectors.getUserName(state),
    };
};

export default connect(mapStateToProps)(Navbar);
