import * as logger from 'loglevel';
import {Component, default as React} from 'react';
import GoogleAnalytics from 'react-ga';
import {RouteComponentProps, withRouter} from 'react-router';

export const Analytics = {
    initialize: GoogleAnalytics.initialize as ((trackingCode: string, options?: GoogleAnalytics.InitializeOptions) => void),
    trackEvent: (category: string, action: string) => {
        GoogleAnalytics.event({category, action});
    },
    trackException: (description: string, fatal: boolean = false) => {
        GoogleAnalytics.exception({description, fatal});
    },
    set: GoogleAnalytics.set as ((fieldsObject: GoogleAnalytics.FieldsObject) => void),
};

export const AnalyticsComponent = withRouter(class extends React.Component<RouteComponentProps> {
    public componentDidMount() {
        const currentPage = this.props.location.pathname + this.props.location.search;
        this.trackPage(currentPage);
    }

    public componentDidUpdate(prevProps: RouteComponentProps) {
        const prevPage = prevProps.location.pathname + prevProps.location.search;
        const currentPage = this.props.location.pathname + this.props.location.search;
        if (prevPage !== currentPage) {
            this.trackPage(currentPage);
        }
    }

    // noinspection JSMethodCanBeStatic
    public render() {
        return null;
    }

    private trackPage = (page) => {
        GoogleAnalytics.set({page});
        GoogleAnalytics.pageview(page);
    };
});
