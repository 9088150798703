import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from 'react-redux';
import * as selectors from '../../store/selectors';
import {validate as validateEmail} from 'email-validator';
import {AppState} from '../../store';
import webServices from '../../crypto/webService';

interface ContactProps {
    defaultName: string,
    defaultEmail: string,
}

class Contact extends React.Component<ContactProps,
    { name: string, email: string, message: string, isEmailError: boolean, isReadyToSend: boolean }> {

    constructor(props: ContactProps) {
        super(props);
        this.state = {
            name: props.defaultName,
            email: props.defaultEmail,
            message: '',
            isReadyToSend: false,
            isEmailError: false,
        };
    }

    public render() {
        return <section id="help" className="container">

            <div className="content is-medium">
                <div className="hero-body has-text-centered">
                    <h2><strong>Get in touch with us</strong></h2>
                    <p>We would love to hear from you. Fill in the form below for any questions or inquires you have.
                        We will gladly help you.</p>
                </div>

                <div className="box is-contact">
                    <span className="is-pulled-right">
                     <span className="fa-layers fa-fw">
                         <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                          transform="grow-3 left-3.7 down-1 up-5 rotate-30" size="7x"/>
                         <FontAwesomeIcon className="has-text-white" icon={['fab', 'ethereum']}
                                          transform="shrink-3 right-0.3 down-1 up-5" size="7x"/>
                     </span>
                    </span>
                    <div className="columns">
                        <div className="column is-narrow has-text-centered">
                            <FontAwesomeIcon className="has-text-primary" icon={['fal', 'gift']} transform="down-16"
                                             size="7x"/>
                        </div>

                        <div className="column">
                            <br/>
                            <div className="field is-paddingless">
                                <label className="label is-size-5"> Name </label>
                                <div className="control has-icons-left">
                                    <input className="input" type="text" placeholder="Your name or nickname"
                                           onChange={this.handleNameFieldChange} value={this.state.name}/>
                                    <span className="icon is-small is-left"><FontAwesomeIcon icon="user"/></span>
                                </div>
                            </div>

                            <div className="field is-paddingless">
                                <label className="label is-size-5">
                                    Email address
                                </label>
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input" type="email" placeholder="Email address"
                                           onChange={this.handleEmailFieldChange} value={this.state.email}
                                           onBlur={this.handleEmailFieldBlur}/>
                                    <span className="icon is-small is-left"> <FontAwesomeIcon icon="envelope"/></span>
                                    <span className="has-text-danger" hidden={!this.state.isEmailError}>
                                        This doesn't look like a valid email address.
                                    </span>
                                </div>
                            </div>

                            <div className="field is-grouped-multiline">
                                <label className="label is-size-5">
                                    Write your message
                                </label>
                                <textarea className="textarea" placeholder="" name="Contact"
                                          onChange={this.handleMessageFieldChange}/>
                            </div>

                            <span className="">
                                <button className="button is-primary is-medium" onClick={this.handleSubmit}
                                        disabled={!this.state.isReadyToSend}>Send</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>;
    }

    private handleNameFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const isReadyToSend = value.length > 0 &&
            !this.state.isEmailError &&
            this.state.email.trim().length > 0 &&
            this.state.message.trim().length > 0;
        this.setState({isReadyToSend, name: value});
    };

    private handleEmailFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const isReadyToSend = value.length > 0 &&
            this.state.name.trim().length > 0 &&
            this.state.message.trim().length > 0;
        this.setState({isReadyToSend, email: value, isEmailError: false});
    };

    private handleEmailFieldBlur = () => {
        const isEmailError = !validateEmail(this.state.email);
        this.setState({isEmailError, isReadyToSend: !isEmailError && this.state.isReadyToSend});
    };

    private handleMessageFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value.trim();
        const isReadyToSend = value.length > 0 &&
            !this.state.isEmailError &&
            this.state.name.trim().length > 0 &&
            this.state.email.trim().length > 0;
        this.setState({isReadyToSend, message: value});
    };

    private handleSubmit = async () => {
        logger.debug(`Sending contact message from ${this.state.name} (${this.state.email}): ${this.state.message}`);
        await webServices.contact(this.state.email, this.state.name, this.state.message);
    };
}

const mapStateToProps = (state: AppState): ContactProps => ({
    defaultName: selectors.getUserName(state) || '',
    defaultEmail: selectors.getUserEmail(state) || '',
});

export default connect(mapStateToProps)(Contact);
