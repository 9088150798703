import * as logger from 'loglevel';

import {Action} from 'redux';
import {
    SET_CLAIM_GIFT_CONFIG_FIELD_ACTION, SET_CLAIM_GIFT_ACTION,
    SetClaimGiftConfigFieldAction, SetClaimGiftAction,
    RESET_CLAIM_CONFIG_ACTION,
} from '../actions/claimConfig';
import {W3} from 'soltsice';
import address = W3.address;
import {ContractGift} from '../../crypto';

export interface ClaimGiftConfig {
    claimKey?: string;
    contractGift?: ContractGift;
    receiverAddress?: address;
    thankYouNote?: string;
    isAgreedToTerms: boolean;
    transactionHash?: string;
    error?: any;
}

const initialState: ClaimGiftConfig = {
    isAgreedToTerms: false,
};

export function claimGiftConfigReducer(state: ClaimGiftConfig = initialState, action: Action) {
    switch (action.type) {
        case SET_CLAIM_GIFT_CONFIG_FIELD_ACTION:
            return {
                ...state,
                [(action as SetClaimGiftConfigFieldAction).fieldName]: (action as SetClaimGiftConfigFieldAction).value,
            };
        case SET_CLAIM_GIFT_ACTION:
            return {
                ...state,
                claimKey: (action as SetClaimGiftAction).claimKey,
                contractGift: (action as SetClaimGiftAction).contractGift,
            };
        case RESET_CLAIM_CONFIG_ACTION:
            return initialState;
        default:
            return state;
    }
}
