import * as logger from 'loglevel';
import * as React from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ClaimSuccessStep: React.SFC = () => (
    <div className="content has-text-centered is-medium">

        <div className="hero-body">
            <h1 className="is-size-1 is-spaced is-bold"><strong>That's it, thank you!</strong></h1>
            <br/>
            <br/>

            <span className="icon has-text-success">
                <FontAwesomeIcon icon={['far', 'check-circle']} size="7x"/>
            </span>

            <br/>
            <br/>
            <p className="is-size-5">
                <strong>You've succesfully claimed your Crypto GiftBox.</strong>
            </p>
            <p>The Ether (ETH) has been transferred to your Ethereum wallet and you can now enjoy your gift at your wish.</p>
            <hr className="hr"/>
            <div className="box is-claim-success">
                <p><b>Do you want to send a Crypto GiftBox yourself?</b>
                </p>
                <p>You're more than welcome.
                    It's very easy and you'll have plenty of help from us along the way.</p>
                <p className="has-text-centered">
                    <Link to="/send">
                        <button className="button is-primary is-medium is-inverted">
                            Send a Crypto GiftBox
                        </button>
                    </Link>
                </p>
            </div>
            <hr className="hr"/>
            <p>
                <strong>
                    We hope you enjoyed your Crypto GiftBox experience, the same as we did! We can't wait to see you
                    again!
                </strong>
            </p>
            <p>
                Your feedback means a lot for us, just like you do!
                <Link to="help/contact" target="_blank" className=""> Write us</Link> for any
                suggestions or ideas you have, or even if it's just to say hello.
            </p>


        </div>
    </div>
);

export default ClaimSuccessStep;
