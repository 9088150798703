import * as logger from 'loglevel';

import {W3} from 'soltsice';
import address = W3.address;
import {Gift, ServerError, TransactionInfo} from './models';
import config from '../utils/config';
import Web3 = require('web3');
import Signature = W3.Signature;

function getUser(account: address): Promise<{ name: string, email: string, address: address }> {
    throw Error('Unsupported operation');
}

function registerUser(account: address, accountInfo: { name: string, email: string }): Promise<number> {
    throw Error('Unsupported operation');
}

function getGift(giftId: number): Promise<Gift> {
    throw Error('Unsupported operation');
}

function registerGift(web3: Web3, gift: Gift): Promise<number> {
    // Call our cloud for registering the gift
    const giftKeyPair = (web3.eth.accounts as any).create();
    const bodyContent = {
        txInfo: gift.txInfo,
        value: gift.value,
        cardId: gift.cardId,
        message: gift.message,
    };
    const payloadMessage: string = JSON.stringify(bodyContent);

    // TODO need sender prv key
    const sig: Signature = web3.eth.accounts.sign(payloadMessage, giftKeyPair.privateKey) as Signature;
    const signHeader = `${sig.r}-${sig.s}-${sig.v}`;

    logger.log('Hash from body,', sig);

    return fetch(`${config.SERVICE_URL}/gift/${gift.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Address': gift.senderAddress,
            // 'X-sign': signHeader,
        },
        mode: 'cors',
        body: payloadMessage,
    }).then((resp) => {
        if (resp.status >= 400) {
            // TODO: Add the response error object to the error
            throw new Error('Bad response from server');
        }
        return gift.id;
    });
}

function contact(from, name, message): Promise<void> {

    // const contentBody = JSON.stringify({ pdfBody : pdfString });
    return fetch(`${config.SERVICE_URL}/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
            from,
            message,
            subject: name,
        }),
    }).then((resp) => {
        if (resp.status >= 400) {
            throw new Error('Bad response from server');
        }
    });

}

function sharePDF(web3: Web3, base64Pdf, to): Promise<number> {
    // Call our cloud for registering the gift

    /* Some signature stuff for auth purposes
    const giftKeyPair = (web3.eth.accounts as any).create();
    const bodyContent = {
        txInfo: gift.txInfo,
        value: gift.value,
        cardId: gift.cardId,
        message: gift.message,
    };
    const payloadMessage: string = JSON.stringify(bodyContent);

    // TODO need sender prv key
    const sig: Signature = web3.eth.accounts.sign(payloadMessage, giftKeyPair.privateKey) as Signature;
    const signHeader = sig.r + '-' + sig.s + '-' + sig.v;

    logger.log('Hash from body,', sig);
    */

    // const contentBody = JSON.stringify({ pdfBody : pdfString });
    return fetch(`${config.SERVICE_URL}/share?to=${to}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'plain/text',
            // 'X-sign': signHeader,
        },
        mode: 'cors',
        body: base64Pdf,
    }).then((resp) => {
        if (resp.status >= 400) {
            // TODO: Add the response error object to the error
            throw new Error('Bad response from server');
        }
        return base64Pdf;
    });
}

async function claimGift(web3: Web3, giftId: number, receiverAddr: address, signKey: string): Promise<TransactionInfo> {
    const sig: Signature = web3.eth.accounts.sign(receiverAddr.slice(2), signKey) as Signature;

    const response = await fetch(`${config.SERVICE_URL}/gift/${giftId}/claim`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
            sendAddress: sig.message,
            r: sig.r,
            s: sig.s,
            v: sig.v,
        }),
    });
    if (response.status >= 400 && response.status < 500) {
        const error = (await response.json() as ServerError);
        throw new Error(`${error.message} (${error.code || '?'})`);
    } else if (response.status > 500) {
        throw new Error('Unknown server failure: ' + await response.text());
    }
    return response.json();
}

export default {
    getUser,
    registerUser,
    getGift,
    registerGift,
    claimGift,
    sharePDF,
    contact,
};
