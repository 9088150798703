import {W3} from 'soltsice';
import address = W3.address;
import {Action} from 'redux';
import {
    SetAccountAction, SET_ACCOUNT_ACTION, SetContractAction, SET_CONTRACT_ACTION, SetWeb3Action, SET_WEB3_ACTION,
    SET_SENT_GIFTS_ACTION, SetSentGiftsAction,
} from '../actions/crypto';
import {EthGiftBox} from '../../crypto/types';
import Web3 = require('web3');

export interface CryptoState {
// tslint:disable:max-line-length
    /** The account the user has selected, `undefined` if not loaded yet, or `null` if the account is locked. */
    account?: address | null;
    /** The contract instance, `undefined` if not loaded yet, or `null` if the contract could not be found (i.e. wrong network). */
    contract?: EthGiftBox | null;
    /** The web3 instance, `undefined` if not loaded yet, or `null` if one is not available (i.e. missing MetaMask or Mist). */
    web3?: Web3 | null;
    sentGiftIds: Set<number>;
// tslint:enable:max-line-length
}

export function cryptoReducer(state: CryptoState = {sentGiftIds: new Set()}, action: Action) {
    switch (action.type) {
        case SET_ACCOUNT_ACTION:
            return {
                ...state,
                account: (action as SetAccountAction).account,
            };
        case SET_CONTRACT_ACTION:
            return {
                ...state,
                contract: (action as SetContractAction).contract,
            };
        case SET_WEB3_ACTION:
            // If we don't have a valid web3, set the state accordingly
            if ((action as SetWeb3Action).web3 === null) {
                return {
                    ...state,
                    web3: null,
                    account: null,
                    contract: null,
                };
            }
            return {
                ...state,
                web3: (action as SetWeb3Action).web3,
            };
        case SET_SENT_GIFTS_ACTION:
            return {
                ...state,
                sentGiftIds: new Set((action as SetSentGiftsAction).sentGiftIds),
            };
        default:
            return state;
    }
}
