import * as logger from 'loglevel';
import {store} from '../store';
import {W3} from 'soltsice';
import address = W3.address;
import config from '../utils/config';
import {firebaseApp} from './index';
import {getUserId, getAccount} from '../store/selectors';
import {actionTypes, getFirebase} from 'react-redux-firebase';

interface ServerError {
    error: {
        code: number,
        message: string,
    };
}

export async function authenticate(account: address) {
    // Make sure we have what's needed
    const web3 = store.getState().crypto.web3;
    if (!web3) {
        throw Error('Web3 is unavailable');
    }

    // Generate a signature to make sure the user has access to the provided account
    const signature: string = await (web3.eth.personal as any).sign('CryptoGiftBox login request', account);
    logger.debug(`Message signature for ${account}:`, signature);

    // Validate the signature on the web server
    const response: Response = await fetch(`${config.SERVICE_URL}/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        mode: 'cors',
        body: JSON.stringify({account, signature}),
    });

    // Make sure no error was encountered
    if (!response.ok) {
        const errorBody: ServerError = await response.json();
        logger.error('Authentication failed:', errorBody.error);
        throw Error(`Authentication failed: ${errorBody.error.message}`);
    }

    // Extract the authentication info
    const authInfo: { uid: string, accessToken: string } = await response.json();
    logger.debug('Server authentication successful:', authInfo);

    // Sign in to Firebase
    const user = await firebaseApp.auth().signInWithCustomToken(authInfo.accessToken);
    logger.debug('Firebase authentication successful:', user);
}

export async function updateUserProfile(name: string, email: string) {
    try {
        // Update the Firebase user
        const firebase = getFirebase();
        await firebase.auth().currentUser.updateProfile({displayName: name, photoURL: null});
        await firebase.auth().currentUser.updateEmail(email);
        // NOTE: Due to a bug in Redux-Firebase, making an update via firebase.updateAuth() creates a few intermediate
        // states where the auth object is empty. As such, we make the updates directly via Firebase and then dispatch
        // an update by hand
        store.dispatch({type: actionTypes.AUTH_RELOAD_SUCCESS, payload: firebase.auth().currentUser});

        // Update the profile as well
        await firebase.updateProfile({email, displayName: name});
    } catch (error) {
        if (error.code === 'auth/requires-recent-login') {
            logger.warn(`Detected required re-login for id=${getUserId(store.getState())}. Signing out user...`);
            getFirebase().logout();
        }
        throw error;
    }
}

export async function checkAuthMismatch(authenticatedUserId: string = getUserId(store.getState())) {
    const userId = getAccount(store.getState());
    logger.debug('Checking user auth mismatch...');
    if (authenticatedUserId && userId && userId !== authenticatedUserId) {
        logger.warn(`Detected user auth mismatch: id=${userId}, auth=${authenticatedUserId}. Signing out user...`);
        getFirebase().logout();
    }
}
