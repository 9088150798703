import * as logger from 'loglevel';
import {W3} from 'soltsice';
import {Action} from 'redux';

export const SET_ACCOUNT_INFO_ACTION = 'SET_ACCOUNT_INFO';

export interface SetAccountInfoAction extends Action {
    name: string;
    email: string;
}

export function setAccountInfo(name: string, email: string): SetAccountInfoAction {
    return {
        name,
        email,
        type: SET_ACCOUNT_INFO_ACTION,
    };
}
