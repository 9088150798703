import * as logger from 'loglevel';

/**
 * Logs all actions and states after they are dispatched.
 */
export const autoLogger = store => next => (action) => {
    logger.debug(`Dispatching ${action.type} action:`, action);
    const result = next(action);
    logger.debug(`\t\t-> New state:`, store.getState());
    return result;
};

/**
 * Schedules actions with { meta: { delay: N } } to be delayed by N milliseconds.
 * Makes `dispatch` return a function to cancel the timeout in this case.
 */
export const delayedScheduler = store => next => (action) => {
    if (!action.meta || !action.meta.delay) {
        return next(action);
    }

    const timeoutId = setTimeout(
        () => next(action),
        action.meta.delay,
    );

    return function cancel() {
        clearTimeout(timeoutId);
    };
};

/**
 * Lets you dispatch a function instead of an action.
 * This function will receive `dispatch` and `getState` as arguments.
 *
 * Useful for early exits (conditions over `getState()`), as well
 * as for async control flow (it can `dispatch()` something else).
 *
 * `dispatch` will return the return value of the dispatched function.
 */
export const thunk = store => next => action =>
    typeof action === 'function'
        ? action(store.dispatch, store.getState)
        : next(action);

/**
 * Lets you dispatch promises in addition to actions.
 * If the promise is resolved, its result will be dispatched as an action.
 * The promise is returned from `dispatch` so the caller may handle rejection.
 */
export const vanillaPromise = store => next => action =>
    typeof action.then !== 'function'
        ? next(action)
        : Promise.resolve(action).then(store.dispatch);
