import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {AppState, store} from '../../store';
import {connect, InferableComponentEnhancer} from 'react-redux';
import {compose} from 'redux';
import {firestoreConnect, helpers} from 'react-redux-firebase';
import {ellipsize, valueFormatter} from '../../utils/formatting';
import LoadingInProgress from '../common/LoadingInProgress';
import {isAccountLoaded, isAuthenticated, isAuthLoaded} from '../../store/selectors';
import AuthRequired from '../common/AuthRequired';
import {Link} from 'react-router-dom';
import isLoaded = helpers.isLoaded;
import {Gift} from '../../crypto/models';

const renderGiftRow = (gift: Gift) => (
    <tr key={gift.id}>
        <td>{gift.id}</td>
        <td>{gift.createdAt.toDate().toLocaleString()}</td>
        <td>{valueFormatter.format(gift.value)}</td>
        <td>{ellipsize(gift.txInfo.txHash, 12, 4)}</td>
        <td>{!gift.receiverAddress ? 'Unclaimed' : 'Claimed'}</td>
    </tr>
);

const renderIncompleteGiftRow = (giftId: number) => (
    <tr key={giftId}>
        <td>{giftId}</td>
        <td>?</td>
        <td>?</td>
        <td>?</td>
        <td>Incomplete</td>
    </tr>
);

const GiftListView_: React.SFC<{ gifts: Gift[], incompleteGifts: number[], isLoading: boolean }> = (props) => {
    if (props.isLoading) {
        return <LoadingInProgress/>;
    }

    if (props.gifts.length + props.incompleteGifts.length === 0) {
        return (
            <section className="container">
                <div className="content is-medium">
                    <div className="hero-head has-text-centered">
                        <FontAwesomeIcon className="has-text-primary" icon={['fal', 'gift']} size="7x"/>
                    </div>
                    <div className="hero-body has-text-centered">
                        <p><b>It seems you haven't sent any Crypto GiftBoxes so far...</b></p>
                        <p>Do you want to send one? You're more than welcome. It's very easy and you'll have plenty of
                            help from us along the way.
                        </p>
                        <Link to="/send">
                            <button className="button is-medium is-primary is-outlined">Get started</button>
                        </Link>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="container is-fluid">
            <div className="content is-medium">
                <div className="hero-body has-text-centered">
                    <h2><strong>Crypto GiftBox history</strong></h2>
                    <p>Check out the summaries of your Crypto Giftboxes.</p>
                </div>
            </div>

            <table className="table is-hoverable is-fullwidth">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Sent at</th>
                    <th>Value (ETH)</th>
                    <th>Transaction</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {props.gifts.map(renderGiftRow)}
                {props.incompleteGifts.map(renderIncompleteGiftRow)}
                </tbody>
            </table>
        </section>
    );
};

const GiftListView = compose(
    firestoreConnect(props => [
        {collection: 'gifts', where: ['senderAddress', '==', store.getState().crypto.account]},
    ]) as InferableComponentEnhancer<{}>,
    connect(
        (state: AppState) => {
            const gifts = Object.keys(state.firestore.data.gifts || [])
                .map(key => state.firestore.data.gifts[key])
                .filter(gift => state.crypto.sentGiftIds.has(gift.id));

            // If we have loaded the gifts and we have some unmatched ids in the contract, add them to the list in order
            // to give the user a way to withdraw them
            let incompleteGifts;
            if (isLoaded(state.firestore.data.gifts) && state.crypto.sentGiftIds.size > gifts.length) {
                incompleteGifts = Array.from(state.crypto.sentGiftIds)
                    .filter(id => gifts.findIndex((gift: Gift) => gift.id === id) < 0);
                logger.error('Contract gifts not found in database: ', incompleteGifts);
            } else {
                incompleteGifts = [];
            }

            return {
                gifts,
                incompleteGifts,
                isLoading: !isLoaded(state.firestore.data.gifts),
            };
        },
    ),
)(GiftListView_);

/**
 * Since we use tha crypto account in the composition of the GiftListView, in order to perform the query,
 * we need to wrap that view so that it only gets rendered when the crypto account is available.
 */
const GiftList: React.SFC<{ isLoading: boolean, isAuthenticated: boolean }> = props => (
    (props.isLoading)
        ? <LoadingInProgress/>
        : (props.isAuthenticated
        ? <GiftListView/>
        : <AuthRequired/>)
);
export default connect((state: AppState) => ({
    isLoading: !isAccountLoaded(state) || !isAuthLoaded(state),
    isAuthenticated: isAuthenticated(state),
}))(GiftList);
