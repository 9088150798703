import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';
import {AppState} from '../../store';
import {connect} from 'react-redux';
import {SendGiftConfig} from '../../store/reducers/sendConfig';

const SendErrorStep: React.SFC<{
    error: Error,
    claimKey: string,
    transactionHash: string,
}> = (props) => {
    // Clean the error, if badly formatted
    let errorMessage: string;
    let isDenied: boolean = false;
    if (props.error.message.indexOf('User denied transaction signature') > 0) {
        errorMessage = 'MetaMask Tx Signature: User denied transaction signature.';
        isDenied = true;
    } else {
        const newLineIdx = props.error.message.indexOf('\n');
        errorMessage = newLineIdx > 1
            ? props.error.message.substring(0, newLineIdx)
            : errorMessage = props.error.message;
    }
    return (
        <div className="content">
            <div className="hero-body has-text-centered">
                <div className="columns">
                    <div className="column is-one-fifth">
                        <p className="has-text-left">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['far', 'user-md']} transform="down-8"
                                             size="6x"/>
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'medkit']}
                                             transform="down-26 right-30" size="3x"/>
                        </span>
                        </p>
                    </div>
                    <div className="column">
            <h1 className="title is-spaced"><b>Hmm. Something has gone wrong...</b></h1>

            <p className="is-size-5">No worries, your Ether is safe. Let's do a quick exam to check what happened.</p>
                    </div>
                </div>
            </div>
            <br/>
            <div className="notification is-warning">
                <p className="is-size-5">
                    Apparently, an error has occurred while trying to create your Crypto GiftBox:
                </p>
                <code className="is-flex">
                    {errorMessage}
                </code>
            </div>
            <div className="card" hidden={!isDenied}>
                <div className="card-content">
                    <p className="is-size-5">
                    <span className="icon is-inline-block">
                        <FontAwesomeIcon className="has-text-primary" icon="wrench" size="1x"/>
                    </span>
                    It seems the MetaMask transaction was denied. In order to create the Crypto GiftBox, the transaction
                    needs to
                    be approved when the MetaMask window shows up. If you need help, please read more
                    <Link to="/help/metamasksubmit" target="_blank"> here</Link>.
                    </p>
                </div>
            </div>
            <div className="card" hidden={isDenied}>
                <div className="card-content">
                    <p className="is-size-5">
                    <span className="icon is-inline-block">
                        <FontAwesomeIcon className="has-text-primary" icon="wrench" size="1x"/>
                    </span>
                    Generally, if an error has occurred, then something has gone wrong with the Ethereum
                    transaction, so your Ether has not been transferred and is safely in your MetaMask wallet. However, in the
                    rare chance that something else happened and the Ether has been transferred, although the Crypto GiftBox was not generated, don't worry as there are easy ways to quickly recover.
                    </p>
                    <p className="is-size-5">So, if the Ether has been transferred, the Crypo GiftBox should appear in the <b><i>Gifts</i></b> section. And here's what you can do:
                    <ol>
                        <li>Send the claim key yourself to your intended receiver;</li>
                        <li>Use the withdrawal feature to get back the Ether
                            yourself. <strong>Warning:</strong> In order for this feature to work, you must use the same MetaMask
                            account.
                        </li>
                    </ol>
                    </p>
                    <div hidden={!props.claimKey}>
                        <hr/>
                        <p className="has-text-centered"><strong>Recovery info</strong></p>
                        <p>

                            <div className="field is-horizontal">
                                <div className="field-label" hidden={!props.claimKey}>
                                    <label className="label is-success-heading">Claim Key:</label>
                                </div>
                                <div className="field-body" hidden={!props.claimKey}>
                                    <div className="field is-narrow is-pulled-left">
                                        <p>{props.claimKey}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-label" hidden={!props.transactionHash}>
                                    <label className="label is-success-heading">Transaction:</label>
                                </div>
                                <div className="field-body" hidden={!props.transactionHash}>
                                    <div className="field">
                                        <p>{props.transactionHash}</p>
                                    </div>
                                </div>
                            </div>

                            <span hidden={!props.claimKey}><b>Claim Key:</b> {props.claimKey}<br/></span>
                            <span hidden={!props.transactionHash}><b>Transaction:</b> {props.transactionHash}</span>
                        </p>
                    </div>
                </div>
            </div>
            <br/>
            <Link to="/send/confirm" className="is-flex  is-horizontal-center ">
                <button className="button is-medium is-primary is-outlined">Try again</button>
            </Link>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        error: state.sendGiftConfig.error,
        claimKey: state.sendGiftConfig.claimKey,
        transactionHash: state.sendGiftConfig.transactionHash,
    };
};

export default connect(mapStateToProps)(SendErrorStep);
