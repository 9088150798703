import * as logger from 'loglevel';
import * as React from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {connect} from 'react-redux';
import {AppState} from '../../store';
import * as selectors from '../../store/selectors';

const SendWelcomeStep: React.SFC<{ isAuthenticated: boolean, userName: string }> = props => (
    <div className="content is-medium has-text-centered">
        <h1 className="title is-spaced"><b>Hello, {props.userName}!</b></h1>
        <h3 className="subtitle">Let's create your Crypto GiftBox.</h3>

        <br/>
        <p>
            Follow these simple steps, and in just a few minutes you'll have your Crypto GiftBox ready
            to be given to the lucky receiver.
        </p>

        <br/>
        <br/>
        <div className="level">
            <div className="level-item has-text-left">
                <div>
                    <p className="icon is-large has-text-primary">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                             transform="left-2 rotate-30" size="4x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['far', 'rectangle-landscape']}
                                             transform="grow-4 right-6" size="2x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['far', 'gift']}
                                             transform="grow-2 right-18" size="1x"/>
                        </span>
                    </p>
                    <p className="heading"><br/>Choose GiftBox
                    </p>
                </div>
            </div>
            <div className="level-item has-text-left">
                <div>
                    <p className="icon is-large has-text-primary">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                             transform="right-1 rotate-30" size="4x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['far', 'paint-brush']}
                                             transform="grow-3 right-11" size="2x"/>
                        </span>
                    </p>
                    <span className="heading"><br/>Customize your gift</span>
                </div>
            </div>
            {/*<div className="level-item has-text-centered">*/}
            {/*<div>*/}
            {/*<p className="has-text-primary">*/}
            {/*<FontAwesomeIcon icon={['far', 'key']} mask={['fas', 'circle']}*/}
            {/*transform="shrink-7" size="4x"/>*/}
            {/*</p>*/}
            {/*<span className="heading">Generate claim key</span>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div className="level-item has-text-left">
                <div>
                    <p className="icon is-large has-text-primary">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                             transform="right-1 rotate-30" size="4x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['fab', 'ethereum']}
                                             transform="grow-3 right-15" size="2x"/>
                        </span>
                    </p>
                    <span className="heading"><br/>Transfer the ether</span>
                </div>
            </div>
            <div className="level-item has-text-left">
                <div>
                    <p className="icon is-large has-text-primary">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-primary" icon={['fas', 'hexagon']}
                                             transform="right-3 rotate-30" size="4x"/>
                            <FontAwesomeIcon className="has-text-white" icon={['fas', 'cloud-download']}
                                             transform="grow-3 right-14" size="2x"/>
                        </span>
                    </p>
                    <span className="heading"><br/>Download your GiftBox</span>
                </div>
            </div>
        </div>
        <br/>
        <div className="notification is-primary is-size-6" hidden={props.isAuthenticated}>
            Before you can begin configuring your gift, you'll need to sign in. That way you'll be able to easily manage
            your gifts and receive the best support, if needed.
        </div>
        <Link to={props.isAuthenticated ? '/send/card' : '/auth'}>
            <button className="button is-large is-primary is-outlined">
                {props.isAuthenticated ? 'Get started' : 'Sign in'}
            </button>
        </Link>
    </div>
);


export default connect((state: AppState) => {
    return {
        isAuthenticated: selectors.isUserConfigured(state),
        userName: selectors.getUserName(state),
    };
})(SendWelcomeStep);
