import * as logger from 'loglevel';
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {SendGiftConfig} from '../../store/reducers/sendConfig';
import * as pdfGenerator from '../../utils/pdfGenerator';
import webServices from '../../crypto/webService';
import {GiftCard} from '../../cards';

class SendSuccessStep extends React.Component<{ sendGiftConfig: SendGiftConfig } & DispatchProp, {}> {

    private canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;

    constructor(props) {
        super(props);
    }

    public componentDidMount() {
        this.ctx = this.canvas.getContext('2d');
        this.sendGiftEmail(this.props.sendGiftConfig);
    }

    public render() {
        return (
            <div className="content has-text-centered">
                <canvas ref={canvasRef => this.canvas = canvasRef} className="gift-canvas" hidden={true}/>
                <div className="hero-body">
                    <h1 className="is-size-1 is-spaced is-bold"><strong>And that's it, thank you!</strong></h1>
                    <br/>
                    <br/>

                    <span className="icon has-text-success">
                <FontAwesomeIcon icon={['far', 'check-circle']} size="7x"/>
            </span>

                    <br/>
                    <br/>
                    <p className="is-size-5">
                        <strong>Your Crypto GiftBox has been created.</strong></p>
                    <p className="is-size-5">The unique secret claim has been generated and the Ether has been safely
                        deposited
                        into the smart
                        contract waiting to be withdrawn by the lucky receiver.
                    </p>


            <div className="box">
                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label is-success-heading">Claim Key:</label>
                    </div>
                    <div className="field-body">
                        <div className="field is-narrow is-pulled-left">
                            <p>{this.props.sendGiftConfig.claimKey}</p>
                        </div>
                    </div>
                </div>

                        <div className="field is-horizontal">
                            <div className="field-label">
                                <label className="label is-success-heading">Transaction:</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p>{this.props.sendGiftConfig.transactionHash}</p>
                                </div>
                            </div>
                        </div>
                    </div>

            <hr className="hr"/>

            <p className="has-text-centered">
                <button className="button is-primary is-large"
                        onClick={() => this.generatePdf(this.props.sendGiftConfig)}>DOWNLOAD
                </button>
            </p>

            <div className="notification is-warning has-text-left">
                <p className="is-size-6"><b>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} transform="down-0.5" size="1x"/> Notice</b>
                </p>
                <div className="columns">
                    <div className="column">
                        <p className="is-size-6">Safeguard the Crypto GiftBox very carefully, because:
                        </p>
                        <ul className="is-size-6">
                            <li>The receiver can request the ether only by using the claim
                                key printed on the Crypto GiftBox.
                            </li>
                            <li>We do not store the claim key and we do not have access to it. If the Crypto GiftBox
                                is
                                lost, we cannot recover the claim key.
                            </li>
                            <li>Anyone having the claim key can withdraw the ether.</li>
                        </ul>
                    </div>
                    <div className="column is-one-quarter has-text-centered">
                        <p className="">
                        <span className="fa-layers fa-fw">
                            <FontAwesomeIcon className="has-text-white" icon={['fas', 'shield']}
                                             transform="down-9 left-5" size="7x"/>
                            <FontAwesomeIcon className="has-text-primary" icon={['far', 'shield-alt']}
                                             transform="down-9 left-5" size="7x"/>
                        </span>
                        </p>
                    </div>
                </div>
                <p className="is-size-6">
                    For more information about how Crypto GiftBox works, see our <Link to="/help"
                                                                                       target="_blank">Help</Link>.
                </p>

            </div>

            <hr className="hr"/>

                    <p className="is-size-5">
                        <strong>
                            We hope you enjoyed your Crypto GiftBox experience, the same as we did! We can't wait to see
                            you
                            again!
                        </strong>
                    </p>
                    <p className="is-size-5">
                        Your feedback means a lot for us, just like you do!
                        <Link to="help/contact" target="_blank" className=""> Write us</Link> for any
                        suggestions or ideas you have, or even if it's just to say hello.
                    </p>
                </div>
            </div>
        );
    }

    public generatePdf(sendGiftConfig: SendGiftConfig) {
        pdfGenerator.downloadCardPdf(sendGiftConfig, this.canvas, this.ctx);
    }

    public sendGiftEmail(sendGiftConfig: SendGiftConfig) {
        const pdfBlob = pdfGenerator.getCardDocPdfAsBlob(sendGiftConfig, this.canvas, this.ctx);
        // logger.log(pdfString);

        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            const indexSep = base64data.indexOf(',');
            const contentBase64 = base64data.substring(indexSep + 1, base64data.length);

            logger.log('Send to email  ', sendGiftConfig.toEmail);

            webServices.sharePDF(null, contentBase64, sendGiftConfig.toEmail);
        };
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        sendGiftConfig: state.sendGiftConfig,
    };
};

export default connect(mapStateToProps)(SendSuccessStep);
