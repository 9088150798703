import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';

export function initializeFontAwesome() {
    // Temporary add all allIcons
    library.add(fas, fal, far, fab);
}
