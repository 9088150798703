import * as logger from 'loglevel';
import * as React from 'react';
import ReactImageGallery, {ReactImageGalleryItem} from 'react-image-gallery';
import {connect, DispatchProp} from 'react-redux';
import {AppState} from '../../store';
import {setGiftCard} from '../../store/actions/sendConfig';
import giftCardsConfig from '../../cards/config';
import {SendGiftConfig} from '../../store/reducers/sendConfig';
import {RouteComponentProps} from 'react-router';
import {Action} from 'redux';

interface CardReactImageGalleryItem extends ReactImageGalleryItem {
    cardId: string;
}

interface SendCardStepProps extends RouteComponentProps<{}>, DispatchProp<Action<AppState>> {
    sendGiftConfig: SendGiftConfig;
}

class SendCardStep extends React.Component<SendCardStepProps, {}> {

    private images: CardReactImageGalleryItem[];

    constructor(props) {
        super(props);
        // Load all configured images and save their card id
        this.images = Object.keys(giftCardsConfig)
            .map((cardId) => {
                return {
                    cardId,
                    ...(giftCardsConfig[cardId].image),
                };
            });
    }

    public shouldComponentUpdate(nextProps, nextState): boolean {
        // NOTE: ReactImageGallery manages it's state internally, so there's no need to render again whenever the
        // selected card changes
        return false;
    }

    public render() {
        // If no card has been configured so far, pre-select the first one, by triggering the slide event
        let startIndex = 0;
        if (this.props.sendGiftConfig.cardId === undefined) {
            this.onSlideCarusel(startIndex);
        } else {
            startIndex = this.images.findIndex(image => image.cardId === this.props.sendGiftConfig.cardId);
        }

        return (
            <div className="content is-medium">
                <div className="hero-body has-text-centered">
                    <h2><strong>Pick a GiftBox you like</strong></h2>
                    <p>
                        That's how your Crypto GiftBox will look.
                    </p>
                </div>

                <ReactImageGallery items={this.images} startIndex={startIndex} onSlide={this.onSlideCarusel}
                                   showNav={false} thumbnailPosition={'right'} showFullscreenButton={false}
                                   showPlayButton={false} slideDuration={0}/>
            </div>
        );
    }

    // When we select a slide, immediately mark the card as the selected one
    private onSlideCarusel = (index: number) => {
        const selectedCard = this.images[index];
        this.props.dispatch(setGiftCard(selectedCard.cardId, giftCardsConfig[selectedCard.cardId]));
    };
}

const mapStateToProps = (state: AppState) => {
    return {
        sendGiftConfig: state.sendGiftConfig,
    };
};

export default connect(mapStateToProps)(SendCardStep);
