import * as logger from 'loglevel';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../store';
import {connect, DispatchProp} from 'react-redux';
import {SendGiftConfig} from '../../store/reducers/sendConfig';
import {ethFormatter, usdFormatter} from '../../utils/formatting';
import {setGiftField} from '../../store/actions/sendConfig';
import * as selectors from '../../store/selectors';
import {computeTotalCost} from '../../utils/feeUtils';

const SendConfirmStep: React.SFC<{ sendGiftConfig: SendGiftConfig, userEmail: string } & DispatchProp>
    = ({sendGiftConfig, userEmail, dispatch}) => {

    const handleTermsFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setGiftField('isAgreedToTerms', event.target.checked));
    };

    const handleSendEmailFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setGiftField('toEmail', event.target.checked ? userEmail : null));
    };

    return (
        <div className="content is-medium">

            <div className="hero-body has-text-centered">
                <h2><strong>Crypto GiftBox summary</strong></h2>
                <p>
                    You're almost done. Please review the Crypto GiftBox details.
                </p>
            </div>

            <div className="box">
                <div className="level">
                    <div className="level-item has-text-centered">
                        <div>
                            <p className="heading">Amount (ETH)</p>
                            <p className="title">{ethFormatter.format(sendGiftConfig.value)}</p>
                        </div>
                    </div>
                    <div className="level-item has-text-centered">
                        <div>
                            <p className="heading">Total cost (ETH)</p>
                            <p className="title">{ethFormatter.format(computeTotalCost(sendGiftConfig.value))}</p>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <p><b>Everything looks alright?</b></p>
            <p>Great! Then click <b>Continue</b> - you will see a MetaMask window and will need to
                approve the transaction to create the Crypto GiftBox.
            </p>

            <div className="notification is-warning">
                <p className="is-size-6"><b>
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} transform="down-0.5" size="1x"/> Notice</b>
                </p>
                <p className="is-size-6">
                    To prevent any issues in the generating process, do not close the browser page while the Crypto
                    GiftBox is created .
                </p>
                <p className="is-size-6">
                    See this short <Link to="/help/metamasksubmit" target="_blank">Help</Link> for more information.
                </p>
            </div>

            <div className="field">
                <div className="control">
                    <label className="checkbox">
                        <input type="checkbox" checked={sendGiftConfig.toEmail !== null}
                               onChange={handleSendEmailFieldChange}/>
                        Send the GiftBox to my email when finished <i className="is-small">({userEmail})</i>
                    </label>
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <label className="checkbox">
                        <input type="checkbox" checked={sendGiftConfig.isAgreedToTerms}
                               onChange={handleTermsFieldChange}/>
                        I agree to the <a href="#/terms" target="_blank">Terms of Use</a> and the <a
                        href="#/terms/privacy" target="_blank">Privacy Policy</a>
                    </label>
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        sendGiftConfig: state.sendGiftConfig,
        userEmail: selectors.getUserEmail(state) || '-',
    };
};

export default connect(mapStateToProps)(SendConfirmStep);
