import * as logger from 'loglevel';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';

const LoadingInProgress: React.SFC = () => (
    <section className="container">
        <div className="hero has-text-centered">
            <div className="hero-body">
                <div className="hero-head is-primary">
                    <FontAwesomeIcon className="spinner" icon="spinner" size="7x" spin={true}/>
                </div>
                    <p className="is-size-5">Loading... Please wait.</p>
            </div>
        </div>
    </section>
);

export default LoadingInProgress;
