import * as logger from 'loglevel';
import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PrivacyPolicy: React.SFC = () => (
    <section id="PrivacyPolicy" className="container">

        <div className="content is-medium">

            <div className="hero-body">
                <h2 className="has-text-centered"><strong>Privacy Policy</strong></h2>
            </div>
            <p><strong>Last Updated: 20 December 2017</strong></p>

            <p>Your privacy is one of our fundamental commitments here at CryptoGiftBox. Therefore, we take outmost care to process your personal data in accordance with the principles set forth in the applicable legislation. We have written this Privacy Policy to reflect our values and to explain how your personal data is collected, stored, used and disclosed by CryptoGiftBox („CryptoGiftBox”, „we”, „us”), as a data controller, with respect to your access and use of our website identified with the url at https://www.cryptogiftbox.com (the “Site”). By using the Site, you agree that we can collect, use, disclose, process and transfer your information as described in this Privacy Policy. You understand and agree that in the absence of your  consent to the processing of the personal data pursuant to this Privacy Policy, we cannot provide you our services through our Site.
            </p>
            <p>We reserve the right to change the provisions of this Privacy Policy from time to time. If CryptoGiftBox makes changes to these Privacy Policy, we will make the updated version available on the Site and update the “Last Updated” date found at the top of this Privacy Policy. We encourage you to review this Privacy Policy from time-to-time to make sure that you understand how any personal data you provide to us or collected about you will be used. Any changes to the Privacy Policy will apply on the date that they are made, and your continued access to or use of the Site after the Privacy Policy has been updated will constitute your binding acceptance of the updates.
            </p>
            <p><strong>1. What is Personal Data?</strong></p>
            <p>Personal data represents any information relating to an identified or identifiable natural person, and an identifiable person is that person which may be identified directly or indirectly.
            </p>
            <p><strong>2. What categories of Personal Data do we collect?</strong></p>
            <p>We will only collect a minimum of personal data - the name, the email address, and Ethereum wallet address. We do not collect any other personal data, unless you choose to fill out a 'Contact Us' form or you give us written feedback („Personal Data”).
            </p>
            <p>Additionally, when you use the Site, some non-personally identifiable information when you visit our site – such as the type of browser you are using, the type of operating system you are using, the URL of the accessed pages, the hour and date of access and the IP address from which the page was accessed. This information is used in aggregate or de-identified form only. For further details, please refer to our Cookie Policy.
            </p>
            <p><strong>3. For what purpose do we collect the Personal Data?</strong></p>
            <p>We process the aforementioned categories of Personal Data for the following purposes:
            </p>
            <ul>
                <li>To provide you the services through our Site, namely, to process and complete the ETHER transaction, to send you confirmations and information on the claiming by the receiver of the ETHER you have given;
                </li>
                <li>In case you contact us on a specific matter, we will use the Personal Data to respond to your inquiry or process the application form you have completed on our Site;
                </li>
                <li>In case you expressly consented to receive updates or news regarding our services, we will use the contact details, in order to send you such updates or news;
                </li>
                <li>We use non-personally identifiable information to analyze the Site usage (such as aggregated information on the pages visited by our users), which allows us to improve the design and content of our Site.
                </li>
            </ul>
            <p><strong>4. Transfer of Personal Data</strong></p>
            <p>Your Personal Data are filed and stored on our servers and the servers of our contractual partners that are helping us to provide our services to you. While as a rule we shall not disclose your Personal Data to any third parties, we may transfer the information we collect with third parties, such as, business partners, contractors, or other parties working on our behalf. Such third parties may be located within the EU and outside the EU, including to countries which are not recognized as ensuring an adequate level of protection. Currently, your dersonal Data is transferred to the [.....]. We will only transfer your Personal Data provided adequate safeguards are in place, according to the applicable law, and we will take steps to ensure that your Personal Data is adequately protected. In this sense, we rely on several legal guarantees, such as the EU-U.S. Privacy Shield and the EU Model Standard Contractual Clauses.
            </p>
            <p>Additionally, it is possible, though unlikely, that we might be forced to disclose personally identifiable information in response to legal process or when we believe in good faith that the law requires it, for example, in response to a court order, subpoena or a law enforcement agency's request.
            </p>
            <p><strong>5. Security</strong></p>
            <p>Security of your Personal Data is important to us. Therefore, we provide reasonable administrative, technical, and physical security controls to protect your Personal Data. However, despite our efforts, no security controls are in all cases effective. With that in mind, we cannot warrant at all times the security of your Personal Data.
            </p>
            <p><strong>6. What are your rights?</strong></p>
            <p>You have the following rights in connection with the processing of your Personal Data:
            </p>
            <ul>
                <li>the right to access your Personal Data;</li>
                <li>the right to intervene on your Personal Data (g., request the update, rectification or deletion thereof);</li>
                <li>the right to object to the processing of your Personal Data;</li>
                <li>the right not to be subject to any automatic individual decisions (automatic decisions based on data processing by automatic means, for the purpose of assessing several personal aspects);</li>
                <li>the right to address the courts of law in relation to any breach of your rights regarding the processing of your Personal Data.</li>
            </ul>
            <p>To learn more about the manner in which you may exercise the aforementioned rights, please contact us at privacy@cryptogiftbox.com.
            </p>
            <p><strong>7. Marketing</strong></p>
            <p>We may send you marketing e-mails, if you opted for this when you used our services. You can always opt out from our marketing list, free of charge, selecting the unsubscribe option provided by us each time we send you marketing emails, or by contacting us at marketing@cryptogiftbox.com.
            </p>
            <p><strong>8. Contact</strong></p>
            <p>If you have any questions or concerns about this Privacy Policy or its implementation, you may contact us at privacy@cryptogiftbox.com.
            </p>




        </div>

    </section>
);

export default PrivacyPolicy;
